import { GatsbyImage, type GatsbyImageSchema } from '@ecomm/shared-components'
import * as Icons from '@ecomm/shared-icons'
import type { IconTypes } from '@ecomm/shared-icons'
import { Link } from '@ecomm/framework'
import React from 'react'

export type ListType = {
  readonly icon: IconTypes
  readonly text: string
}

export type ButtonType = {
  readonly text: string
  readonly url: string
}

export type FeatureSectionDataProps = {
  readonly body: readonly string[]
  readonly list: readonly ListType[]
  readonly button: ButtonType
  readonly footer: readonly string[]
}

export function FeatureSection({
  data,
  image
}: {
  readonly data: FeatureSectionDataProps
  readonly image: GatsbyImageSchema
}) {
  const imageTag = image.gatsbyImageData && <GatsbyImage image={image} />
  const { body, list, button, footer } = data

  const createIcon = (icon: Icons.IconTypes) => {
    const Icon = Icons[icon]
    return (
      <Icon className="text-complementary-blue-100 h-6 w-6 lg:h-10 lg:w-10" />
    )
  }

  return (
    <>
      <div className="rounded-base grid grid-cols-2 items-center gap-4 bg-[#f1f1f2] p-6 md:gap-8 lg:p-10">
        <div className="prose-headings:text-gray-900 prose-p:text-gray-900 order-2 col-span-2 md:col-span-1">
          <h3 className="mb-4 text-3xl lg:text-4xl">
            SimpliSafe<span className="align-super text-lg">®</span> can do what
            others can’t
          </h3>
          {body.map((text: string, index: number) => (
            <p className="mb-3 text-sm font-normal lg:text-base" key={index}>
              {text}
            </p>
          ))}

          <ul className="my-6 list-none gap-y-4 p-0">
            {list.map(({ icon, text }, index: number) => (
              <li
                className="mb-3 flex space-x-3.5 text-base font-normal"
                key={index}
              >
                <div className="flex-none">
                  {icon ? createIcon(icon) : null}
                </div>
                <p className="flex-1 self-end text-sm lg:text-base">{text}</p>
              </li>
            ))}
          </ul>
          <Link to={button.url}>
            <button
              className="rounded-base border-complementary-blue-100 text-complementary-blue-100 hover:bg-complementary-blue-100 w-full cursor-pointer border-2 border-solid bg-transparent px-4 py-3 text-center text-base leading-normal no-underline transition-colors duration-200 ease-in-out hover:text-white md:px-6 lg:w-auto lg:text-lg lg:even:ml-6 lg:even:mt-0"
              type="button"
            >
              {button.text}
            </button>
          </Link>
        </div>
        <div className="order-1 col-span-2 self-baseline md:col-span-1">
          {imageTag ? imageTag : null}
        </div>
      </div>
      <ul className="border-neutral-light-100 -mt-1 list-none border-0 border-b border-solid p-0 pb-6 lg:-mt-8">
        {footer.map((footer: string, index: number) => (
          <li className="mb-2 text-xs font-normal" key={index}>
            <div className="col-span-1">{footer}</div>
          </li>
        ))}
      </ul>
    </>
  )
}
