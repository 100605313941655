import React from 'react'

// We have a copy of this Component in shared/components/src/v2/lib as well which
// supports data fetched from apollo instead of gatsby data layer. Functiality and
// structure is exactly same in both the components, so if you are making any changes
// to this, please make corresponding changes to the v2 version of this component too.
// We will make v2/ version the main version going forward when we are completely swapped
// over to apollo from gatsby data layer.
export function YoutubeVideo({
  link,
  title
}: { readonly link: string; readonly title: string }) {
  return (
    <div
      className="relative h-0 pt-[56.25%]"
      style={{
        maxWidth: '100%',
        width: '720px'
      }}
    >
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="rounded-base absolute left-0 top-0 h-full w-full overflow-hidden"
        frameBorder="0"
        src={link}
        style={{
          height: '480px',
          maxHeight: '100%',
          maxWidth: '100%',
          width: '720px'
        }}
        title={title}
      ></iframe>
    </div>
  )
}
