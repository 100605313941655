import { ApolloRichText, ContentfulImage } from '@ecomm/contentful/components'
import { devThrowError } from '@ecomm/error-handling'
import {
  type FormQuerySchema,
  PartnerWithUsForm
} from '@ecomm/shared-components'
import { type GridSchema, isSmallTextArray } from '@ecomm/shared-sections'
import { match } from 'ts-pattern'

export default function PartnershipComponent({
  referencesData,
  formV2Data
}: {
  readonly referencesData: GridSchema
  readonly formV2Data: FormQuerySchema | undefined
}) {
  const renderPartnershipComponent = (gridData: GridSchema) => {
    return (
      match(gridData.gridItemsCollection.items)
        .when(isSmallTextArray, items =>
          items.map((gridData, index) => {
            const json = gridData.text?.json
            return (
              <div className="flex" key={index}>
                {gridData.icon?.url ? (
                  <div className="w-full max-w-[200px]">
                    <ContentfulImage
                      description={
                        gridData.icon.description || 'image of partner'
                      }
                      originalHeight={200}
                      originalWidth={200}
                      quality={100}
                      url={gridData.icon.url}
                    />
                  </div>
                ) : null}
                <div className="mb-4 ml-4">
                  {json ? <ApolloRichText json={json} /> : null}
                </div>
              </div>
            )
          })
        )
        // TODO: make this exhaustive
        .otherwise(value => {
          devThrowError(Error(`unhandled value: ${value}`))
          return null
        })
    )
  }

  return (
    <div className="flex flex-row flex-wrap lg:flex-nowrap">
      <div className="lg:w-7/12">
        <h2 className="text-left">{referencesData.headline}</h2>
        {renderPartnershipComponent(referencesData)}
      </div>

      {formV2Data ? (
        <div className="mt-5 w-full rounded border-r-2 bg-neutral-100 p-9 lg:ml-5 lg:mt-14 lg:w-5/12">
          <PartnerWithUsForm
            button={{ text: formV2Data.button.text }}
            fields={formV2Data.fields}
            location="body"
          />
        </div>
      ) : null}
    </div>
  )
}
