import { type TypeOf, z } from '@simplisafe/ewok'

import { expertSectionAssetSchema } from '../../data'

export const expertSectionSchema = z.object({
  image: expertSectionAssetSchema,
  title: z.string().optional()
})

export type ExpertSectionSchema = TypeOf<typeof expertSectionSchema>
