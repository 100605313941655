import { documentSchema } from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'
import type { TypeOf } from 'zod'

export const affirmBannerApolloSchema = z.object({
  __typename: z.literal('AffirmBanner'),
  description: z
    .object({
      json: documentSchema
    })
    .optional(),
  title: z.string()
})

export type AffirmBannerType = TypeOf<typeof affirmBannerApolloSchema>
