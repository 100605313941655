import { LoadingSpinner } from '@ecomm/checkout-icons'
import { proSetupQueryParam } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { brazeSetCustomUserAttributes } from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import { useGuidedSystemBuilderCaptureLeadAndTrack } from '../../hooks/useGuidedSystemBuilderCaptureLeadAndTrack'
import {
  Installation,
  QueryParams,
  getAttributeHashToItemCount
} from '@ecomm/home-landing-data'
import { useEffect } from 'react'

export default function QuizRecommendedSystem() {
  const location = useLocation()
  const locale = useLocale()

  const [leadGenError, leadGenResponse, trackLeadCaptureCompleted] =
    useGuidedSystemBuilderCaptureLeadAndTrack()

  const params = new URLSearchParams(location.search)
  const isProInstallSelectedInQuiz =
    params.get(QueryParams.installation) === Installation.ProInstall

  useEffect(() => {
    leadGenResponse?.forEach(({ attributeHash }) => {
      const dynamicURL = `/product/system/${attributeHash}${
        isProInstallSelectedInQuiz ? `?${proSetupQueryParam}` : ''
      }`
      // Track product name into Braze
      brazeSetCustomUserAttributes({
        QW_RECS_PRODUCT_NAME: `${getAttributeHashToItemCount(
          attributeHash,
          locale
        )}-Piece Security System`
      })
      trackLeadCaptureCompleted && navigate(dynamicURL)
    })
  }, [trackLeadCaptureCompleted, leadGenResponse])

  return leadGenError ? (
    <div className="alignCenter pb-16 pt-24">
      <h2>Something went wrong.</h2>
      <p>
        <a href="/build-my-system">Create your own system instead </a>
      </p>
    </div>
  ) : (
    <div className="alignCenter pb-16 pt-24">
      <h2>Loading your package…</h2>
      <LoadingSpinner fillColor="#f79e00" size={48} />
    </div>
  )
}
