import { ninetailedExperimentsDataKey } from '@ecomm/data-constants'
import { ExperiencesContext } from '@ecomm/gatsby-wrappers'
import { getLeadData } from '@ecomm/shared-cookies'
import {
  type VariationEnteredEvent,
  sendNinetailedExperimentVariationsData
} from '@ecomm/tracking'
import {
  get as sessionStorageGet,
  remove as sessionStorageRemove,
  set as sessionStorageSet
} from '@ecomm/utils'
import { isNotNil, localStorage } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { type ReactNode, useContext, useState } from 'react'

import { useNinetailed } from './useNinetailed'

const { get } = localStorage
const MAX_RETRIES = 2
const TIMEOUT_RETRY = 1500
const ignoredExperiments = [
  'Uber',
  'Uber Entry - Ninetailed Experience',
  'A/A - Proof of Concept Experience',
  '[POC] BMS Guarantee Image'
]

/**
 * Given that the action of sending info to Braze can fail (one
 * probably reason being if this hook is called before Braze was initialized),
 * we're using this intermediate function to act as a retry mechanism.
 *
 * @param data
 * @param attempt
 */
function sendExperimentsDataToBraze(
  data: VariationEnteredEvent[],
  attempt = 0
) {
  const result = sendNinetailedExperimentVariationsData(data)

  !result &&
    attempt < MAX_RETRIES &&
    setTimeout(
      () => sendExperimentsDataToBraze(data, attempt + 1),
      TIMEOUT_RETRY
    )
}

/**
 * Save the ninetailed experiments data in session storage until we're able to log Braze
 * custom events. I.e: when Braze EID is set in local storage or when Lead Data cookie is set.
 *
 * @param data
 */
function saveExperimentsDataInSessionStorage(data: VariationEnteredEvent[]) {
  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  const current: O.Option<VariationEnteredEvent[]> = O.tryCatch(() =>
    JSON.parse(sessionStorageGet(ninetailedExperimentsDataKey) || '[]')
  )

  sessionStorageSet(
    ninetailedExperimentsDataKey,
    JSON.stringify([...(O.isSome(current) ? current.value : []), ...data])
  )
}

/**
 * This hook will read the current user's Ninetailed profile state and the list of
 * experiment names in the ExperiencesContext, and send each experiment's name and current
 * variant for this user to Braze.
 */
export const useSendExperimentsDataToBraze = () => {
  const experimentNamesData = useContext(ExperiencesContext)
  const [bound, setBound] = useState(false)
  const variantNames = ['Baseline', 'Variant']
  let experimentsRegistered: string[] = []

  !bound && sessionStorageRemove(ninetailedExperimentsDataKey)

  const { onProfileChange } = useNinetailed()
  O.isSome(onProfileChange) &&
    !bound &&
    onProfileChange.value(profileState => {
      // For each experiment in the array of this user's profile that hasn't been added yet
      // to the experimentsRegistered array, create an event object that will be sent to Braze
      const events: VariationEnteredEvent[] =
        O.isSome(experimentNamesData) && !!profileState.experiences
          ? profileState.experiences
              .filter(e => !experimentsRegistered.includes(e.experienceId))
              .map(e => {
                const experimentName =
                  experimentNamesData.value[e.experienceId] || ''
                return {
                  Test_name: experimentName,
                  Variation_name:
                    e.variantIndex <= 1
                      ? variantNames[e.variantIndex]
                      : `Variant ${e.variantIndex}`
                }
              })
              .filter(
                e => !!e.Test_name && !ignoredExperiments.includes(e.Test_name)
              )
          : []

      // Add these experiments's ids to the registered list. This is to avoid sending this data more than once per session.
      experimentsRegistered =
        O.isSome(experimentNamesData) && !!profileState.experiences
          ? profileState.experiences.map(e => e.experienceId)
          : experimentsRegistered

      // Send the data to Braze
      const brazeSessionId = get('braze_eid') || null
      const leadData = getLeadData()
      const canLogCustomEvent = isNotNil(leadData) || isNotNil(brazeSessionId)
      canLogCustomEvent
        ? sendExperimentsDataToBraze(events)
        : saveExperimentsDataInSessionStorage(events)
    })

  // Bind the callback to onProfileChange only once
  O.isSome(onProfileChange) && !bound && setBound(true)
}

/**
 * Reads the current user's Ninetailed profile state and the list of
 * experiment names in the ExperiencesContext, and send each experiment's name and current
 * variant for this user to Braze.
 *
 * This is exposed as a component because the hook can't be used at the top level of WrapPageElement,
 * because useNinetailed relies on EnvProvider being populated with the correct locale.
 */
export function BrazeExperimentDataWrapper({
  children
}: { readonly children: ReactNode }) {
  useSendExperimentsDataToBraze()
  return children
}
