import { ChevronDown } from '@ecomm/shared-icons'
import { voidFn } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'

import { ContentfulRichText } from '../ContentfulRichText'

export type AccordionProps = {
  readonly description: string
  readonly longTitle: string
  readonly onToggle?: (isOpen: boolean) => void
  readonly shortTitle: string
}

export function Accordion({
  description,
  longTitle,
  onToggle = voidFn,
  shortTitle
}: AccordionProps) {
  const [open, setOpen] = useState(false)
  const handleOnClick = useCallback(() => {
    setOpen(o => !o)
    onToggle && onToggle(!open)
  }, [onToggle, open])

  return (
    <div className="bg-neutral-light-50 flex flex-col rounded-[20px] px-5 py-5 md:px-10 md:py-9">
      <button
        className="flex cursor-pointer items-center justify-between bg-transparent border-none m-0"
        data-component="CollapseButton"
        onClick={handleOnClick}
      >
        <span className="hidden text-2xl font-medium md:block">
          {longTitle}
        </span>
        <span className="text-lg font-medium md:hidden">{shortTitle}</span>
        <span className="min-w-[25px]">
          <ChevronDown
            className={classNames(
              'h-8 w-8 transition-transform text-neutral-dark',
              {
                'rotate-180': open
              }
            )}
          />
        </span>
      </button>
      <div className={classNames('mr-1 md:mr-[5.75rem]', { hidden: !open })}>
        <div className="prose-p:mb-0 pt-3 md:pt-6">
          <ContentfulRichText raw={description} />
        </div>
      </div>
    </div>
  )
}
