import { useContentful } from '@ecomm/shared-apollo'
import { v2HeroBannerSchema as heroBannerSchema } from '@ecomm/shared-components'
import { useMemo } from 'react'

import { HERO_BANNER_QUERY } from './hero_banner_query'

export const useHeroBannerQuery = (bannerId: string) => {
  const { data: apolloData } = useContentful(HERO_BANNER_QUERY, {
    id: bannerId
  })
  const heroBannerData = useMemo(
    () => heroBannerSchema.parse(apolloData?.['heroBanner']),
    [apolloData]
  )
  return heroBannerData
}
