import { useLocale } from '@ecomm/data-hooks'
import { ContentfulRichText } from '@ecomm/shared-components'
import {
  COOKIE_LEAD_DATA,
  getCookie,
  getLeadData,
  setCookie,
  updateLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import { useIsOnScreen } from '@ecomm/shared-hooks'
import { Column, LoadingSpinner, Text } from '@ecomm/ss-react-components'
import { WizardInitial } from '@ecomm/ss-react-components'
import {
  addUserToSMSSubscriptionGroup,
  brazeTrackQuoteWizardSubmission,
  fbTrackLeadCreated,
  handleBrazeTrackingEvent,
  setPhoneNumber,
  useOptimizelyTrackSiteEvents,
  usePollVid
} from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
import { safePath, safeProp } from '@simplisafe/monda'
import {
  type LeadGenCreateParams,
  leadGenRecommendations,
  leadGenUpdate
} from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import { selectCurrentPromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Link } from '@ecomm/framework'
import { type Maybe, None } from 'monet'
import propOr from 'ramda/src/propOr'
import T from 'ramda/src/T'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import styled from 'styled-components'

import { locale } from '../../commercetools/utils'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
// TODO: Revert it to re-run the experiment after Black Friday's freeze.
//   import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics'
import {
  trackEventCompletedQuoteWizard,
  trackSubmitLeadEvent
} from '../../util/analytics'
import ButtonComponent from '../ButtonComponent'
import QuoteWizardComponent, {
  type QuoteWizardProps,
  toRichText
} from '../QuoteWizardComponent'
import {
  // TODO: Revert it to re-run the experiment after Black Friday's freeze.
  //   appendVIDandUniqueSessionIdtoButtonUrl,
  KEY_QW_RECOMMENDATIONS_SUBMITTED,
  KEY_RETURN_TO_QW,
  POP_UP_STORAGE_KEY
} from './lib'
import { buildRecommendationResponseData } from './quoteWizardRecommendationTrackingData'
const { set } = localStorage

export type QuoteWizardWrapperProps = {
  readonly handleEmailSubmit?: QuoteWizardProps['handleEmailSubmit']
  readonly handlePhoneSubmit?: QuoteWizardProps['handlePhoneSubmit']
  readonly data: QuoteWizardProps['data']
  readonly includePhone?: QuoteWizardProps['includePhone']
  readonly type?: QuoteWizardProps['type']
  readonly finalTabDescription?: QuoteWizardProps['finalTabDescription']
  readonly finalTabContents?: QuoteWizardProps['finalTabContents']
  readonly firstTab?: QuoteWizardProps['firstTab']
  readonly showTitle?: QuoteWizardProps['showTitle']
  readonly columnType?: 'none' | 'shadowbox'
}

const StyledText = styled(Text)`
  --prose-body-color: var(--white);
  --prose-headings-color: var(--white);
  --prose-links-color: var(--white);

  --btn-outline-accent: var(--white);
  --btn-outline-primary: var(--neutral-black);
  --btn-outline-primary-light: var(--white);
  --btn-outline-accent-light: var(--neutral-black);

  --btn-link-accent: var(--white);
  /* This line is because the component uses RichText, so paragraphs takes neutral black instead of white */
  p {
    color: #fff;
  }
  text-align: center;
`

export default function QuoteWizardWrapper(props: QuoteWizardWrapperProps) {
  const {
    columnType = 'shadowbox',
    data,
    type = 'embedded',
    includePhone = false,
    handlePhoneSubmit
  } = props
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const wizardRef = useRef<HTMLDivElement>(null)
  const isWizardSeen = useIsOnScreen(wizardRef)
  const leadDataCookie = getLeadData()
  // TODO: Revert it to re-run the experiment after Black Friday's freeze.
  //   const [ clientId, sessionId ] = useGoogleAnalytics()
  const [emailAddress, setEmailAddress] = useState<string>(
    propOr<string, string>('', 'email', leadDataCookie)
  )
  const [phone, setPhone] = useState<string>(
    propOr<string, string>('', 'phone', leadDataCookie)
  )
  const vid = usePollVid()
  const [attributeHash, setAttributeHash] = useState(None())
  const [isLoading, setIsLoading] = useState(false)
  const { Track, trackEvent } = useTracking({
    action: 'quoteWizard',
    appSection: 'quoteWizard',
    wizardType: type
  })

  const currentPromoCode = useSelector(selectCurrentPromoCode)
  const siteLocale = useLocale()
  const { handleHidePopup } = useContext(HidePopupWizard)
  const storedAttributeHash: string | undefined =
    getCookie<string>(KEY_RETURN_TO_QW)

  const {
    newUserHelpText,
    newUserSkipQuizButton,
    newUserStartQuizButton,
    returningUserHelpText,
    returningUserRestartQuizButton,
    returningUserSeeResultsButton
  } = data.firstTab || {}

  // TODO: Revert it to re-run the experiment after Black Friday's freeze.
  // const variation = useActivate(propOr('control', 'experimentKey', newUserStartQuizButton))

  // TODO: fix type
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const newUserSkipQuizButtonUrl = prop('url', newUserSkipQuizButton || {})

  const handleEmailSubmitFailure = () => {
    optimizelyTrackSiteEvents({ eventType: 'website_error' })
  }

  const handleEmailSubmitSuccess =
    (responses: Record<string, string>) => (value: Maybe<any>) => {
      setAttributeHash(value.map(prop('attributeHash')))
      setCookie(KEY_QW_RECOMMENDATIONS_SUBMITTED, true, cookiesOption)

      const attributeHash = value.map(prop('attributeHash')).orUndefined()
      setCookie(KEY_RETURN_TO_QW, attributeHash, cookiesOption)

      handleBrazeTrackingEvent(value.orUndefined())
      responses['phone'] && setPhoneNumber(responses['phone'])
      responses['phone'] && addUserToSMSSubscriptionGroup()

      const wizardWithRecsResponseData = buildRecommendationResponseData(
        siteLocale,
        attributeHash,
        responses
      )
      brazeTrackQuoteWizardSubmission(wizardWithRecsResponseData, true)
      trackEventCompletedQuoteWizard(trackEvent)
      optimizelyTrackSiteEvents({ eventType: 'quote_wizard_complete_fs' })
      optimizelyTrackSiteEvents({ eventType: 'lead_captured_fs' })
      setIsLoading(false)
      trackSubmitLeadEvent(trackEvent)

      value.forEach(
        async (response: {
          readonly email: string
          readonly leadId: number
        }) => {
          responses['phone'] &&
            leadGenUpdate({
              leadId: response.leadId,
              responses
            })(handleEmailSubmitFailure)(() => T())
          const submittedPhone = responses['phone'] || ''
          response.email &&
            (await fbTrackLeadCreated(response.email, submittedPhone))
          updateLeadData({
            phone: submittedPhone,
            ...value.orUndefined()
          })
          setPhone(submittedPhone)
        }
      )
    }

  const handleEmailSubmit = (responses: Record<string, string>) => {
    const leadGenParams: LeadGenCreateParams = {
      format: type,
      leadPromoOffer: currentPromoCode.getOrElse('NO_CODE'),
      leadSourceVersion: {}, // TODO pull in from page context
      locale: locale,
      responses,
      showPhoneField: includePhone,
      visitorId: vid || 'NO_VISITOR_ID'
    }

    const emailAddress = safeProp('email', responses).getOrElse('')
    setEmailAddress(typeof emailAddress === 'string' ? emailAddress : '')

    setIsLoading(true)
    leadGenRecommendations(leadGenParams)(handleEmailSubmitFailure)(
      handleEmailSubmitSuccess(responses)
    )
  }

  useEffect(() => {
    isWizardSeen &&
      optimizelyTrackSiteEvents({ eventType: 'impacted_22146810067' })
  }, [isWizardSeen, optimizelyTrackSiteEvents])

  useCookieChange(COOKIE_LEAD_DATA, data => {
    setEmailAddress(propOr('', 'email', JSON.parse(data)))

    setPhone(propOr('', 'phone', JSON.parse(data)))
  })
  const onClickButton = () => {
    handleHidePopup(false)
    set(POP_UP_STORAGE_KEY, true)
  }

  const isNewUser = !storedAttributeHash

  /**
   * `useMemo` allows us to avoid inconsistencies between the number of steps for a new user and a returning one.
   * Without the use of "useMemo," a bug occurs because when a new user submits their email, a cookie is set,
   * and it becomes a returning user, so an initial step is added, and an inconsistency occurs.
   */
  const showNewUsersFirstTab = useMemo(
    () =>
      !!(
        isNewUser &&
        newUserHelpText &&
        newUserSkipQuizButton &&
        newUserSkipQuizButtonUrl &&
        newUserStartQuizButton
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const showReturningUsersFirstTab = useMemo(
    () =>
      !!(
        !isNewUser &&
        returningUserHelpText &&
        returningUserRestartQuizButton &&
        returningUserSeeResultsButton
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const firstTab =
    showNewUsersFirstTab || showReturningUsersFirstTab ? (
      <WizardInitial
        isNewUser={isNewUser}
        newUserHelpText={newUserHelpText}
        newUserSkipQuizButton={<ButtonComponent data={newUserSkipQuizButton} />}
        // TODO: Revert it to re-run the experiment after Black Friday's freeze.
        //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        //   newUserStartQuizButton={<ButtonComponent data={variation && variation === 'variation_1' ? appendVIDandUniqueSessionIdtoButtonUrl(newUserStartQuizButton?.variations?.[1], vid, clientId, sessionId) : newUserStartQuizButton?.variations?.[0]} onClick={onClickButton}
        //     style={{ width: '100%' }}
        //   />}
        // @ts-expect-error TS(2322) FIXME: Type 'ContentfulButtonContentfulVariationContainer... Remove this comment to see the full error message
        newUserStartQuizButton={
          <ButtonComponent
            data={newUserStartQuizButton}
            onClick={onClickButton}
          />
        }
        returningUserHelpText={returningUserHelpText}
        // TODO: Revert it to re-run the experiment after Black Friday's freeze.
        //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        //   returningUserRestartQuizButton={<ButtonComponent data={variation && variation === 'variation_1' ? appendVIDandUniqueSessionIdtoButtonUrl(returningUserRestartQuizButton?.variations?.[1], vid, clientId, sessionId) : returningUserRestartQuizButton?.variations?.[0]} onClick={onClickButton}
        //      style={{ width: '100%' }}
        //   />}
        // @ts-expect-error TS(2322) FIXME: Type 'ContentfulButtonContentfulVariationContainer... Remove this comment to see the full error message
        returningUserRestartQuizButton={
          <ButtonComponent data={returningUserRestartQuizButton} />
        }
        returningUserSeeResultsButton={
          <Link
            style={{
              display: 'block',
              textDecoration: 'none'
            }}
            to={`/product/system/${storedAttributeHash}`}
          >
            <ButtonComponent data={returningUserSeeResultsButton} />
          </Link>
        }
      />
    ) : null

  const loading = (
    <div
      key="loading"
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <LoadingSpinner />
      <p className="m1_t" style={{ marginBottom: 0 }}>
        Building your System
      </p>
    </div>
  )

  const finalTabDescription = isLoading
    ? [loading]
    : toRichText('finalTabDescription', data, emailAddress)
  const finalTabContents = isLoading
    ? [loading]
    : safeProp('finalTabContents', data)
        .map(components =>
          components.map(componentData => {
            const component = componentData

            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            const key = prop('id', component)

            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            return path(['internal', 'type'], component) ===
              'ContentfulRichText' && attributeHash.isSome() ? (
              <Text className="prose-p:text-md prose text-center" useTailwind>
                <Link key={key} to={`/product/system/${attributeHash.just()}`}>
                  {}
                  {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
                  {safePath(['richText', 'raw'], component).map(raw => (
                    <ContentfulRichText key={key} raw={raw} />
                  ))}
                </Link>
              </Text>
            ) : (
              <div key={key} />
            )
          })
        )
        .getOrElse([])
  const renderQuoteWizard = () => (
    <>
      <QuoteWizardComponent
        data={data}
        defaultEmail={emailAddress}
        defaultPhone={phone}
        finalTabContents={finalTabContents}
        finalTabDescription={finalTabDescription}
        firstTab={firstTab}
        //@ts-expect-error
        handleEmailSubmit={handleEmailSubmit}
        handlePhoneSubmit={handlePhoneSubmit}
        includePhone={includePhone}
        type={type}
      />
      <div ref={wizardRef} />
    </>
  )

  return columnType === 'none' ? (
    renderQuoteWizard()
  ) : (
    // @ts-expect-error TS(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
    <Track>
      <Column backgroundColor="neutralBlack" padding="medium">
        <StyledText useTailwind={true}>{renderQuoteWizard()}</StyledText>
      </Column>
    </Track>
  )
}
