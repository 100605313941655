import React from 'react'

import ProductCard from '../ProductCard'
import type { ProductCardFragment } from '../ProductCard/schema'

type Props = {
  readonly items: readonly ProductCardFragment[]
  readonly isCurrent: boolean
  readonly isAffirmExperience: boolean
}

function ProductCardsList({ items, isCurrent }: Props) {
  return (
    <div className={'mt-4 grid gap-2 md:mt-8 md:grid-cols-2 lg:grid-cols-3'}>
      {items.map(
        item =>
          isCurrent && (
            <ProductCard
              key={`product-cart-${item.sku}`}
              tooltipEvent={'bms_tooltip'}
              type="BMS"
              {...item}
            />
          )
      )}
    </div>
  )
}

export default ProductCardsList
