import {
  useOdmonExperience,
  usePlanChoiceDynamicNonIntentPushExperience,
  usePlanChoiceDynamicIntentPushExperience,
  usePlanChoiceExperience,
  usePlanChoiceNonCameraPushExperience,
  usePlanChoiceNonCameraStayExperience,
  usePlanChoiceDynamicNonIntentStayExperience,
  usePlanChoiceDynamicIntentStayExperience
} from '@ecomm/shared-ninetailed-odmon'
import { getShowedOdmonIntentCookie } from '../../../utils/odmonIntentCookies'
import type { PackageType } from '../schema'
import { match } from 'ts-pattern'

/**
 * the ODMON content controlled here is:
 * - standalone olympus carousel image
 * - olympus camera link
 * - olympus in carousel below the fold
 * - personalize your package section showing Olympus
 *
 * All non-dynamic packages should show ODMON content if in the ODMON Full Launch test and Plan Choice Control
 *
 * When Dynamic packages are in the ODMON Full Launch test and Plan Choice Control content is based on user quiz intent cookie:
 * if “showedODMONIntent” = True: ODMON content on the dynamic PDPs
 * if “showedODMONIntent” = False: no ODMON content on the dynamic PDPs (same as control)
 * */
export const useShowOdmonContent = (
  packageType: PackageType,
  containsOutdoorCamera: boolean
) => {
  const isInOdmonFullLaunchVariant = useOdmonExperience().isVariant
  const showedOdmonIntent = getShowedOdmonIntentCookie()
  const isInPlanChoiceControl =
    !usePlanChoiceExperience().isVariant && isInOdmonFullLaunchVariant

  const planChoiceVariantShowOlympus = usePlanChoiceVariantShowOlympus(
    packageType,
    containsOutdoorCamera
  )

  if (isInPlanChoiceControl) {
    return match(packageType)
      .with('Dynamic', () => {
        return showedOdmonIntent
      })
      .otherwise(() => isInOdmonFullLaunchVariant)
  } else {
    return planChoiceVariantShowOlympus
  }
}

/**
 * For variation of Plan Choice experiment:
 *
 * return true when:
 * variant odmon full launch + variant plan choice + associated variant push experience... meaning
 * - dynamic + intent + dynamic intent push variant
 * - dynamic + non-intent + dynamic non-intent push variant
 * - non-dynamic + non-camera + non-dynamic non-camera push variant
 * - non-dynamic + camera
 */
const usePlanChoiceVariantShowOlympus = (
  packageType: PackageType,
  containsOutdoorCamera: boolean
) => {
  const isInOdmonFullLaunchVariant = useOdmonExperience().isVariant
  const isInDynamicIntentPushVariant =
    usePlanChoiceDynamicIntentPushExperience().isVariant &&
    isInOdmonFullLaunchVariant
  const isInDynamicNonIntentPushVariant =
    usePlanChoiceDynamicNonIntentPushExperience().isVariant &&
    isInOdmonFullLaunchVariant
  const isInNonCameraPushVariant =
    usePlanChoiceNonCameraPushExperience().isVariant &&
    isInOdmonFullLaunchVariant
  const showedOdmonIntent = getShowedOdmonIntentCookie()

  return match(packageType)
    .with('Dynamic', () => {
      if (showedOdmonIntent) {
        return isInDynamicIntentPushVariant
      } else {
        return isInDynamicNonIntentPushVariant
      }
    })
    .otherwise(() => {
      if (containsOutdoorCamera) {
        return isInOdmonFullLaunchVariant
      } else {
        return isInNonCameraPushVariant
      }
    })
}

/**
 * For AGOP component on PDP:
 *
 * return true when:
 * camera + non-dynamic PDP + control plan choice
 * showed ODMON intent + dynamic PDP + control plan choice
 * no camera + non-dynamic PDP +  variant plan choice + variant non-camera stay
 * no intent + dynamic PDP + variant plan choice + variant dynamic non-intent stay
 * intent + dynamic PDP + variant plan choice + variant dynamic intent stay
 */
export const useShowAGOPComponent = (
  packageType: PackageType,
  containsOutdoorCamera: boolean
) => {
  const isInOdmonFullLaunchVariant = useOdmonExperience().isVariant
  const isInPlanChoiceVariant = usePlanChoiceExperience().isVariant
  const isInNonCameraStayVariant =
    usePlanChoiceNonCameraStayExperience().isVariant
  const isInDynamicNonIntentStayVariant =
    usePlanChoiceDynamicNonIntentStayExperience().isVariant
  const isInDynamicIntentStayVariant =
    usePlanChoiceDynamicIntentStayExperience().isVariant
  const showedOdmonIntent = getShowedOdmonIntentCookie()

  const planChoiceControlDynamicAGOPContent =
    isInOdmonFullLaunchVariant && !isInPlanChoiceVariant && showedOdmonIntent

  const showAGOPonNonDynamic =
    isInOdmonFullLaunchVariant &&
    isInPlanChoiceVariant &&
    isInNonCameraStayVariant
  const showAGOPonDynamicNonIntent =
    isInOdmonFullLaunchVariant &&
    isInPlanChoiceVariant &&
    isInDynamicNonIntentStayVariant
  const showAGOPonDynamicIntent =
    isInOdmonFullLaunchVariant &&
    isInPlanChoiceVariant &&
    isInDynamicIntentStayVariant

  return match(packageType)
    .with('Dynamic', () => {
      if (showedOdmonIntent) {
        return showAGOPonDynamicIntent || planChoiceControlDynamicAGOPContent
      } else {
        return showAGOPonDynamicNonIntent
      }
    })
    .otherwise(() => {
      if (containsOutdoorCamera) {
        return isInOdmonFullLaunchVariant
      } else {
        return showAGOPonNonDynamic
      }
    })
}
