import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'
import { match } from 'ts-pattern'

import type { InstallationOptionCardSchema } from '@ecomm/home-landing-data'

export function InstallationOptionCard({
  content
}: InstallationOptionCardSchema) {
  return (
    <div className="col-span-1 rounded-base gap-4  prose-headings:mb-4 overflow-hidden bg-neutral-light-50 flex flex-col">
      {content.map((el, index) =>
        match(el)
          .with({ __typename: 'ContentfulSmallText' }, data => (
            <div className="flex flex-col gap-4" key={index}>
              <ContentfulImage classNameOverride="w-full" {...data.icon} />
              <div className="px-4 md:px-6 lg:px-8">
                <ContentfulRichText {...data.text} />
              </div>
            </div>
          ))
          .with({ __typename: 'ContentfulImageAndText' }, data => (
            <div
              className="flex items-center gap-5 pb-4 px-4 md:px-6 prose-p:mb-0 lg:px-8"
              key={index}
            >
              <div className="w-8 items-center flex">
                <ContentfulImage classNameOverride="w-8" {...data.image} />
              </div>
              <ContentfulRichText {...data.text} />
            </div>
          ))
          .exhaustive()
      )}
    </div>
  )
}
