import { contentfulImageSchema } from '@ecomm/contentful/components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const expertsReviewsAssetSchema = z.object({
  items: z.array(
    contentfulImageSchema.extend({
      sys: z.object({
        id: z.string()
      })
    })
  )
})

const contentSchema = z.object({
  content: z.object({
    reviews: z.array(
      z.object({
        id: z.number(),
        key: z.string(),
        text: z.string(),
        disclaimer: z.string().optional()
      })
    )
  })
})

export const expertReviewsSchema = z.object({
  expertReviews: contentSchema
})

export const expertReviewsAdditionalSchema = contentSchema.optional()

export type ExpertReviews = TypeOf<typeof expertReviewsSchema>
export type ExpertReviewsAsset = TypeOf<typeof expertsReviewsAssetSchema>
