import {
  type DocumentNode,
  type QueryOptions,
  type TypedDocumentNode,
  useSuspenseQuery,
  type UseSuspenseQueryResult
} from '@apollo/client/index.js'
import { useLocale } from '@ecomm/data-hooks'

import { validateQuery } from './validateQuery'

/**
 * A hook to query Contentful with GraphQL.
 *
 * Queries should be constructed using the `gql` tag from `@apollo/client` and be
 * in SCREAMING_SNAKE_CASE and should be named with the suffix `_QUERY`.
 * Queries should also be exported from libs and never directly created in App code.
 * This allows them to be shared across multiple apps, which is required for link pre-loading
 * and to make it easier while we migrate off Gatsby.
 *
 * Every query name must be unique and must include `$preview` and `$locale` variable.
 * If you do not have a unique name Apollo will throw an error.
 * If you do not have a `$preview` variable it will lead to a runtime error if preview mode is used.
 * If you do not have a `$locale` variable it will always use `en-US`.
 *
 * Any component that uses this hook must be wrapped in `<Suspense fallback={null}/>` and `<ErrorBoundary />`.
 *
 * This hook is intended to be temporary to help us migrate away from Gatsby.
 * Once we are off from Gatsby we can move the Contentful data loading to SSR.
 *
 * Any page that uses this hook should also use the `PreloadLink` component to pre-fetch the data when possible
 * to avoid a flicker during in app navigation.
 *
 * Data returned from this hook should be parsed with `z` from `@simplisafe/ewok` to verify types and correctness.
 * Note: If you use `.parse` and the error is invalid, it will throw an error and trigger an error boundary.
 * You might want to consider using `useSafeContentful` instead if you want to handle the error gracefully.
 *
 * To enable Contentful's preview mode, set the `CONTENTFUL_PREVIEW` environment variable to `true`.
 *
 * @example
 *
 * export const LEGAL_TEMPLATE_QUERY = gql`
 * # Every query needs a unique name
 * query LegalTemplateQuery($id: String!, $preview: Boolean!, $locale: String!) {
 *  # Every query needs to pass along the preview flag
 *  textPage(id: $id, preview: $preview, $locale: String!) {
 *    ...textPage
 *  }
 * }`
 *
 * export function Component({ contentful_id }: Props) {
 *  const { data: apolloData } = useContentful(LEGAL_TEMPLATE_QUERY, {
 *    id: contentful_id
 *  })
 *
 *  const { textPage } = legalTextPageSchema.parse(apolloData)
 *
 *  return <div>{textPage.title}</div>
 * }
 **/
export const useContentful = (
  query: DocumentNode | TypedDocumentNode,
  variables?: QueryOptions['variables']
): UseSuspenseQueryResult<Record<string, unknown>> => {
  validateQuery(query)
  const locale = useLocale()
  return useSuspenseQuery(query, {
    variables: {
      ...variables,
      preview: process.env['PUBLIC_CONTENTFUL_PREVIEW'] === 'true',
      locale
    }
  })
}
