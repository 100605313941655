import { useMicroCopy } from '@ecomm/micro-copy'
import {
  AlarmMoments,
  AppWidget,
  ConfidenceBar,
  DeadEnd,
  ExpertSection,
  FAQs,
  MonitoringAccolades,
  MonitoringPageHeroBanner,
  MonitoringPageVideoWrapper,
  ProductDetails,
  ScoutExpandableMonitoring,
  ScoutProductPageDeadEndII,
  ScoutVideo,
  TwoColumnImageBlurbs,
  TwoColumnImages,
  TwoColumnVideo,
  useExpertSectionData,
  useMonitoringPageAccoladesData,
  useMonitoringPageAlarmMomentsData,
  useMonitoringPageAppWidgetData,
  useMonitoringPageConfidenceBar,
  useMonitoringPageDeadEndData,
  useMonitoringPageFAQsData,
  useMonitoringPageHeroBannerData,
  useMonitoringPageUserStoriesData,
  useMonitoringPageVideoWrapperData,
  UserStories,
  useScoutProductPageAlarmMomentsData,
  useScoutProductPageAppWidgetData,
  useScoutProductPageConfidenceBar,
  useScoutProductPageDeadEndIIData,
  useScoutProductPageFAQsData,
  useScoutProductPageInstallationTwoColumnVideo,
  useScoutProductPageProductDetailsData,
  useScoutProductPageTwoColumnImageBlurbsData,
  useScoutProductPageTwoColumnImagesData
} from '@ecomm/scout'
import { Collapsible, CollapsibleSection } from '@ecomm/shared-components'
import type {
  ContentfulScoutPlaceholder,
  Maybe,
  Scalars
} from 'apps/legacy/graphql'
import React from 'react'

import { LegacyChangeMonitoringExpandable } from './LegacyChangeMonitoringExpandable'
import { LegacyChooseMonitoringExpandable } from './LegacyChooseMonitoringExpandable'
import { LegacyScoutProductPageHero } from './ScoutProductPageHero'
import { LegacyRiskFreeComponent } from './ScoutRiskFreeComponent'

export type ContentfulScoutPlaceholderProps = {
  readonly data: ContentfulScoutPlaceholder & {
    readonly componentType: Maybe<Scalars['String']>
  }
}

function ScoutHardcodedComponent({ data }: ContentfulScoutPlaceholderProps) {
  const microCopy = useMicroCopy()

  const { componentType } = data

  const expertSectionData = useExpertSectionData()
  const monitoringPageAccoladesData = useMonitoringPageAccoladesData()
  const monitoringPageAlarmMomentsData = useMonitoringPageAlarmMomentsData()
  const monitoringPageAppWidgetData = useMonitoringPageAppWidgetData()
  const monitoringPageDeadEndData = useMonitoringPageDeadEndData()
  const monitoringPageConfidenceBarData = useMonitoringPageConfidenceBar()
  const monitoringPageFAQsData = useMonitoringPageFAQsData()
  const monitoringPageHeroBannerData = useMonitoringPageHeroBannerData()
  const monitoringPageUserStoriesData = useMonitoringPageUserStoriesData()
  const monitoringPageVideoWrapperData = useMonitoringPageVideoWrapperData()
  const scoutProductPageAlarmMomentsData = useScoutProductPageAlarmMomentsData()
  const scoutProductPageAppWidgetData = useScoutProductPageAppWidgetData()
  const scoutProductPageConfidenceBarData = useScoutProductPageConfidenceBar()
  const scoutProductPageDeadEndIIData = useScoutProductPageDeadEndIIData()
  const scoutProductPageFAQsData = useScoutProductPageFAQsData()
  const scoutProductPageTwoColumnImageBlurbsData =
    useScoutProductPageTwoColumnImageBlurbsData()
  const scoutProductPageProductDetailsData =
    useScoutProductPageProductDetailsData()
  const scoutProductPageTwoColumnImagesData =
    useScoutProductPageTwoColumnImagesData()
  const scoutProductPageTwoColumnVideoData =
    useScoutProductPageInstallationTwoColumnVideo()

  const components = {
    'Change Monitoring Page: Plan Cards with Comparison': (
      <LegacyChangeMonitoringExpandable />
    ),
    'Choose Monitoring Page: Plan Cards with Comparison': (
      <LegacyChooseMonitoringExpandable />
    ),
    'Monitoring Page: Accolades': (
      <MonitoringAccolades data={monitoringPageAccoladesData} />
    ),
    'Monitoring Page: Alarm Moments': (
      <AlarmMoments data={monitoringPageAlarmMomentsData} />
    ),
    'Monitoring Page: App Widget': (
      <AppWidget data={monitoringPageAppWidgetData} />
    ),
    'Monitoring Page: Confidence Bar': (
      <ConfidenceBar data={monitoringPageConfidenceBarData} />
    ),
    'Monitoring Page: Dead End Nav Component': (
      <DeadEnd data={monitoringPageDeadEndData} />
    ),
    'Monitoring Page: FAQs': <FAQs data={monitoringPageFAQsData} />,
    'Monitoring Page: Help Widget': <ExpertSection data={expertSectionData} />,
    'Monitoring Page: Hero': (
      <MonitoringPageHeroBanner data={monitoringPageHeroBannerData} />
    ),
    'Monitoring Page: Plan Cards with Comparison': (
      <ScoutExpandableMonitoring headline="Monitoring Plans" />
    ),
    'Monitoring Page: User Story Cards': (
      <UserStories data={monitoringPageUserStoriesData} />
    ),
    'Monitoring Page: Video': (
      <MonitoringPageVideoWrapper data={monitoringPageVideoWrapperData} />
    ),
    'Scout Product Page: Dead End I': <LegacyRiskFreeComponent />,
    'Scout Product Page: Dead End II': (
      <ScoutProductPageDeadEndII data={scoutProductPageDeadEndIIData} />
    ),
    'Scout Product Page: FAQs': (
      <Collapsible isOpen={false} title="FAQs">
        <CollapsibleSection>
          <FAQs data={scoutProductPageFAQsData} />
        </CollapsibleSection>
      </Collapsible>
    ),
    'Scout Product Page: Hero': <LegacyScoutProductPageHero />,
    'Scout Product Page: Installation': (
      <Collapsible isOpen={false} title="Installation">
        <CollapsibleSection>
          <TwoColumnVideo data={scoutProductPageTwoColumnVideoData} />
        </CollapsibleSection>
      </Collapsible>
    ),
    'Scout Product Page: Live Guard Protection': (
      <Collapsible isOpen={true} title={microCopy['live-guard-protection']}>
        <CollapsibleSection>
          <ScoutVideo
            className="h-auto"
            containerClassName="mx-auto max-w-5xl"
            type="ScoutProductLiveGuardVideo"
          />
        </CollapsibleSection>
        <CollapsibleSection>
          <ConfidenceBar data={scoutProductPageConfidenceBarData} />
        </CollapsibleSection>
        <CollapsibleSection>
          <AlarmMoments data={scoutProductPageAlarmMomentsData} />
        </CollapsibleSection>
      </Collapsible>
    ),
    'Scout Product Page: Meet the Camera': (
      <Collapsible isOpen={false} title="Meet the camera">
        <CollapsibleSection>
          <TwoColumnImages data={scoutProductPageTwoColumnImagesData} />
        </CollapsibleSection>
        <CollapsibleSection>
          <AppWidget data={scoutProductPageAppWidgetData} />
        </CollapsibleSection>
        <CollapsibleSection>
          <TwoColumnImageBlurbs
            data={scoutProductPageTwoColumnImageBlurbsData}
          />
        </CollapsibleSection>
      </Collapsible>
    ),
    'Scout Product Page: Product Details': (
      <Collapsible isOpen={false} title="Product details">
        <CollapsibleSection>
          <ProductDetails data={scoutProductPageProductDetailsData} />
        </CollapsibleSection>
      </Collapsible>
    )
  }

  return components.hasOwnProperty(componentType)
    ? components[componentType]
    : null
}

export default ScoutHardcodedComponent
