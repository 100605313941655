import { ContentfulImage } from '@ecomm/contentful/components'
import { useLocale } from '@ecomm/data-hooks'
import { ContentfulRichText } from '@ecomm/shared-components'
import { match } from 'ts-pattern'

import type { TwoColumnSchema } from '../../templates/BusinessSecurity/schema'
import BusinessSecurityForm from './BusinessSecurityForm'

export default function TwoColumn(data: TwoColumnSchema) {
  const locale = useLocale()
  return (
    <div className="grid grid-cols-2 gap-4 md:gap-8 lg:gap-12">
      {match(data.leftSide)
        .with({ __typename: 'ContentfulFormV2' }, data => (
          <div
            className="order-2 col-span-2 md:order-1 md:col-span-1"
            data-component="TwoColumnLeftSide"
          >
            <BusinessSecurityForm {...data} />
          </div>
        ))
        .with({ __typename: 'ContentfulGrid' }, grid => (
          <div
            className="col-span-2 grid grid-cols-2 gap-4 lg:col-span-1"
            data-component="TwoColumnLeftSide"
          >
            {grid.gridItems.map((item, i) => (
              <div
                className="prose-h3:text-body-size prose-h3:font-bold prose-h3:mb-1 col-span-2 md:col-span-1"
                key={`grid-item-${i}`}
              >
                <ContentfulRichText {...item.text} />
              </div>
            ))}
          </div>
        ))
        .exhaustive()}
      {match(data.rightSide)
        .with({ __typename: 'ContentfulSmallText' }, smallText => (
          <div
            className="order-1 col-span-2 flex flex-col justify-center md:order-2 md:col-span-1"
            data-component="TwoColumnRightSide"
          >
            <ContentfulRichText {...smallText.text} />
          </div>
        ))
        .with({ __typename: 'ContentfulImageLink' }, imageLink => (
          <div
            className="relative col-span-2 flex flex-col items-center justify-start gap-6 p-5 md:gap-8 md:px-12 lg:col-span-1 lg:gap-12"
            data-component="TwoColumnRightSide"
          >
            <ContentfulImage
              classNameOverride="-z-[1] rounded-base w-full h-full md:max-h-80 left-0 top-0"
              description={imageLink.image.description}
              originalHeight={imageLink.image.file.details.image.height}
              originalWidth={imageLink.image.file.details.image.width}
              url={imageLink.image.file.url}
            />
            <span className="bg-neutral-dark absolute -top-2 left-0 flex h-20 w-20 -rotate-12 items-center justify-center rounded-full text-center text-sm font-bold text-white shadow-lg md:-left-2 md:-top-6 md:h-32 md:w-32 md:text-base">
              STARTING AT {locale === 'en-US' ? '$259' : '£199'}
            </span>
            <a className="btn btn-solid-primary" href={imageLink.url}>
              {imageLink.title}
            </a>
          </div>
        ))
        .exhaustive()}
    </div>
  )
}
