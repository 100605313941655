import type { ReactNode } from 'react'

type Props = {
  readonly children: {
    readonly PromoBanner: ReactNode
    readonly Header: ReactNode
    readonly Footer: ReactNode
    readonly Content: ReactNode
  }
}

export function MonitoringTemplate({ children }: Props) {
  return (
    <div className="prose md:prose-md lg:prose-lg">
      {children.PromoBanner}
      {children.Header}
      {children.Content}
      {children.Footer}
    </div>
  )
}
