import React from 'react'

import { replaceTags } from '.'
import type { ComparisonTableProps } from '../components/ComparisonTable/types'
import { parseJSONDataFromContentful } from './parseJSONDataFromContentful'
import type { ComparisonTableType } from '../data'

export function transformToComparisonTableData(
  data: Record<string, unknown>,
  key?: string
): ComparisonTableProps {
  const comparisonTableData: ComparisonTableType['comparisonTable']['content'] =
    !!key
      ? parseJSONDataFromContentful(data, key)
      : (data as ComparisonTableType['comparisonTable']['content'])

  return {
    ...comparisonTableData,
    footer: (
      <span>
        {comparisonTableData.footer.map((str, index) => (
          <span className="mt-2 block" key={index}>
            {replaceTags(str)}
            <br />
          </span>
        ))}
      </span>
    )
  }
}
