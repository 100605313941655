import { Link } from '@ecomm/framework'
import React from 'react'

function BannerProducts() {
  return (
    <div
      className="prose rounded-base border-neutral-light-50 md:prose-md lg:prose-lg my-2 flex flex-col items-center justify-center gap-4 border border-solid p-1 md:my-6 md:flex-row md:gap-6 md:p-2"
      data-component="Banner"
      id="banner"
    >
      <div className="text-center">
        <span>
          This is a legacy system. Upgrade to the latest SimpliSafe for
        </span>
        <span className="text-complementary-blue-100 mx-1">50% OFF</span>
        <span>with code</span>
        <span className="text-complementary-blue-100 mx-1">ALLNEW50</span>
      </div>
      <Link
        className="rounded-base border-btn-primary text-btn-primary hover:bg-btn-primary inline-block shrink-0 justify-center border-2 border-solid px-4 py-3 text-base no-underline hover:text-white md:w-auto md:text-lg"
        to="/allnew-customer-upgrade-50"
      >
        Build a system
      </Link>
    </div>
  )
}

export default BannerProducts
