import { parseContentfulJson } from '@ecomm/micro-copy'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from '@ecomm/framework'
import React from 'react'
import { useTracking } from 'react-tracking'

export function PrivacyDeepDive() {
  const { trackEvent } = useTracking()

  const handletrackEvent = () => {
    trackEvent({ event: 'link-click-privacy-policy-deep-dive-component' })
  }

  const staticQuery = useStaticQuery(graphql`
    #graphql
    query privacyPageDeepDive {
      copy: contentfulJson(contentful_id: { eq: "7rKExdpOQ2HflqMO99vsYk" }) {
        ...json
      }
    }
  `)

  const copy = parseContentfulJson(staticQuery, 'copy')

  return (
    <div className="my-6 text-center" data-component="PrivacyDeepDive">
      <h4 className="mb-0 text-2xl/7 font-bold">
        {copy.get('text_1')}{' '}
        <Link
          className="block w-full align-bottom text-lg font-normal text-inherit visited:text-inherit hover:no-underline md:inline md:w-auto md:pl-4"
          onClick={handletrackEvent}
          to="/legal/privacy-policy"
        >
          {copy.get('text_2')}
        </Link>
      </h4>
    </div>
  )
}
