import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export const useTrackMonitoringSelectionClick = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (plan: string) => {
      trackEvent({
        event: 'monitoring-selection-click',
        plan
      })
    },
    [trackEvent]
  )
}
