import { GatsbyHeroBannerSchema } from '@ecomm/shared-components'
import { Experience, mapApolloExperiences } from '@ecomm/shared-ninetailed'

import { useHeroBannerQuery } from '../../hooks/useHeroBannerQuery'
import { HeroBanner } from '@ecomm/monitoring/components'

type MonitoringHeroBannerProps = {
  readonly heroBannerData: GatsbyHeroBannerSchema
  readonly locale: string
}

export function MonitoringHeroBanner({
  heroBannerData,
  locale
}: MonitoringHeroBannerProps) {
  const apolloHeroBannerData = useHeroBannerQuery(heroBannerData.contentful_id)

  return locale === 'en-US' && apolloHeroBannerData ? (
    <Experience
      {...apolloHeroBannerData}
      component={HeroBanner}
      experiences={mapApolloExperiences(
        apolloHeroBannerData.ntExperiencesCollection.items
      )}
      key={apolloHeroBannerData.id}
    />
  ) : apolloHeroBannerData ? (
    <HeroBanner {...apolloHeroBannerData} />
  ) : null
}
