import { getRudderstack } from '@ecomm/shared-window'

export const trackCheckoutSummaryDetails = (label: string) => {
  getRudderstack(r =>
    r.track('checkout_summary_details', {
      label: label
    })
  )
}

export const trackCheckoutPaymentOption = (payment_option: string) => {
  getRudderstack(r =>
    r.track('checkout_payment_option', {
      payment_option
    })
  )
}

export type LoginStatus =
  | 'API_FAILURE'
  | 'INVALID_OR_CLOSED'
  | 'NO_DATA'
  | 'PROCESSING_ERROR'
  | 'SUCCESS'
  | 'TIMEOUT'
export type LoginLocation = 'CHECKOUT'
export type LoggedInProps = {
  readonly status: LoginStatus
  readonly location: LoginLocation
}

export const trackLoggedIn = (props: LoggedInProps) => {
  getRudderstack(r => r.track('logged_in', props))
}
