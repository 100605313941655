import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import {
  GatsbyImage,
  type GatsbyImageProps,
  getImage
} from 'gatsby-plugin-image'
import React, { useState } from 'react'

type Props = Omit<GatsbyImageProps, 'alt' | 'image'> & {
  readonly image: GatsbyImageSchema
}

/**
 * @deprecated Use `ContentfulImage` from `@ecomm/contentful/components` instead.
 * The performance for `GatsbyImage` is terrible in comparison to native HTML `img` elements with proper lazy loading.
 */
function InternalGatsbyImage({ image, ...rest }: Props) {
  const imageProp = getImage(image)
  const [isLoaded, setLoaded] = useState(false)
  return imageProp ? (
    <GatsbyImage
      alt={image?.description || image?.title || ''}
      decoding="sync"
      image={imageProp}
      imgStyle={{
        ...rest.imgStyle,
        ...(isLoaded ? { opacity: 1 } : {})
      }}
      loading="eager"
      onStartLoad={() => {
        setLoaded(true)
      }}
      {...rest}
    />
  ) : null
}

export { InternalGatsbyImage as GatsbyImage }
export type { GatsbyImageSchema }
