import { GatsbyImageSchema } from './image'

export function createMockGatsbyImage(
  src = '/test-image.png',
  title = 'Test Image',
  description?: string
): GatsbyImageSchema {
  return {
    gatsbyImageData: {
      height: 1,
      images: {
        fallback: { src: src },
        sources: [
          {
            media: '',
            srcSet: src,
            type: 'image/webp'
          }
        ]
      },
      layout: 'fullWidth',
      width: 1
    },
    title,
    description
  }
}
