import React from 'react'

export function Iframe({
  url,
  title
}: { readonly url: string; readonly title: string }) {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        maxWidth: '100%',
        width: '2000px',
        height: '1000px'
      }}
    >
      <iframe
        src={url}
        style={{
          border: '0',
          boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)',
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          maxWidth: '100%'
        }}
        title={title}
      ></iframe>
    </div>
  )
}
