import { usePlanChoiceExperience } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'

import type { OdmonFeatureFlagDraftSchema } from '../../../experiments/ODMONPrelaunch/schema'
import type { PackageMonitoringWrapperProps } from '../PackageMonitoringWrapper'
import type { DynamicPackageMonitoringWrapperProps } from '../DynamicPackageMonitoringWrapper'
import { useAmountSavedWithServicePlanBMS } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanBMS'
import { useAmountSavedWithServicePlanPackage } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanPackage'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'
import { useOdmonCameraImpactedEvent } from '../../../hooks/useOdmonCameraImpactedEvent'
import { DraftCartMonitoringWrapper } from '../DraftCartMonitoringWrapper'
import { PackageMonitoringWrapper } from '../PackageMonitoringWrapper'
import { DynamicPackageMonitoringWrapper } from '../DynamicPackageMonitoringWrapper'
import type { PriceToggleProps } from '../types'
import {
  OdmonPlanChoiceDraftCart,
  OdmonPlanChoiceDynamic,
  OdmonPlanChoicePackage
} from './OdmonPlanChoice/OdmonPlanChoiceTestContainer'

type OdmonHasOutdoorCameraProp = {
  readonly hasOutdoorCamera: boolean
}
type OdmonIntentProps = {
  readonly showedOdmonIntent: boolean
  readonly quizBreakIns: boolean
  readonly quizPackageTheft: boolean
}
type OdmonDraftProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PriceToggleProps
type OdmonDynamicPackageProps = DynamicPackageMonitoringWrapperProps &
  OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  OdmonIntentProps
type OdmonPackageProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PackageMonitoringWrapperProps

export function OdmonMonitoringDraftCart({
  hasOutdoorCamera,
  json,
  priceToggles,
  sku,
  total
}: OdmonDraftProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS()

  const planChoiceExperience = usePlanChoiceExperience()
  const odmonPlanChoiceTestObserver = useOdmonCameraImpactedEvent(
    planChoiceExperience,
    hasOutdoorCamera
  )

  switch (json?.tag) {
    case 'variant-1':
      return (
        <div>
          {odmonPlanChoiceTestObserver}
          <Experience
            {...planChoiceExperience.data}
            component={OdmonPlanChoiceDraftCart}
            experiences={planChoiceExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              hasOutdoorCamera,
              priceToggles
            }}
          />
        </div>
      )
    case 'control':
    default:
      return (
        <DraftCartMonitoringWrapper
          priceToggles={priceToggles}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringDynamic({
  hasOutdoorCamera,
  json,
  priceToggles,
  proSetup,
  quizBreakIns,
  quizPackageTheft,
  showProsetupHighlightedComponent,
  showedOdmonIntent,
  sku,
  total
}: OdmonDynamicPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS(total)

  const planChoiceExperience = usePlanChoiceExperience()
  const odmonPlanChoiceTestObserver = useOdmonCameraImpactedEvent(
    planChoiceExperience,
    hasOutdoorCamera
  )

  switch (json?.tag) {
    case 'variant-1':
      return (
        <div>
          {odmonPlanChoiceTestObserver}
          <Experience
            {...planChoiceExperience.data}
            component={OdmonPlanChoiceDynamic}
            experiences={planChoiceExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              hasOutdoorCamera,
              priceToggles,
              showedOdmonIntent,
              quizBreakIns,
              quizPackageTheft
            }}
          />
        </div>
      )
    case 'control':
    default:
      return (
        <DynamicPackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringPackage({
  hasOutdoorCamera,
  json,
  priceToggles,
  proSetup,
  showProsetupHighlightedComponent,
  sku
}: OdmonPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanPackage({
    sku: sku || ''
  })
  const planChoiceExperience = usePlanChoiceExperience()
  const odmonPlanChoiceTestObserver = useOdmonCameraImpactedEvent(
    planChoiceExperience,
    hasOutdoorCamera
  )

  switch (json?.tag) {
    case 'variant-1':
      return (
        <div>
          {odmonPlanChoiceTestObserver}
          <Experience
            {...planChoiceExperience.data}
            component={OdmonPlanChoicePackage}
            experiences={planChoiceExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              hasOutdoorCamera,
              priceToggles
            }}
          />
        </div>
      )
    case 'control':
    default:
      return (
        <PackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
        />
      )
  }
}
