import { GatsbyTrustPilot as TrustPilot } from '@ecomm/lander'
import { type ButtonFragment, AffirmBanner } from '@ecomm/shared-components'
import {
  type TwoColumnTextSchema,
  AnchorSection,
  ColoredSection,
  DuplexSection,
  GatsbyGridSection,
  ReviewSection,
  TwoColumnTextSection
} from '@ecomm/shared-sections'
import { GatsbySmallText as SmallText } from '@ecomm/shared-sections'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

import type { ComponentTypes } from '@ecomm/home-landing-data'
import HomeLandingButton from '../components/HomeLandingButton'
import HomeLandingFeatureSection from '../components/HomeLandingFeatureSection'
import HomeLandingHeroBanner from '../components/HomeLandingHeroBanner'
import LayoutReference from '../components/LayoutReference'

export const getMappedComponent = (component: ComponentTypes, index: number) =>
  match(component)
    .with({ __typename: literal('ContentfulReviewSection').value }, data => (
      /**
       * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
       *
       * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
       *
       * ECP-12405
       *
       * Please reach out to Josh and the platform team if you need to make any changes.
       *
       */
      <ReviewSection key={`ReviewSection_${index}`} {...data} />
    ))
    .with(
      { __typename: literal('ContentfulGrid').value },
      ({ contentful_id }) => (
        <GatsbyGridSection id={contentful_id} key={`Grid_${index}`} />
      )
    )
    .with({ __typename: literal('ContentfulDuplex').value }, data => (
      /**
       * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
       *
       * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
       *
       * ECP-12406
       *
       * Please reach out to Josh and the platform team if you need to make any changes.
       *
       */
      <DuplexSection key={`Duplex_${index}`} {...data} />
    ))
    .with({ __typename: literal('ContentfulFeatureSection').value }, data => (
      <HomeLandingFeatureSection
        key={`HomeLandingFeatureSection_${index}`}
        {...data}
      />
    ))
    /**
     * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
     *
     * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
     *
     * ECP-12397
     *
     * Please reach out to Josh and the platform team if you need to make any changes.
     *
     */
    .with({ __typename: literal('ContentfulAnchorSection').value }, data => (
      <AnchorSection
        key={`AnchorSection_${index}`}
        {...data}
        classNames="scroll-mt-24"
      />
    ))
    .with({ __typename: literal('ContentfulHeroBanner').value }, data => (
      <HomeLandingHeroBanner key={`HomeLandingHeroBanner_${index}`} {...data} />
    ))

    .with({ __typename: literal('ContentfulColoredSection').value }, data => (
      /**
       * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
       *
       * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
       *
       * ECP-12397
       *
       * Please reach out to Josh and the platform team if you need to make any changes.
       *
       */
      <ColoredSection key={`ColoredSection_${index}`} {...data} />
    ))
    .with(
      { __typename: literal('ContentfulWidgetTrustpilotBanner').value },
      data => <TrustPilot id={data.contentful_id} key={`TrustPilot-${index}`} />
    )
    .with({ __typename: literal('ContentfulLayoutReference').value }, data => (
      <LayoutReference key={`LayoutReference_${index}`} {...data} />
    ))
    .with({ __typename: literal('AffirmBanner').value }, data => (
      <AffirmBanner data={data} key={`AffirmBanner_${index}`} />
    ))
    .with(
      { __typename: literal('ContentfulButton').value },
      (data: ButtonFragment) => (
        <HomeLandingButton key={`HomeLandingButton_${index}`} {...data} />
      )
    )
    .with(
      { __typename: literal('ContentfulTwoColumnTextSection').value },
      (data: TwoColumnTextSchema) => (
        <TwoColumnTextSection
          className="prose-h3:text-body-size prose-h3:mb-3"
          key={`HomeLandingTwoColumnText${index}`}
          {...data}
        />
      )
    )
    .with({ __typename: literal('ContentfulSmallText').value }, data => (
      <SmallText id={data.contentful_id} />
    ))
    .exhaustive()
