import { ContentfulImage } from '@ecomm/contentful/components'
import { Modal } from '@ecomm/shared-components'
import { Cancel, CaretForward } from '@ecomm/shared-icons'
import { useTrackHomepage } from '@ecomm/tracking'
import React, { useState } from 'react'

import type { VideoButtonAsset } from '../../data'
import { ScoutVideo } from '../ScoutVideo'

export type VideoButtonWrapperProps = {
  readonly videoButtonAsset: VideoButtonAsset
}

export function VideoButtonWrapper({
  videoButtonAsset
}: VideoButtonWrapperProps) {
  const [showVideoModal, setShowVideoModal] = useState(false)

  const trackVideoBtnClick = useTrackHomepage()

  const handleVideoBtnClick = () => {
    trackVideoBtnClick('button-click-agents-play-video')
    setShowVideoModal(true)
  }

  return (
    <>
      <div
        className="bg-neutral-black flex max-h-[61px] min-h-[61px] cursor-pointer items-center justify-between rounded-xl p-0  md:max-h-[140px] md:min-h-[140px] md:rounded-2xl md:p-3 lg:min-h-[auto]"
        data-component="VideoButtonWrapper"
        data-testid="VideoButtonWrapper"
        onClick={handleVideoBtnClick}
      >
        <div className="lg:basis-4/14 basis-28 md:basis-56 items-center flex">
          {videoButtonAsset.videoButtonAsset.url ? (
            <ContentfulImage
              {...videoButtonAsset.videoButtonAsset}
              className="rounded-l-xl md:rounded-none"
              height={112}
              width={207}
            />
          ) : null}
        </div>
        <div className="ml-2 flex grow basis-7/12 items-center md:ml-4 md:block md:basis-5/12">
          <h1 className="mb-0 text-sm leading-4 text-white md:text-xl md:leading-8 lg:text-2xl">
            Agents can now help stop crime in real time
          </h1>
        </div>
        <div className="flex basis-2/12 justify-center md:basis-3/12">
          <button
            className="border-neutral-light-50 hover:text-neutral-black hidden w-full cursor-pointer justify-center rounded-[0.625rem] border-2 border-solid bg-transparent p-2 text-center text-base text-white transition duration-200 hover:bg-white md:block md:w-auto md:text-lg"
            type="button"
          >
            <span className="leading-normal">Play video</span>
          </button>
          <button
            aria-label="Play Video"
            className="bg-complementary-blue-100 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-none active:pl-2 active:pt-1 active:brightness-110 md:hidden"
            type="button"
          >
            <CaretForward className="h-3 w-3" />
          </button>
        </div>
      </div>
      <Modal
        isOpen={showVideoModal}
        onRequestClose={() => setShowVideoModal(false)}
      >
        <button
          aria-label="close"
          className="absolute right-3 top-4 z-20 h-6 w-6 cursor-pointer border-none bg-transparent p-0 hover:opacity-100"
          data-component="CloseButton"
          onClick={() => setShowVideoModal(false)}
        >
          <Cancel className="h-full w-full text-white" />
        </button>
        <ScoutVideo autoPlay className="max-w-4xl" type="ScoutHomePageVideo" />
      </Modal>
    </>
  )
}
