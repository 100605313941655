import { ContentfulRichText, FormTextInput } from '@ecomm/shared-components'
import classNames from 'classnames'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import z from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { EmailSubmissionFormProps } from '../types'
import { trackLeadSaveQuote } from '@ecomm/cdp-tracking'

const EMAIL_ERROR = 'Please enter a valid email address.'
const emailSchema = toFormikValidationSchema(
  z.object({
    email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR)
  })
)

function EmailSubmissionForm({
  label,
  placeholder,
  onSubmit,
  disclaimerRaw,
  buttonText,
  emailAddress = ''
}: EmailSubmissionFormProps) {
  return buttonText ? (
    <>
      <Formik
        initialValues={{ email: emailAddress }}
        onSubmit={onSubmit}
        validationSchema={emailSchema}
      >
        {({ isValid }) => (
          <Form noValidate>
            <div className="my-4 flex flex-col">
              <label className="my-1" htmlFor="email">
                {label}
              </label>
              <FormTextInput
                className="h-14"
                name="email"
                placeholder={placeholder}
                type="email"
              />
              <ErrorMessage
                className="text-complementary-red-100"
                component="span"
                name="email"
              />
            </div>
            <button
              className={classNames('w-48 min-w-min btn', {
                'btn-solid-primary': isValid,
                'btn-disabled': !isValid
              })}
              disabled={!isValid}
              onClick={() => trackLeadSaveQuote(buttonText)}
              type="submit"
            >
              {buttonText}
            </button>
          </Form>
        )}
      </Formik>
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line prose-p:text-sm">
        {disclaimerRaw ? <ContentfulRichText raw={disclaimerRaw} /> : null}
      </div>
    </>
  ) : null
}

export default EmailSubmissionForm
