import { useContentful } from '@ecomm/shared-apollo'
import {
  type ExperimentSchema,
  v2HeroBannerSchema as heroBannerSchema
} from '@ecomm/shared-components'
import { useMemo } from 'react'

import { HERO_BANNER_QUERY } from './hero_banner_query'

export const useHeroBannerQuery = (bannerId: string) => {
  const { data: apolloData } = useContentful(HERO_BANNER_QUERY, {
    id: bannerId
  })

  const heroBannerData = useMemo(() => {
    // we need to sanitize variants out of nt_exoeriences to avoid nullish values
    const sanitizedApolloData = JSON.parse(JSON.stringify(apolloData))

    const updatedItems =
      // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
      sanitizedApolloData.heroBanner?.ntExperiencesCollection?.items.map(
        (item: ExperimentSchema) => {
          const newVariants = item.variants.items.filter(variant => variant.id)
          return {
            ...item,
            variants: {
              items: newVariants
            }
          }
        }
      )

    const updatedApolloData = {
      heroBanner: {
        // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
        ...sanitizedApolloData.heroBanner,
        ntExperiencesCollection: {
          items: updatedItems || []
        }
      }
    }

    return heroBannerSchema.parse(updatedApolloData.heroBanner)
  }, [apolloData])

  return heroBannerData
}
