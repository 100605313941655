import { useGetCartLineItemDescription } from '@ecomm/cart-line-items'
import { Text } from '@ecomm/ss-react-components'

import { ContentfulProductInformation } from '../../../../graphql'
import ContentfulRichText from '../../ContentfulRichText'
import usePriceVariation from '../../../hooks/usePriceVariation'
import { prop } from '@simplisafe/ewok'

export type CartLineItemDescriptionProps = {
  readonly onClick: (_url: string) => void
  readonly productInformation: ContentfulProductInformation
  readonly sku: string
}

function CartLineItemDescription({
  onClick,
  productInformation,
  sku
}: CartLineItemDescriptionProps) {
  const planProduct = usePriceVariation(sku)
  const planProductPrice = planProduct.cata(
    () => 0,
    value => prop('price', value)
  )

  const description = useGetCartLineItemDescription(
    planProductPrice,
    productInformation.description?.raw
  )
  const references = productInformation.description?.references

  return (
    <div data-component="CartLineItemDescriptionWrapper">
      <Text
        className="prose-p:!text-[#78746F] prose-p:max-md:!text-xs text-[#78746F]"
        useTailwind
      >
        <ContentfulRichText
          onLinkClick={(e, uri) => {
            e.preventDefault()
            onClick && onClick(uri)
          }}
          raw={description}
          // @ts-expect-error TS(2322): Type 'readonly Maybe<ContentfulAssetContentfulLink... Remove this comment to see the full error message
          references={references}
        />
      </Text>
    </div>
  )
}

export default CartLineItemDescription
