import { useMicroCopy } from '@ecomm/micro-copy'
import { useFormStyles, useHandleFormSubmission } from '@ecomm/promotions-hooks'
import { leadingSlashIt } from '@simplisafe/ewok'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Link } from '@ecomm/framework'
import { useState } from 'react'
import { useTracking } from 'react-tracking'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { LeadCaptureSuccess } from '../LeadCaptureForm/LeadCaptureSuccess'
import { PromoButton } from '../PromoButton'
import { PhoneNumberFormSchema } from './phoneSchema'

export function PhoneLeadCaptureForm() {
  const microCopy = useMicroCopy()
  const { defaultPhoneNumber, handlePhoneNumberSubmit } =
    useHandleFormSubmission()
  const { textStyles } = useFormStyles()
  const { trackEvent } = useTracking()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const onClickPrivacyPolicy = () => {
    trackEvent({ event: 'promo_privacy_policy' })
  }

  return !isFormSubmitted ? (
    <Formik
      enableReinitialize={true}
      initialValues={{ phone: defaultPhoneNumber }}
      onSubmit={values => {
        handlePhoneNumberSubmit(values.phone)
        setIsFormSubmitted(true)
      }}
      validationSchema={toFormikValidationSchema(PhoneNumberFormSchema)}
    >
      {({ isSubmitting, errors, values }) => (
        <Form data-component="PhoneLeadCaptureForm">
          <div className="flex items-end gap-2">
            <div className="flex w-full flex-col gap-1">
              <label
                className={`font-medium ${textStyles} max-lg:text-neutral-black`}
                htmlFor="lead-phoneNumber"
              >
                Phone number
              </label>
              <Field
                className={classNames(
                  'border-neutral-medium-100 h-[51px] w-full rounded-xl border border-solid pl-[20px] text-[16px] lg:text-[14px]',
                  {
                    'text-sale border-sale': values.phone && errors.phone
                  }
                )}
                id="lead-phoneNumber"
                name="phone"
                placeholder={'Enter your phone number (optional)'}
                type="phone"
              />
            </div>
            <PromoButton
              isSubmitting={isSubmitting}
              text={microCopy['claim-offer']}
            />
          </div>
          <p className="text-sale m-0 min-h-[20px] p-0 pl-1 pt-1 text-xs">
            {values.phone ? errors.phone : null}
          </p>
          <p
            className={`max-lg:text-neutral-black m-0 p-0 text-xs font-thin max-lg:text-center ${textStyles}`}
          >
            By adding your phone number and clicking &quot;Claim offer&quot; you
            agree to receive marketing phone calls or text messages, and accept
            the details in our{' '}
            <Link
              className={`max-lg:text-neutral-black font-semibold ${textStyles}`}
              onClick={onClickPrivacyPolicy}
              to={leadingSlashIt('/legal/privacy-policy')}
            >
              Privacy Policy
            </Link>
            . You can opt-out any time by responding STOP. Standard message and
            data rates may apply.
          </p>
        </Form>
      )}
    </Formik>
  ) : (
    <LeadCaptureSuccess />
  )
}
