import { parseWithoutNulls } from '@simplisafe/ewok'
import {
  partnerWithUsLayoutSchema,
  partnerWithUsPageContentSchema
} from '@ecomm/home-landing-data'

export const usePartnerWithUsPageFragment = <
  T extends { readonly contentfulPartnerWithUsLandingPage: U },
  U
>(
  query: T
) => {
  const partnerWithUsFragment = query.contentfulPartnerWithUsLandingPage

  const partnerWithUsPage = parseWithoutNulls(
    partnerWithUsPageContentSchema,
    partnerWithUsFragment
  )
  const layout = parseWithoutNulls(
    partnerWithUsLayoutSchema,
    partnerWithUsPage.layout
  )

  return {
    ...partnerWithUsPageContentSchema.parse(partnerWithUsPage),
    layout: partnerWithUsLayoutSchema.parse(layout)
  }
}
