import { useCartShippingAddress, useCartState } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { fetchPaymentMethod } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { ZuoraErrorResponse, ZuoraSuccessResponse } from '@ecomm/data-zuora'
import { useIsVisaGiftCardPromotionActive } from '@ecomm/promotions-components'
import { setCookie } from '@ecomm/shared-cookies'
import {
  TrackingData,
  brazeLogCustomEvent,
  useTrackMetricEvent,
  useTrackingPaymentForm,
  visitorIdAtAt
} from '@ecomm/tracking'
import { TrackEvent } from '@ecomm/tracking/src/analytics'
import { path, voidFn } from '@simplisafe/ewok'
import { localeInfo } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { PaymentMethodResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import { fetchSafeTechCollectorInfo } from '@simplisafe/ss-ecomm-data/simplisafe/paymentsClient'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { exists, window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { OrderData, ZuoraOrderData, createOrder } from './createOrder'
import submitAffirmOrder from './submitAffirmOrder'
import { handleZuoraError, handleZuoraSuccess } from './submitZuoraOrder'
import { useAffirmCheckoutData } from './useAffirmCheckoutData'
import {
  handlePreactivationCookie,
  handlePreactivationEvents
} from './utils/preactivation'

export function trackOrderSubmissionFailed(
  trackEvent: TrackEvent,
  error: string
) {
  trackEvent({
    submitOrderFailed: true,
    submitOrderFailedErrorMessage: error
  })
}

/* To trigger the gtm event when the error is occured */
export const handlePaymentErrorEvent =
  (trackEvent: (_data: TrackingData) => void) => (e: Error) => {
    trackEvent({
      errorMessage: e,
      event: 'buttonClick'
    })
  }

export const fetchZuoraPaymentForm = async (
  onError: () => void,
  onSuccess: (_res: O.Option<PaymentMethodResponse>) => void
) => await fetchPaymentMethod(onError, onSuccess, false)

// todo centralize -- also in ZuoraPaymentForm and PaymentFormWrapper.types
export type PaymentState =
  | 'complete'
  | 'error'
  | 'loading'
  | 'processing'
  | 'ready'

// Recommended method for validating window prior to SSR per Gatsby documentation: https://www.gatsbyjs.com/docs/debugging-html-builds/
const isBrowser = exists(window)

type BrazeEventsState = {
  readonly [experiment: string]: boolean
}

const VISA_GIFT_CARD_EVENT = 'Received_GiftCard_Offer'

export const usePaymentJotai = (
  // allows passing in a different value for affirmClient in unit tests
  isAffirmExperience = false,
  affirmClient = isBrowser ? window.affirm : null
) => {
  const locale = useLocale()
  const domain = path([locale, 'domain'], localeInfo)
  const sessionId = visitorIdAtAt()
  const [brazeEventsSent, setBrazeEventsSent] = useState<BrazeEventsState>({})

  const trackEvent = useTrackingPaymentForm()

  const cartState = useCartState()
  const shippingAddress = useCartShippingAddress()

  const cartId = getCartId()

  const trackMetricEvent = useTrackMetricEvent()

  const [paymentState, setPaymentState] = useState<PaymentState>('ready')
  const [errorMessage, setErrorMessage] = useState('')

  const [paymentMethodRequired, setPaymentMethodRequired] = useState(true)
  const [zuoraPaymentMethod, setZuoraPaymentMethod] =
    useState<PaymentMethodResponse>()
  const [safeTechSdkUrl, setSafeTechSdkUrl] = useState<string>()

  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.outerWidth : 0
  )
  const cartExists = cartState === 'with_items'

  const [manuallyLoadedZuoraForm, setManuallyLoadedZuoraForm] = useState(false)

  const affirmCheckoutData = useAffirmCheckoutData(isAffirmExperience)

  const email = shippingAddress?.email

  useEffect(() => {
    setCookie('email', email, cookiesOption)
  }, [email])

  // For Zuora, the SafeTech SDK URL is managed by the payment decorator API
  useEffect(() => {
    const shouldFetch = !safeTechSdkUrl && cartExists

    const fetch = () => {
      fetchSafeTechCollectorInfo({
        domain,
        sessionId
      })(() => {
        setSafeTechSdkUrl('')
      })(response => {
        response.forEach(response => {
          setSafeTechSdkUrl(response.sdkUrl)
        })
      })
    }

    shouldFetch && fetch()
  }, [domain, safeTechSdkUrl, sessionId, cartExists])

  // Fetch the Zuora payment method
  useEffect(() => {
    const shouldFetch =
      paymentState !== 'loading' && paymentMethodRequired && cartExists

    const fetch = async () => {
      setPaymentState('loading')
      setPaymentMethodRequired(false)
      await fetchZuoraPaymentForm(
        () => {
          setPaymentState('error')
        },
        response => {
          pipe(
            response,
            O.map(rsp => {
              setZuoraPaymentMethod(rsp)
              setPaymentState('ready')
            })
          )
        }
      )
    }
    shouldFetch && fetch()
  }, [paymentMethodRequired, paymentState, cartExists])

  const refreshZuoraForm = (force = false) => {
    const fetchAndMark = () => {
      setPaymentMethodRequired(true)
      setManuallyLoadedZuoraForm(true)
    }
    ;(!manuallyLoadedZuoraForm || force) && fetchAndMark()
  }

  const handleHPM = () => {
    window.handleHPMFailure = (callbackResponse: ZuoraErrorResponse) => {
      // Signals to the payment form to re-render with an error message
      handleZuoraError(
        callbackResponse,
        () => setPaymentState('error'),
        handleError,
        trackEvent
      )
    }

    window.handleHPMSuccess = (callbackResponse: ZuoraSuccessResponse) => {
      handleZuoraSuccess(callbackResponse, handleCreateOrder, () =>
        setPaymentState('processing')
      )
    }
  }

  isBrowser && handleHPM()

  const removeIFrame = () => {
    const zuoraIFrame: O.Option<HTMLElement> = O.fromNullable(
      document.getElementById('z_hppm_iframe')
    )

    pipe(
      zuoraIFrame,
      O.map(iframeElement => iframeElement.remove())
    )
  }
  const handleResizeDebounced = debounce(500, () => {
    const width = isBrowser ? window.outerWidth : 0
    const fn = () => {
      removeIFrame()
      // Trigger a re-render of the payment form
      setPaymentMethodRequired(true)
      // Track the window width to only re-render when the width has changed, preventing issues on mobile
      setWindowWidth(width)
    }
    windowWidth !== width && fn()
  })

  useEffect(() => {
    paymentState === 'error' && removeIFrame()
  }, [paymentState])

  const resizeRef = useRef(handleResizeDebounced)

  useEffect(() => {
    resizeRef.current = handleResizeDebounced
    const resizeHandler = () => resizeRef.current()
    isBrowser && window.addEventListener('resize', resizeHandler)
    return () => {
      isBrowser && window.removeEventListener('resize', resizeHandler)
    }
  }, [windowWidth, handleResizeDebounced])

  const handleError = (error: Error) => {
    handlePaymentErrorEvent(trackEvent)(error)
    trackOrderSubmissionFailed(trackEvent, `payment error occurred ${error}`)
    setPaymentMethodRequired(true)
    setErrorMessage(error.message ?? '')
  }

  const handleAffirmError = useCallback(
    (error: Error) => {
      setPaymentState('loading')
      handlePaymentErrorEvent(trackEvent)(error)
      trackOrderSubmissionFailed(trackEvent, `payment error occurred ${error}`)
    },
    [trackEvent]
  )

  /* Trigger GTM/Optimizely events and set the preactivation cookie */
  const handlePreactivationReady = useCallback(
    (webappToken: string) => {
      handlePreactivationEvents(trackEvent)(voidFn)
      handlePreactivationCookie(webappToken)
    },
    [trackEvent]
  )

  /**
   *  Visa Gift Card Promo Experiments - Start
   */
  const isVisaGiftCardPromotionActive = useIsVisaGiftCardPromotionActive()

  const sendBrazeEvent = (eventName: string) => {
    brazeLogCustomEvent(eventName)
    setBrazeEventsSent(currentEventsState => ({
      ...currentEventsState,
      [eventName]: true
    }))
  }
  /**
   *  Visa Gift Card/ProInstall Promo Experiments - End
   */

  /* Partially applied `createOrder` call. The returned function takes order data and submits the order. */
  const handleCreateOrder = useCallback(
    (orderData: OrderData) =>
      createOrder({
        cartId: cartId ?? '',
        // Toggles payment complete message while post payment flows initialize
        onPaymentComplete: () => {
          setPaymentState('complete')
          // VISA Card Promo Experiment
          isVisaGiftCardPromotionActive &&
            !brazeEventsSent[VISA_GIFT_CARD_EVENT] &&
            sendBrazeEvent(VISA_GIFT_CARD_EVENT)
        },
        onPaymentError: handleAffirmError,
        onPreactivationReady: handlePreactivationReady,
        trackMetricEvent: trackMetricEvent
      })(orderData),
    [cartId, handleAffirmError, handlePreactivationReady, trackMetricEvent]
  )

  const handleZuoraFormRender = useCallback(() => {
    paymentState !== 'error' && setPaymentState('ready')
  }, [paymentState])

  const handleSubmitAffirmOrder = useCallback(
    (setFormSubmitted: (isSubmitted: boolean) => void) => {
      const onCanceled = () => {
        setFormSubmitted && setFormSubmitted(false)
        return setPaymentState('ready')
      }
      const onError = (error: Error) => {
        setFormSubmitted && setFormSubmitted(false)
        return handleAffirmError(error)
      }
      const onProcessing = () => {
        setFormSubmitted && setFormSubmitted(true)
        return setPaymentState('processing')
      }

      submitAffirmOrder({
        affirmCheckoutData: O.fromNullable(affirmCheckoutData),
        affirmClient,
        createOrder: handleCreateOrder,
        onPaymentCanceled: onCanceled,
        onPaymentError: onError,
        // Controls the loader when payment has been submitted
        onPaymentProcessing: onProcessing,
        trackMetricEvent: trackMetricEvent
      })
    },
    [
      affirmCheckoutData,
      affirmClient,
      handleCreateOrder,
      trackMetricEvent,
      handleAffirmError
    ]
  )

  const handleSubmitSavedZuoraPaymentOrder = useCallback(
    (zuoraPaymentMethodId: string) => {
      setPaymentState('processing')
      const data: ZuoraOrderData = {
        paymentMethodId: zuoraPaymentMethodId,
        token: 'dummy token',
        type: 'credit',
        provider: 'zuora'
      }

      handleCreateOrder(data)
    },
    [handleCreateOrder]
  )

  return {
    errorMessage,
    handleSubmitAffirmOrder,
    handleZuoraFormRender,
    handleSubmitSavedZuoraPaymentOrder,
    paymentState,
    refreshZuoraForm,
    safeTechSdkUrl,
    zuoraPaymentMethod
  }
}
