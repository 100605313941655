import {
  APP_FEATURES_CAROUSEL_ASSET_QUERY,
  APP_WIDGET_QUERY,
  appFeaturesCarouselAssetsSchema,
  appWidgetDataSchema,
  CAMERA_BANNER_ASSET_QUERY,
  cameraBannnerAssetSchema,
  COMPARISON_TABLE_QUERY,
  comparisonTableSchema,
  CONFIDENCE_BAR_QUERY,
  confidenceBarSchema,
  EXPERT_REVIEWS_ADDITIONAL_QUERY,
  EXPERT_REVIEWS_QUERY,
  EXPERT_SECTION_ASSET_QUERY,
  expertReviewsAdditionalSchema,
  expertReviewsSchema,
  EXPERTS_REVIEWS_ASSET_QUERY,
  expertSectionAssetSchema,
  expertsReviewsAssetSchema,
  GUARANTEE_ASSET_QUERY,
  GUARANTEE_COPY_QUERY,
  guaranteeAssetSchema,
  guaranteeCopySchema,
  VIDEO_BUTTON_ASSET_QUERY,
  videoButtonAssetSchema
} from '@ecomm/scout'
import { useContentful } from '@ecomm/shared-apollo'

import {
  AFFIRM_BANNER_QUERY,
  affirmBannerApolloSchema,
  USER_REVIEWS_QUERY,
  userReviewsSchema
} from '@ecomm/shared-data'
import { Map as ImmutableMap } from 'immutable'
import { useMemo } from 'react'

import { QUOTE_WIZARD_ASSET_QUERY } from './QuizBanner/quoteWizardAssetQuery'
import { quoteWizardAssetSchema } from './QuizBanner/quoteWizardAssetSchema'

export const useQuoteWizardAssetQuery = (id = '4mI9KaGvxh51fi1GYOSaVr') => {
  const { data } = useContentful(QUOTE_WIZARD_ASSET_QUERY, { id })
  return useMemo(() => quoteWizardAssetSchema.parse(data), [data])
}

export const useCameraBannerAssetQuery = () => {
  const { data } = useContentful(CAMERA_BANNER_ASSET_QUERY)
  return useMemo(() => cameraBannnerAssetSchema.parse(data), [data])
}

export const useGuaranteeCopyQuery = () => {
  const { data } = useContentful(GUARANTEE_COPY_QUERY)
  const guaranteeCopy = useMemo(() => guaranteeCopySchema.parse(data), [data])
  return ImmutableMap(guaranteeCopy.guaranteeCopy.content)
}

export const useUserReviewsDataQuery = () => {
  const { data } = useContentful(USER_REVIEWS_QUERY)
  return useMemo(() => userReviewsSchema.parse(data), [data])
}

export const useVideoButtonAssetQuery = () => {
  const { data } = useContentful(VIDEO_BUTTON_ASSET_QUERY)
  return useMemo(() => videoButtonAssetSchema.parse(data), [data])
}

export const useExpertReviewsQuery = () => {
  const { data } = useContentful(EXPERT_REVIEWS_QUERY)
  return useMemo(() => expertReviewsSchema.parse(data), [data])
}

export const useAffirmBannerQuery = () => {
  const { data } = useContentful(AFFIRM_BANNER_QUERY)
  return useMemo(
    () => affirmBannerApolloSchema.parse(data?.affirmBanner),
    [data]
  )
}

export const useExpertReviewsAssetQuery = (imageIds: readonly string[]) => {
  const { data } = useContentful(EXPERTS_REVIEWS_ASSET_QUERY, { ids: imageIds })
  return useMemo(
    () => expertsReviewsAssetSchema.parse(data?.expertReviewsAssets),
    [data]
  )
}
export const useExpertSectionAssetQuery = () => {
  const { data } = useContentful(EXPERT_SECTION_ASSET_QUERY)
  return useMemo(() => expertSectionAssetSchema.parse(data), [data])
}

export const useExpertReviewsAdditionalQuery = () => {
  const { data } = useContentful(EXPERT_REVIEWS_ADDITIONAL_QUERY)
  return useMemo(
    () => expertReviewsAdditionalSchema.parse(data.expertReviewsAdditional),
    [data]
  )
}

export const useAppWidgetQuery = () => {
  const { data } = useContentful(APP_WIDGET_QUERY)
  return useMemo(() => appWidgetDataSchema.parse(data), [data])
}

export const useAppFeaturesCarouselAssetsQuery = (
  assetIds: readonly string[]
) => {
  const { data } = useContentful(APP_FEATURES_CAROUSEL_ASSET_QUERY, {
    ids: assetIds
  })
  return useMemo(
    () => appFeaturesCarouselAssetsSchema.parse(data.appFeaturesCarouselAssets),
    [data]
  )
}
export const useComparisonTableQuery = (id: string) => {
  const { data } = useContentful(COMPARISON_TABLE_QUERY, { id })
  return useMemo(() => comparisonTableSchema.parse(data), [data])
}

export const useConfidenceBarQuery = (id: string) => {
  const { data } = useContentful(CONFIDENCE_BAR_QUERY, { id })
  return useMemo(() => confidenceBarSchema.parse(data)?.confidenceBar, [data])
}

export const useGuaranteeAssetQuery = () => {
  const { data } = useContentful(GUARANTEE_ASSET_QUERY)
  return useMemo(() => guaranteeAssetSchema.parse(data), [data])
}
