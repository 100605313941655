import classNames from 'classnames'
import type { ReactElement } from 'react'

import type { MonitoringPlanType } from '../../DraftCart/types'
import { CheckCircleSolid } from '@ecomm/shared-icons'

export type OdmonPlanChoiceExpCardProps = {
  readonly title: string
  readonly subtitle: string
  readonly cardId: number
  readonly description: ReactElement
  readonly pricePerDay: string
  readonly monitoringType: MonitoringPlanType
  readonly isSelected?: boolean
  readonly onClick: ({
    monitoringType
  }: {
    readonly monitoringType: MonitoringPlanType
  }) => void
}

export function OdmonPlanChoiceExpCard({
  title,
  subtitle,
  description,
  pricePerDay,
  monitoringType,
  isSelected = false,
  onClick
}: OdmonPlanChoiceExpCardProps) {
  return (
    <div
      className={classNames(
        'relative flex w-full flex-col justify-stretch gap-2 md:flex-row md:gap-4 md:pt-4'
      )}
    >
      <button
        className={classNames(
          'flex w-full grow cursor-pointer flex-col rounded-[20px] border-solid box-content bg-white px-0 py-3 md:py-4',
          {
            'border-neutral-medium-100 border ': !isSelected,
            'border-complementary-blue-100 border-[3px] -m-0.5': isSelected,
            'shadow-md': isSelected
          }
        )}
        onClick={() => {
          onClick({ monitoringType })
        }}
      >
        <div className="mb-2 flex w-full flex-col md:grow">
          <div className="px-5 md:px-4 flex justify-center">
            <span className="text-neutral-black text-lg md:text-xl font-bold text-center">
              {title}
            </span>
            <div
              className={classNames('relative h-full flex items-center', {
                hidden: !isSelected
              })}
            >
              <CheckCircleSolid className="text-complementary-blue-100 h-5 w-5 ml-0.5 mt-0.5 shrink-0 absolute" />
            </div>
          </div>
          <span className="text-sm md:text-base text-center px-5 md:px-4">
            {subtitle}
          </span>

          <div className="w-full text-center bg-neutral-light-50 py-2 my-3">
            <div className="text-neutral-black text-sm md:text-base">
              <strong>{pricePerDay}</strong>/day
            </div>
            <div className="text-complementary-red-100 font-bold text-base md:text-lg">
              1st month FREE
            </div>
            <div className="text-[var(--neutral-dark-100)] text-[10px]/[14px]">
              No contracts. Cancel anytime.
            </div>
          </div>

          <div className="text-neutral-black text-xs md:text-sm leading-5 text-start px-5 md:px-4">
            {description}
          </div>
        </div>
      </button>
    </div>
  )
}
