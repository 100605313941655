import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import {
  type GatsbyHeroBannerSchema,
  type GuidedSystemBuilderSchema,
  type HeroBannerColumnSchema,
  type HeroBannerSchema,
  heroBannerSchema,
  type MinimalHeroBannerColumnSchema,
  type RefactoredHeroBannerSchema,
  refactoredHeroBannerSchema
} from './schema'

export const heroColumnMockData: HeroBannerColumnSchema = {
  backgroundColor: 'complementaryBlue100',
  contentVerticalAlignment: 'center',
  description: {
    json: {
      data: {},
      content: [
        {
          nodeType: BLOCKS.HEADING_1,
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Home Security Systems {{location}}',
              marks: [],
              data: {}
            }
          ]
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          data: {
            target: {
              sys: {
                id: '5nTyXzFGiU8sMURfKPKhBo',
                type: 'Link',
                linkType: 'Entry'
              }
            }
          },
          content: []
        },
        {
          nodeType: BLOCKS.EMBEDDED_ENTRY,
          data: {
            target: {
              sys: {
                id: '6vRiig0cXsyYJC5KtSLt4J',
                type: 'Link',
                linkType: 'Entry'
              }
            }
          },
          content: []
        },
        {
          nodeType: BLOCKS.PARAGRAPH,
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {}
            },
            {
              nodeType: INLINES.HYPERLINK,
              data: {
                uri: '/build-my-system'
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'Or build your own system',
                  marks: [],
                  data: {}
                }
              ]
            },
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {}
            },
            {
              nodeType: INLINES.EMBEDDED_ENTRY,
              data: {
                target: {
                  sys: {
                    id: 'youtube',
                    type: 'Link',
                    linkType: 'Entry'
                  }
                }
              },
              content: []
            }
          ]
        }
      ],
      nodeType: BLOCKS.DOCUMENT
    },
    links: {
      entries: {
        block: [
          {
            __typename: 'QuoteWizard',
            id: '5nTyXzFGiU8sMURfKPKhBo',
            sys: {
              id: '5nTyXzFGiU8sMURfKPKhBo'
            },
            jebbitUrl:
              'https://quiz.simplisafe.com/kaedkhe4?L=Full+Page&deferred=true',
            type: 'hero',
            buttonText: 'Take our quiz',
            ntExperiencesCollection: {
              items: []
            }
          }
        ],
        inline: []
      }
    }
  },
  hasPromoSticker: false,
  image: {
    url: '/example-desktop',
    description: 'Example image desktop',
    originalWidth: 100,
    originalHeight: 100
  },
  imageTablet: {
    url: '/example-tablet',
    description: 'Example image tablet',
    originalWidth: 100,
    originalHeight: 100
  },
  imageMobile: {
    url: '/example-mobile',
    description: 'Example image mobile',
    originalWidth: 100,
    originalHeight: 100
  },
  linkColor: 'neutralWhite',
  textAlignment: 'left',
  textColor: 'neutralWhite'
}

export const sleekHeroColumnMockData: HeroBannerColumnSchema = {
  backgroundColor: 'neutralBlack',
  contentVerticalAlignment: 'center',
  hasPromoSticker: false,
  description: {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Example text',
              nodeType: 'text'
            }
          ],
          nodeType: BLOCKS.HEADING_1
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'What if you knew that no matter where you were or\nwhat you were doing, if there was a break-in at your\nhome someone would be there to alert the police? We’ve\ngot you covered.\n\nMeet SimpliSafe’s industry-leading\nprofessional monitoring.',
              nodeType: 'text'
            }
          ],
          nodeType: BLOCKS.PARAGRAPH
        }
      ],
      nodeType: BLOCKS.DOCUMENT
    },
    links: { entries: { block: [] } }
  },
  image: {
    url: '/example-desktop',
    description: 'Example image desktop',
    originalWidth: 100,
    originalHeight: 100
  },
  imageMobile: {
    url: '/example-mobile',
    description: 'Example image mobile',
    originalWidth: 100,
    originalHeight: 100
  },
  imageTablet: {
    url: '/example-tablet',
    description: 'Example image tablet',
    originalWidth: 100,
    originalHeight: 100
  },
  logo: {
    url: '/example-logo',
    description: 'Example logo',
    originalWidth: 100,
    originalHeight: 100
  }
}

export const minimalHeroColumnMockData: MinimalHeroBannerColumnSchema = {
  __typename: 'HeroBannerColumn',
  backgroundColor: 'neutralBlack',
  id: 'column-test-id'
}

export const heroColumnMock = (): HeroBannerColumnSchema => {
  return heroColumnMockData
}

export const sleekHeroColumnMock = (): HeroBannerColumnSchema =>
  sleekHeroColumnMockData

export const heroBannerMockData: HeroBannerSchema = heroBannerSchema.parse({
  id: {
    id: 'some-id'
  },
  __typename: 'HeroBanner',
  disclaimerText: 'Disclaimer text',
  floatingBadge: true,
  heightType: 'any',
  isFullWidth: true,
  roundedCorners: false,
  contentCollection: {
    items: [
      {
        __typename: 'HeroBannerColumn',
        backgroundColor: 'neutralBlack',
        contentVerticalAlignment: 'center',
        description: {
          json: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Example text',
                    nodeType: 'text'
                  }
                ],
                nodeType: BLOCKS.HEADING_1
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value:
                      'What if you knew that no matter where you were or\nwhat you were doing, if there was a break-in at your\nhome someone would be there to alert the police? We’ve\ngot you covered.\n\nMeet SimpliSafe’s industry-leading\nprofessional monitoring.',
                    nodeType: 'text'
                  }
                ],
                nodeType: BLOCKS.PARAGRAPH
              }
            ],
            nodeType: BLOCKS.DOCUMENT
          }
        },
        image: {
          url: '/example-desktop',
          description: 'Example image desktop',
          originalWidth: 100,
          originalHeight: 100
        },
        imageMobile: {
          url: '/example-mobile',
          description: 'Example image mobile',
          originalWidth: 100,
          originalHeight: 100
        },
        imageTablet: {
          url: '/example-tablet',
          description: 'Example image tablet',
          originalWidth: 100,
          originalHeight: 100
        },
        logo: {
          url: '/example-logo',
          description: 'Example logo',
          originalWidth: 100,
          originalHeight: 100
        }
      }
    ]
  },
  ntExperiencesCollection: {
    items: []
  }
})

export const refactoredHeroBannerMockData: RefactoredHeroBannerSchema =
  refactoredHeroBannerSchema.parse({
    id: {
      id: 'some-id'
    },
    __typename: 'HeroBanner',
    disclaimerText: 'Disclaimer text',
    floatingBadge: true,
    heightType: 'any',
    isFullWidth: true,
    roundedCorners: false,
    contentCollection: {
      items: [
        {
          __typename: 'HeroBannerColumn',
          backgroundColor: 'neutralBlack',
          id: { id: 'column-test-id' },
          form: {
            __typename: 'Form',
            sys: { id: '1' },
            contentful_id: '1'
          }
        }
      ]
    },
    ntExperiencesCollection: {
      items: []
    }
  })

export const gatsbyHeroBannerMockData: GatsbyHeroBannerSchema = {
  __typename: 'ContentfulHeroBanner',
  contentful_id: 'some-id',
  id: 'some-id'
}

export const heroBannerMock = (): HeroBannerSchema => heroBannerMockData

export const refactoredHeroBannerMock = (): RefactoredHeroBannerSchema =>
  refactoredHeroBannerMockData

export const minimalHeroColumnMock = (): MinimalHeroBannerColumnSchema =>
  minimalHeroColumnMockData

export const quoteWizardMock = (): GuidedSystemBuilderSchema => {
  return {
    __typename: 'QuoteWizard',
    sys: { id: '6wRdF53o74w2MhOhBIkzip' },
    id: '6wRdF53o74w2MhOhBIkzip',
    jebbitUrl: 'https://www.example.com',
    type: 'nav',
    ntExperiencesCollection: {
      items: []
    }
  }
}

export const heroBannerMockWithContent = (): HeroBannerSchema => {
  return {
    id: '123',
    __typename: 'HeroBanner',
    disclaimerText: 'Disclaimer text',
    floatingBadge: true,
    heightType: 'any',
    isFullWidth: true,
    roundedCorners: false,
    contentCollection: {
      items: [heroColumnMock()]
    },
    hasPromoSticker: false,
    ntExperiencesCollection: {
      items: []
    }
  }
}

export const buttonMock = {
  id: '6vRiig0cXsyYJC5KtSLt4J',
  contentful_id: '6vRiig0cXsyYJC5KtSLt4J',
  __typename: 'Button',
  buttonText: 'Build a system',
  url: '/build-my-system',
  type: 'Primary'
}
