import * as E from 'fp-ts/lib/Either'

function isSyntaxError<T>(err: SyntaxError | T): err is SyntaxError {
  return err && typeof err === 'object' && Object.hasOwn(err, 'stack')
}

/**
 * Parses an object like string into a JS object.
 *
 * @example
 *
 * stringToObject('{ "foo": "bar" }') // => right { foo: "bar" }
 * stringToObject('foo') // => left SyntaxError
 */
function stringToObject(str: string): E.Either<SyntaxError, unknown> {
  return E.tryCatch<Error, unknown>(
    () => JSON.parse(str),
    e => (isSyntaxError(e) ? e : SyntaxError(`${e}`))
  )
}

export default stringToObject
