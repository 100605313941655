import { genericHomeLandingTemplateSchema } from '@ecomm/home-landing-data'

export const useHomeLandingPageFragment = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const homeLandingFragment = query.contentfulGenericLandingPage
  // @ts-expect-error - pulling out the slug so it can be included in the zod path for easier debugging
  const slug: string = homeLandingFragment?.slug || 'unknown slug'
  const homeLandingPage = genericHomeLandingTemplateSchema.parse(
    homeLandingFragment,
    {
      path: [
        slug,
        'GenericHomeLandingPage',
        'useHomeLagePageFragment',
        'genericHomeLandingTemplateSchema'
      ]
    }
  )

  //defaults showPopupQuoteWizard to true if no value is provided in CTFL
  const homeLandingPageWithPopupQW =
    homeLandingPage.showPopupQuoteWizard === null
      ? { ...homeLandingPage, showPopupQuoteWizard: true }
      : homeLandingPage

  return homeLandingPageWithPopupQW
}
