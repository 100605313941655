import { ErrorBoundary } from '@ecomm/error-handling'
import { useContentful } from '@ecomm/shared-apollo'
import { Suspense } from 'react'

import { GridSection } from '.'
import { GRID_QUERY } from './query'
import { gridSchema } from './schema'

const useGridQuery = (id: string) => {
  const { data } = useContentful(GRID_QUERY, { id })
  return gridSchema.parse(data['grid'], {
    path: ['GatsbyGridSection', 'useGridQuery']
  })
}

type Props = {
  readonly id: string
}

function Content({ id }: Props) {
  const grid = useGridQuery(id)
  return <GridSection {...grid} />
}

export function GatsbyGridSection({ id }: Props) {
  return (
    <Suspense>
      <ErrorBoundary>
        <Content id={id} />
      </ErrorBoundary>
    </Suspense>
  )
}
