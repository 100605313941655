import {
  useNinetailedImpactedEvent,
  type FeatureFlag
} from '@ecomm/shared-ninetailed'

export const useOdmonCameraImpactedEvent = (
  experienceData: FeatureFlag,
  hasOutdoorCamera: boolean
) =>
  useNinetailedImpactedEvent({
    baseline: experienceData.baseline,
    experiences: experienceData.experiences,
    name: 'nt_impacted_event',
    event: {
      hasOutdoorCamera
    },
    shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
  })
