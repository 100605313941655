import {
  trackClickContinueToPaymentButtonEvent,
  trackLoggedIn
} from '@ecomm/cdp-tracking'
import {
  useBuildPrefillData,
  usePrefilledFormData
} from '@ecomm/checkout/shipping-hooks'
import {
  type CheckoutFormValuesType,
  type ShippingFormFragment,
  getCheckoutFormSchema
} from '@ecomm/checkout/shipping-schema'
import {
  combineUserDataAndAddress,
  formatFirstAddress,
  getCheckoutBody,
  getCheckoutPaymentUrl,
  getFirstSuggestionAddress,
  getFormattedUserAddress,
  getPersonalUserDataFromCheckout,
  handleCaptureLead,
  isCorrectShippingAddressLocale,
  isStrategicReferralPartner,
  setDeviceId,
  shouldRenderShippingAddressModal,
  shouldSilentlyUpdate
} from '@ecomm/checkout/utils'
import { getCartDiscountCode } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import {
  COOKIE_EMAIL,
  getCookie,
  getLeadData,
  getValueFromPartnerCookie
} from '@ecomm/shared-cookies'
import {
  type LoginStatus,
  ReferrerUrlContext,
  useLeadGenCaptureV2,
  setBrazeAttributeGoogleClientId,
  useGoogleAnalytics,
  useOptimizelyTrackSiteEvents,
  useTrackingContinuePaymentClick,
  useTrackingEpsilonAbacusOptIn,
  useTrackingLoggedIn,
  useTrackingOpenShippingModalValidation,
  useTrackingOrderSubmissionFailed,
  useTrackingSubmitLead
} from '@ecomm/tracking'
import { set as sessionStorageSet } from '@ecomm/utils'
import { type MaybeT, path } from '@simplisafe/ewok'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import {
  type CheckoutRequestResponse,
  handleCheckoutSubmit
} from '@simplisafe/ss-ecomm-data/checkout/checkout'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import type { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { Form, Formik } from 'formik'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { navigate } from 'gatsby'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { CustomerService } from '../CustomerService'
import { Email } from '../Email'
import { ShippingAddress } from '../ShippingAddress'
import { ShippingOptions } from '../ShippingOptions'
import { ShippingModalValidation } from '../ShippingValidationModal'
import { Submission } from '../Submission'

type Props = {
  readonly data: ShippingFormFragment
}

export function ShippingForm({ data }: Props) {
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const locale = useLocale()
  const [clientId] = useGoogleAnalytics()
  const cartIsLoading = useSelector(selectCartLoading)
  const { preFillShippingAddress } = usePrefilledFormData()
  const trackGTMLoggedIn = useTrackingLoggedIn()
  const trackSubmitLead = useTrackingSubmitLead()
  const trackSubmissionFailed = useTrackingOrderSubmissionFailed()
  const trackEpsilonAbacusOptIn = useTrackingEpsilonAbacusOptIn()
  const trackOpenShippingModalValidation =
    useTrackingOpenShippingModalValidation()
  const trackContinuePaymentClick = useTrackingContinuePaymentClick()

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const leadDataCookie = getLeadData()
  setDeviceId()
  const channel = useContext(ReferrerUrlContext).channel

  const initialCoupon = cart
    .map(_cart => getCartDiscountCode(_cart))
    .toMaybe()
    .getOrElse('')

  const initialFormValues = useBuildPrefillData(
    locale,
    getCookie(COOKIE_EMAIL) ||
      (!!leadDataCookie ? leadDataCookie['email'] : ''),
    initialCoupon
  )

  const [submitted, setSubmitted] = useState(false)
  const [currentCoupon, setCurrentCouponValue] = useState(initialCoupon)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [selectedShippingMethod, setSelectedShippingMethod] = useState('')
  const [userAddress, setUserAddress] = useState<
    Partial<CheckoutFormValuesType>
  >({})
  const [
    shippingAddressModalValidationOpen,
    setShippingAddressModalValidationOpen
  ] = useState(false)
  const [addressValidationResponseSchema, setAddressValidationResponseSchema] =
    useState({
      validatedAddress: {},
      userCheckoutData: {},
      shippingMethod: '',
      metadata: {},
      config: {}
    })

  const leadGenCaptureV2 = useLeadGenCaptureV2()
  const trackCheckoutLoggedIn = (status: LoginStatus) => {
    trackGTMLoggedIn(status, 'CHECKOUT')
    trackLoggedIn({ status, location: 'CHECKOUT' })
  }

  /**
   * Send different log statuses based on error message, fall back to API_FAILURE
   */
  const fetchUserCheckoutDataFailure = (err: Error) =>
    err.message === 'Popup closed'
      ? trackCheckoutLoggedIn('INVALID_OR_CLOSED')
      : err.message === 'Timeout'
        ? trackCheckoutLoggedIn('TIMEOUT')
        : trackCheckoutLoggedIn('API_FAILURE')

  /**
   * Downstream caller expects (MaybeT<UserCheckoutData>) => void but everything upstream is
   * fp-ts/Either. Still take a Maybe but immediately convert to an Either instead, and we can
   * refactor downstream when we get to it.
   */
  const fetchUserCheckoutDataSuccess = (
    userCheckoutData: MaybeT<UserCheckoutData>
  ) => {
    pipe(
      // begin with an Either of NO_DATA or the API data returned
      E.fromNullable<LoginStatus>('NO_DATA')(userCheckoutData.orNull()),
      // grab & format and return an Either of PROCESSING_ERROR or a formatted address
      E.chain(checkoutData => {
        const firstAddress = formatFirstAddress(checkoutData)
        return E.fromOption<LoginStatus>(() => 'PROCESSING_ERROR')(firstAddress)
      }),
      // if Left, track the error state as loginStatus
      // if Right, set it locally and track it as SUCCESS
      E.match(
        loginStatus => trackCheckoutLoggedIn(loginStatus),
        formattedAddress => {
          setUserAddress(formattedAddress)
          trackCheckoutLoggedIn('SUCCESS')
        }
      )
    )
    setLoginSuccess(true)
    setIsFormLoading(false)
  }

  const handleSubmitSuccess = (
    checkoutResponse: MaybeT<CheckoutRequestResponse>,
    isSameAddress: boolean
  ) => {
    setSubmitted(false)

    checkoutResponse.cata(
      () => {
        logError(Error('something went wrong parsing user address'))
      },
      ({ addressValidation, cart }) => {
        const handleRedirect = () => {
          setIsFormLoading(false)
          navigate(getCheckoutPaymentUrl())
        }

        // Set the validated address behind the scenes (without opening the modal) if it found an address suggestion with invalid corrections
        const handleSetValidatedAddressSilently = () => {
          const addressData = combineUserDataAndAddress(
            getPersonalUserDataFromCheckout(cart.shippingAddress)
          )(getFirstSuggestionAddress(addressValidation) || {})(locale)

          return shouldSilentlyUpdate(addressValidation)(locale)
            ? dispatch(
                handleCheckoutSubmit(
                  getCheckoutBody({
                    billingAddress: addressData,
                    shippingAddress: addressData,
                    setBillingAddress: isSameAddress,
                    shippingMethod: cart.shippingInfo?.shippingMethod?.id || '',
                    shouldValidate: false,
                    lead: path(['custom', 'fields', 'leadSource'], cart) || '',
                    leadOther:
                      path(['custom', 'fields', 'leadOther'], cart) || ''
                  })
                )(handleSubmitError)(handleRedirect)
              )
            : handleRedirect()
        }

        const handleOpenShippingAddressModal = () => {
          setAddressValidationResponseSchema({
            userCheckoutData: getPersonalUserDataFromCheckout(
              cart.shippingAddress
            ),
            validatedAddress:
              getFirstSuggestionAddress(addressValidation) || {},
            shippingMethod: cart.shippingInfo?.shippingMethod?.id || '',
            metadata: {
              lead: path(['custom', 'fields', 'leadSource'], cart) || '',
              leadOther: path(['custom', 'fields', 'leadOther'], cart) || ''
            },
            config: { setBillingAddress: isSameAddress }
          })
          trackOpenShippingModalValidation()
          setIsFormLoading(false)
          setShippingAddressModalValidationOpen(true)
        }

        shouldRenderShippingAddressModal(addressValidation)(locale)
          ? handleOpenShippingAddressModal()
          : handleSetValidatedAddressSilently()
      }
    )
  }

  const handleSubmitError = (err: Error) => {
    logError(Error(`something went wrong with the checkout: ${err.message}`))
    trackSubmissionFailed(err.message)
    window && window.scrollTo(0, 0)
    setIsFormLoading(false)
  }

  const setCheckboxesToSessionStorage = (values: CheckoutFormValuesType) => {
    sessionStorageSet('offerAndTip', JSON.stringify(values.offerAndTip))
    sessionStorageSet('sameAddress', JSON.stringify(values.sameAddress))
  }

  const handleOnSubmit = (values: CheckoutFormValuesType) => {
    setIsFormLoading(true)
    trackSubmitButton(values)

    trackEpsilonAbacusOptIn(values.epsilonAbacusOptIn)
    handleCaptureLead(
      currentCoupon,
      locale,
      optimizelyTrackSiteEvents,
      values,
      loginSuccess,
      channel
    )(cart, trackSubmitLead)
    setCheckboxesToSessionStorage(values)

    leadGenCaptureV2({
      source: 'checkout',
      siteMetadata: { sourceType: 'cart', promoOffer: currentCoupon },
      sourceDetail: 'exit_intent',
      email: values.email
    })

    // set google client id for UK as custom braze attribute ECP-12023
    locale === 'en-GB' && clientId && setBrazeAttributeGoogleClientId(clientId)

    const userIsStrategicReferral = isStrategicReferralPartner()

    // @ts-expect-error
    const checkoutValues = getFormattedUserAddress(values, locale)
    const checkoutBody = getCheckoutBody({
      billingAddress: checkoutValues,
      setBillingAddress: values.sameAddress,
      shippingAddress: checkoutValues,
      shippingMethod: values.shippingOption,
      lead: userIsStrategicReferral
        ? 'Partner Strategic Referral'
        : values.foundInfoThrough,
      leadOther:
        (userIsStrategicReferral
          ? getValueFromPartnerCookie('partnerName')
          : values.additionalFoundInfoThrough) || '',
      shouldValidate: isCorrectShippingAddressLocale(locale)
    })

    dispatch(
      handleCheckoutSubmit(checkoutBody)(handleSubmitError)(checkoutResponse =>
        handleSubmitSuccess(checkoutResponse, values.sameAddress)
      )
    )
  }

  const trackSubmitButton = (values: CheckoutFormValuesType) => {
    const attribution =
      values.additionalFoundInfoThrough !== '' ? 'submit' : 'reject'
    const checkboxShipping = values.sameAddress ? 'checked' : 'unchecked'
    const checkboxLead = values.offerAndTip ? 'checked' : 'unchecked'
    const shipping = selectedShippingMethod

    trackContinuePaymentClick(
      attribution,
      checkboxLead,
      checkboxShipping,
      shipping
    )
    trackClickContinueToPaymentButtonEvent({
      attribution,
      checkboxLead,
      checkboxShipping,
      shipping
    })
  }

  return (
    <div data-component="CheckoutFormContainer">
      <Formik
        enableReinitialize={!!preFillShippingAddress?.firstName}
        // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
        initialValues={initialFormValues}
        onSubmit={handleOnSubmit}
        validationSchema={toFormikValidationSchema(
          getCheckoutFormSchema(locale)
        )}
      >
        {({ values, errors }) => (
          <Form data-component="CheckoutForm">
            <Email
              fetchUserCheckoutDataFailure={fetchUserCheckoutDataFailure}
              fetchUserCheckoutDataSuccess={fetchUserCheckoutDataSuccess}
              locale={locale}
              loginSuccess={loginSuccess}
              setIsFormLoading={setIsFormLoading}
            />
            <ShippingAddress
              locale={locale}
              loginSuccess={loginSuccess}
              selectCountry={data.selectCountry}
              setCurrentCouponValue={setCurrentCouponValue}
              setLoginSuccess={setLoginSuccess}
              setUserAddress={setUserAddress}
              userAddress={userAddress}
            />
            <ShippingOptions
              isFormLoading={isFormLoading}
              setIsFormLoading={setIsFormLoading}
              setSelectedShippingMethod={setSelectedShippingMethod}
              userAddress={values}
            />
            {!isStrategicReferralPartner() && (
              <CustomerService
                foundInfoThrough={data.foundInfoThrough}
                locale={locale}
              />
            )}
            <Submission
              disableSubmit={cartIsLoading || isFormLoading}
              onClick={() => setSubmitted(true)}
              showErrorMessage={!!Object.keys(errors).length && submitted}
            />
          </Form>
        )}
      </Formik>
      {shippingAddressModalValidationOpen ? (
        <ShippingModalValidation
          modal={{
            data: addressValidationResponseSchema,
            redirectUrl: getCheckoutPaymentUrl()
          }}
          open={shippingAddressModalValidationOpen}
          setOpen={setShippingAddressModalValidationOpen}
        />
      ) : null}
    </div>
  )
}
