import { getRudderstack } from '@ecomm/shared-window'

export function trackClickBuildASystemCta() {
  getRudderstack(r => r.track('cta_bms'))
}

/**
 * Track click monitoring features link from camera banner on HP/LP.
 */
export function trackClickStopCrimeMonitoring() {
  getRudderstack(r => r.track('click_stop_crime_monitoring'))
}

/**
 * Track click indoor camera link from camera banner on HP/LP.
 */
export function trackClickStopCrimeCamera() {
  getRudderstack(r => r.track('click_stop_crime_camera'))
}

/**
 * Track click app carousel tab on HP/LP/Monitoring Features page.
 */
export function trackAppCarousel(carouselName: string) {
  getRudderstack(r => r.track('click_carousel_component', { carouselName }))
}

export type ModalLocation =
  | 'battery-backup'
  | 'fast-protect'
  | 'home-integration'
  | 'home-protection'
  | 'police-response'

/**
 * Track click comparison chart info modal button.
 */
export function trackClickComparisonChartModalEvent(
  modalLocation: ModalLocation
) {
  getRudderstack(r =>
    r.track('click_comparison_chart_modal', { modalLocation })
  )
}

/**
 * Track click phone call button from expert section on HP/LP/Monitoring Features page.
 */
export function trackCallExpert() {
  getRudderstack(r => r.track('click_call_expert_component'))
}

export type SubmitOrderClickPlacement = 'above' | 'below'

/**
 * track click submit order button
 */
export function trackSubmitOrderClick(placement: SubmitOrderClickPlacement) {
  getRudderstack(r => r.track('submit_order_click', { placement }))
}

/**
 * track click continue to checkout button
 */
export function trackContinueToCheckout() {
  getRudderstack(r => r.track('continue_to_checkout_button'))
}

export type Attribution = 'reject' | 'submit'
export type CheckboxLead = 'checked' | 'unchecked'
export type CheckboxShipping = 'checked' | 'unchecked'

export type ContinueToPaymentButtonArgs = {
  readonly attribution: Attribution
  readonly checkboxLead: CheckboxLead
  readonly checkboxShipping: CheckboxShipping
  readonly shipping: string
}

/**
 * Track click continue to payment button.
 */
export function trackClickContinueToPaymentButtonEvent(
  args: ContinueToPaymentButtonArgs
) {
  getRudderstack(r =>
    r.track('continue_to_payment_button', {
      shipping: args.shipping,
      attribution: args.attribution,
      lead_checkbox: args.checkboxLead,
      shipping_checkbox: args.checkboxShipping
    })
  )
}

/**
 * Track click edit shipping button
 */

export function trackEditShippingButtonClick() {
  getRudderstack(r => {
    r.track('edit_shipping_button')
  })
}
/**
 * Track click continue to payment button.
 */
export function trackCheckoutClickToLoginEvent() {
  getRudderstack(r => r.track('checkout_click_to_login'))
}

// all properties are optional
export type ContinueToPaymentButtonErrorArgs = {
  readonly city?: string
  readonly email?: string
  readonly firstName?: string
  readonly lastName?: string
  readonly phone?: string
  readonly postalCode?: string
  readonly shippingOption?: string
  readonly state?: string
  readonly streetName?: string
}
/**
 * Track click continue to payment button error.
 */
export function trackContinueToPaymentClickError(
  fields: ContinueToPaymentButtonErrorArgs
) {
  getRudderstack(r =>
    r.track('continue_to_payment_click_error', {
      city: fields.city,
      email: fields.email,
      firstName: fields.firstName,
      lastName: fields.lastName,
      phone: fields.phone,
      postalCode: fields.postalCode,
      shippingOption: fields.shippingOption,
      state: fields.state,
      streetName: fields.streetName
    })
  )
}

/**
 * Track click on FAQ question expand button.
 */
export function trackFAQExpand(faq_position: number) {
  getRudderstack(r => r.track('faq_expand', { faq_position }))
}

export function trackClickCheckoutLogo() {
  getRudderstack(r => r.track('checkout_logo_click'))
}
