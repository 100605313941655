import { Accessory, Fields, PartnerRewardValue } from './ProductContext'
import { OLYMPUS_SKU, SCOUT_SKU, SHIELD_SKU } from '@ecomm/data-constants'
import { ContentfulRichText } from '@ecomm/shared-components'
import {
  getPartnerGroup,
  getValueFromPartnerCookie
} from '@ecomm/shared-cookies'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import { PartnerMembershipCaptureBody } from '@simplisafe/ss-ecomm-data/partners/submission'
import { match } from 'ts-pattern'

export type ParsedAccessory = Omit<Accessory, 'description' | 'sku'> & {
  readonly description: JSX.Element
  readonly sku: Accessory['sku']
}

export const ACCESSORIES_ORDER = [
  'SSCAM-BAT1',
  'CM009-02DWW',
  'CMCBL1',
  'CM001',
  'CM007-01DUS'
]

/**
 * Sorts an array of accessories based on the order specified in ACCESSORIES_ORDER.
 *
 * @param {readonly ParsedAccessory[]} accessories - The accessories to sort.
 * @returns {readonly ParsedAccessory[]} The sorted array of accessories.
 */
export function sortAccessories(
  accessories: readonly ParsedAccessory[]
): readonly ParsedAccessory[] {
  function sort(
    a: { readonly sku: string },
    b: { readonly sku: string },
    ACCESSORIES_ORDER: readonly string[]
  ) {
    return ACCESSORIES_ORDER.indexOf(a.sku) > ACCESSORIES_ORDER.indexOf(b.sku)
      ? 1
      : -1
  }

  return accessories.map(a => a).sort((a, b) => sort(a, b, ACCESSORIES_ORDER))
}

export const AccessoryMapping: Record<string, readonly string[]> = {
  [SHIELD_SKU]: ['SSCAM-BAT1', 'CM009-02DWW', 'CMCBL1', 'CM001'],
  [OLYMPUS_SKU]: ['SSCAM-BAT1', 'CM009-02DWW', 'CMCBL1', 'CM001'],
  [SCOUT_SKU]: ['CM007-01DUS']
}

/**
 * Returns an array of accessory SKUs associated with a given array of line items.
 *
 * @param {readonly LineItem[]} lineItems - The line items to get accessory SKUs for.
 * @returns {readonly string[]} An array of accessory SKUs.
 */
export function getAccessoriesSkusForLineItems(
  lineItems: readonly LineItem[]
): readonly string[] {
  return lineItems.flatMap(({ sku, packageItems }) => {
    const skus: readonly string[] = [
      sku,
      ...packageItems.map(({ sku }) => sku)
    ].filter((item): item is string => typeof item === 'string')

    return skus.flatMap(sku => AccessoryMapping[sku] ?? [])
  })
}

/**
 * Returns an array of accessories that match the given SKUs.
 *
 * @param {readonly string[]} skus - The SKUs to match.
 * @param {readonly Accessory[]} accessories - The accessories to search.
 * @returns {readonly Accessory[]} An array of matching accessories.
 */
export function getAccessoriesFromSkus(
  skus: readonly string[],
  accessories: readonly Accessory[]
): readonly Accessory[] {
  return Array.from(new Set(skus)).flatMap(sku =>
    accessories.filter(({ sku: _sku }) => _sku === sku)
  )
}

/**
 * Parses an array of accessories and returns a new array with the description field replaced with a JSX element (rendered by ContentfulRichText).
 *
 * @param {readonly Accessory[]} accessories - The accessories to parse.
 * @returns {readonly ParsedAccessory[]} An array of parsed accessories.
 */
export function parseAccessories(
  accessories: readonly Accessory[]
): readonly ParsedAccessory[] {
  return accessories.map(({ description, ...rest }) => ({
    description: <ContentfulRichText raw={description.raw} />,
    ...rest
  }))
}

export function moveGiftItemsToBottom(lineItems: readonly LineItem[]) {
  return lineItems
    .slice()
    .sort((a, b) =>
      !a.isGift && b.isGift ? -1 : a.isGift && !b.isGift ? 1 : 0
    )
}

export type LineItemWithTotalQuantityInCart = LineItem & {
  readonly totalQuantityInCart: number
}

export function addTotalQuantityInCartToLineItems(
  lineItems: readonly LineItem[]
): readonly LineItemWithTotalQuantityInCart[] {
  return lineItems.map(lineItem => {
    const totalQuantityInCart = lineItems.reduce(
      (acc, curr) =>
        acc +
        (curr.sku === lineItem.sku
          ? curr.quantity
          : curr.packageItems.reduce(
              (acc, curr) =>
                acc + (curr.sku === lineItem.sku ? curr.quantity : 0),
              0
            )),
      0
    )

    return {
      ...lineItem,
      totalQuantityInCart
    }
  })
}

export const formatFormData = (
  data: {
    readonly memberNumber: string
    readonly lastName: string
  },
  rewardData: PartnerRewardValue
): PartnerMembershipCaptureBody => {
  const { memberNumber, lastName } = data

  const partnerName = getValueFromPartnerCookie('partnerName') || ''
  const partnerGroup = getPartnerGroup() || ''

  const rewardValue = match(partnerGroup)
    .with('airlines', () => {
      const { airlines } = rewardData.partnerGroups
      return match(partnerName)
        .with('united', () => airlines.united)
        .otherwise(() => airlines.default)
    })
    .otherwise(() => rewardData.default)

  return {
    memberNumber: memberNumber,
    membershipData: {
      lastName: lastName,
      memberNumber: memberNumber,
      rewardValue
    },
    partnerName
  }
}

export function findFieldLabel(fieldName: string, fields: readonly Fields[]) {
  return fields.find(f => f.name === fieldName)?.label || 'This'
}
