import { useMicroCopy } from '@ecomm/micro-copy'
import { Link } from '@ecomm/framework'
import React from 'react'

import { WarningIcon } from '../icons/WarningIcon'

export function CartSecuritySystemWarning() {
  const microCopy = useMicroCopy()

  return (
    <div
      className="mb-8 rounded-[10px] bg-[#F2F2F2] p-0 md:p-1"
      data-component="NewestSecurityWarning"
    >
      <div className="flex items-start justify-around gap-8 md:items-center">
        <div className="ml-10 mt-6 md:mt-0">
          <WarningIcon />
        </div>
        <p
          className="text=[#0F2648] mr-10 text-base"
          data-component="NewestSecurityWarningText"
        >
          {microCopy['cart-newest-security-system-warning']}

          <Link
            className="px-1 text-complementary-blue-100"
            to="/build-my-system"
          >
            {microCopy['build-your-system']}
          </Link>
          {microCopy['first']}
        </p>
      </div>
    </div>
  )
}
