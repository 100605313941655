import { getRudderstack } from '@ecomm/shared-window'

/**
 * A function to track tooltip click event in buil-my-system page to RudderStack
 */
export function trackBmsTooltip(sensor: string) {
  getRudderstack(r => r.track('bms_tooltip', { sensor }))
}
/*
 * A function to track shop now click events to RudderStack
 */
export function trackShopNow() {
  getRudderstack(r => r.track('shop_now'))
}

export function trackShopSeeDetails(see_details_product: string) {
  getRudderstack(r => r.track('shop_see_details', { see_details_product }))
}
