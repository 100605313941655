import { useLocale } from '@ecomm/data-hooks'
import { type BusinessCapturePayload, businessCapture } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { atom, useAtom } from 'jotai'
import { useState } from 'react'

import { Link } from '@ecomm/framework'
import { useLeadGenCaptureV2 } from '@ecomm/tracking'
import { FormField } from '../../../lib/FormField'
import { FormTextInput } from '../../../lib/FormTextInput'
import {
  type FormQuerySchema,
  type OnSubmit,
  type SubmissionResultProps,
  validationSchema
} from './PartnerWithUsFormSchema'

export const SUBMISSION_SUCCESS_MESSAGE =
  'Someone from our team will contact you soon.'
export const SUBMISSION_ERROR_MESSAGE =
  'There was an error submitting, please try again.'

const messageByResultObject = {
  success: SUBMISSION_SUCCESS_MESSAGE,
  error: SUBMISSION_ERROR_MESSAGE
}

const partnerWithUsFormAtom = atom(false)
const PARTNER_WITH_US_LEAD_SOURCE = 'Partner with us form'

export type ParterWithUsForm = {
  readonly formCollection: FormQuerySchema['formCollection']
  readonly button: FormQuerySchema['button']
  readonly location: string // for aria to avoid duplicate id names
}

export function PartnerWithUsForm({
  formCollection,
  button,
  location
}: ParterWithUsForm) {
  const [submissionResult, setSubmissionResult] =
    useState<SubmissionResultProps>({ result: 'pending' })
  const [isSubmitted, setIsSubmitted] = useAtom(partnerWithUsFormAtom)
  const isUS = useLocale() === 'en-US'
  const captureLeadV2 = useLeadGenCaptureV2()
  const onSubmit: OnSubmit = async formData => {
    const { email, companyName, firstName, lastName } = formData
    const submitFormValues: BusinessCapturePayload = {
      companyName,
      email,
      firstName,
      lastName,
      phone: '', // phone is not in the form but in the endpoint payload
      businessType: 'Partners',
      numLocations: '', // also not in the form but in the endpoint payload
      source: PARTNER_WITH_US_LEAD_SOURCE
    }

    pipe(
      await businessCapture(submitFormValues)(),
      E.match(
        (e: Error) => {
          setSubmissionResult({
            result: 'error'
          })
          logError(e)
        },
        _ => {
          setSubmissionResult({
            result: 'success'
          })
          setIsSubmitted(true)
        }
      )
    )

    captureLeadV2({
      company: companyName,
      email,
      firstName,
      lastName,
      source: PARTNER_WITH_US_LEAD_SOURCE
    })
  }

  return isUS ? (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        companyName: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form className="flex w-full flex-col" noValidate>
          {!isSubmitted ? (
            <>
              {formCollection.items.map((field, i) => (
                <FormField
                  className="mb-4 w-full md:mb-6"
                  htmlFor={`${location}-${field.name}`}
                  key={`form-input-${i}`}
                  label={field.label}
                  name={field.name}
                >
                  <FormTextInput
                    className="w-full"
                    id={`${location}-${field.name}`}
                    name={field.name}
                    placeholder={field.placeholder}
                    type={field.type === 'Email' ? 'email' : 'text'}
                  />
                </FormField>
              ))}
              <button
                className={classNames(
                  'btn mt-1 block h-14 w-fit min-w-[190px] px-8 md:mt-8 lg:mt-1',
                  {
                    'bg-neutral-medium-100 border-none text-white': !isValid,
                    'btn-solid-primary cursor-pointer': isValid
                  }
                )}
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                {button.text}
              </button>

              {submissionResult.result !== 'pending' ? (
                <p
                  className={classNames({
                    'text-sale mt-4': submissionResult.result === 'error'
                  })}
                  data-component="result-label"
                >
                  {messageByResultObject[submissionResult.result]}
                </p>
              ) : null}
            </>
          ) : (
            <>
              <h3 className="text-[32px] leading-[1.13] mb-2.5 md:text-4xl font-medium">
                Thank you
              </h3>
              <p>{SUBMISSION_SUCCESS_MESSAGE}</p>
              <Link
                className="btn btn-solid-primary block h-14 w-fit min-w-[190px] cursor-pointer px-8"
                to="/home-security-shop"
              >
                Shop Now
              </Link>
            </>
          )}
        </Form>
      )}
    </Formik>
  ) : null
}
