import { gql } from '@apollo/client/index.js'

export const HERO_BANNER_COLUMN_QUERY = gql`
  fragment ColumnQWExperienceFragmentApollo20 on NtExperience {
      id: sys { id }
      name: ntName
      type: ntType
      audience: ntAudience {
        id: sys { id }
        name: ntName
      }
      config: ntConfig
      variants: ntVariantsCollection(limit: 20) {
        items {
          ... on QuoteWizard {
            ...ColumnQWBaseFragment
          }
        }
      }
    }

    fragment ColumnQWBaseFragment on QuoteWizard {
      __typename
      id: sys {id}
      sys {id} # This is needed for QWs embedded in a rich text
      jebbitUrl
      type
      buttonText
    }

    fragment homeLandingFormFragment on Form {
      __typename
      contentful_id: sys { id }
      sys {
        id
      }
      formCollection(limit: 20) {
        items {
          ... on Input {
            label: title
            name: inputName
            type
            placeholder
          }
        }
      }
      button {
        text
      }
    }

  query Hero_Banner_Column($id: String!, $preview: Boolean!, $locale: String!) {
    heroBannerColumn(id: $id, locale: $locale, preview: $preview) {
      backgroundColor
      description {
        json
        links {
          entries {
            block {
              ... on QuoteWizard {
                ...ColumnQWBaseFragment
                ntExperiencesCollection(limit: 20) {
                  items {
                    ...ColumnQWExperienceFragmentApollo20
                  }
                }
              }
              ... on YoutubeVideo {
                sys {
                  id
                }
                __typename
                title
                link
                text
              }
            }
          }
        }
      }
      descriptionMobile {
        json
      }
      image {
        url
        description
        originalWidth: width
        originalHeight: height
      }
      imageTablet {
        url
        description
        originalWidth: width
        originalHeight: height
      }
      imageMobile {
        url
        description
        originalWidth: width
        originalHeight: height
      }
      textColor
      textAlignment
      contentVerticalAlignment
      linkColor
      hasPromoSticker
      form {
        ... on Form {
          ...homeLandingFormFragment
        }
      }
    }
  }
`
