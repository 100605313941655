import type {
  GatsbyImageSchema,
  GuidedSystemBuilderSchema
} from '@ecomm/shared-components'

export const QuizBannerImageMock: GatsbyImageSchema = {
  gatsbyImageData: {
    height: 1,
    images: {
      fallback: {
        src: 'test-img.png'
      },
      sources: [
        {
          srcSet: 'test-img.png 436w',
          type: 'image/webp'
        }
      ]
    },
    layout: 'fullWidth',
    width: 1
  },
  title: 'New Quiz Image'
}

const quoteWizard: GuidedSystemBuilderSchema = {
  __typename: 'ContentfulQuoteWizard',
  contentful_id: '12345',
  jebbitUrl: 'https://www.example.com',
  type: 'floating',
  id: '6wRdF53o74w2MhOhBIkzip',
  nt_experiences: []
}
export const staticQueryQuoteWizardMock = {
  image: {
    quoteWizardAsset: {
      originalWidth: 1326,
      originalHeight: 850,
      title: 'New Quiz Image',
      url: 'test-image.png',
      size: 302839,
      description: 'test-alt-text'
    }
  },
  quoteWizard: quoteWizard
}
