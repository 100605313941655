import { ErrorBoundary } from '@ecomm/error-handling'
import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { BelowTheFold } from '@ecomm/lander'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  CountryRedirectModal,
  toPromoBannerStyleValue,
  v2PopupQuoteWizard as PopupQuoteWizard
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import classNames from 'classnames'
import { type PageProps, graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import ContentCollection from '../../components/ContentCollection'
import HomeLandingHeroBanner from '../../components/HomeLandingHeroBanner'
import LayoutReference from '../../components/LayoutReference'
import StickyNavbar from '../../components/StickyNavbar'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHomepageQuery } from '../../hooks/useHomepageQuery'
import { getMappedComponent } from '../../utils/getMappedComponent'
import { useHomeLandingPageFragment } from './useHomeLandingPageFragment'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function GenericHomeLandingTemplateM<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const {
    layout,
    showPopupQuoteWizard = true,
    breadcrumbTitle,
    slug
  } = useHomeLandingPageFragment(data)
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout

  const { metaTitle, metaDescription, metaKeywords } = seoDetails
  const { isNofollow, isNoindex, canonicalLink } = seoDetails
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'

  /**
   * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
   *
   * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
   *
   * ECP-12400
   *
   * Please reach out to Josh and the platform team if you need to make any changes.
   *
   */
  const anchorSections = (components || [])
    .filter(component => component.__typename === 'ContentfulAnchorSection')
    .map(component => component.name)

  const hasAnchorSections = anchorSections.length > 0
  const headerData = useHeaderRedesignQuery()
  const pageContent = useHomepageQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const heroOnlyContent =
    components?.filter(
      component => component.__typename === 'ContentfulHeroBanner'
    ) || []
  const heroBannerIsNotSet = heroOnlyContent.length === 0

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      {hasAnchorSections ? (
        <StickyNavbar anchorSections={anchorSections ?? []} />
      ) : null}
      <main
        className="prose md:prose-md lg:prose-lg relative z-0 w-full whitespace-pre-line"
        data-component="components-wrapper"
        data-testid="landing-page-content"
        id="content"
      >
        {hasMounted ? (
          <ErrorBoundary>
            {components?.map((component, index) =>
              match(component)
                .with({ __typename: 'ContentfulHeroBanner' }, data => (
                  <HomeLandingHeroBanner
                    key={'home-landing-hero-banner'}
                    {...data}
                  />
                ))
                .with({ __typename: 'ContentfulContentCollection' }, data => (
                  <div
                    className="max-w-8xl mx-auto mb-16 flex flex-col gap-6 px-4 pt-8 md:gap-8 md:px-8 lg:gap-12"
                    key={`ContentfulContentCollection_${index}`}
                  >
                    <ContentCollection
                      key={'home-landing-content-collection'}
                      {...data}
                    />
                  </div>
                ))
                .with({ __typename: 'ContentfulLayoutReference' }, data => (
                  <div
                    className={classNames(
                      'max-w-8xl mx-auto flex flex-col gap-6 md:gap-8 lg:gap-12 lg:px-8',
                      {
                        /*
                        We have some pages that have LayoutReferences set as the only component or
                        Hero Component as set as the Layout Reference for custom styling. For those components,
                        we don't want to apply some of the default class names for page sections */
                        'mb-6 md:px-8 lg:mb-12': !heroBannerIsNotSet,
                        'lg:pt-8': heroBannerIsNotSet
                      }
                    )}
                    key={`ContentfulLayoutReference_${index}`}
                  >
                    <LayoutReference
                      key={'home-landing-layout-reference'}
                      {...data}
                    />
                  </div>
                ))
                .otherwise(() => (
                  <div
                    className="max-w-8xl mx-auto mb-6 flex flex-col gap-6 px-4 md:mb-8 md:gap-8 md:px-8 lg:mb-12 lg:gap-12"
                    key={index}
                  >
                    <ErrorBoundary>
                      {/* TODO: fix this error */}
                      {/* @ts-expect-error */}
                      {getMappedComponent(component, index)}
                    </ErrorBoundary>
                  </div>
                ))
            )}
            {
              // If only Hero Section is added as a content on the page, we will render the default Below The Fold component
              heroOnlyContent.length === components?.length ? (
                <BelowTheFold data={pageContent} />
              ) : null
            }
          </ErrorBoundary>
        ) : (
          <div className="max-w-8xl mx-auto grid grid-cols-12 py-32">
            <div className="col-span-6 flex flex-col gap-y-6">
              <Skeleton className="h-16" count={2} />
              <Skeleton count={4.5} />
            </div>
          </div>
        )}
        <CountryRedirectModal />
      </main>
      <Footer footerId={footerId} type="Full" />
      {showPopupQuoteWizard ? (
        <PopupQuoteWizard data={headerData.quoteWizard} />
      ) : null}
    </TrackingProvider>
  )
}
export const query = graphql`
  query GenericHomeLandingTemplate($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...homeLandingPage
    }
  }
`
