import { useDevMode } from '@ecomm/data-storage'
import {
  useOptimizelyForceVariation,
  useOptimizelyVariations
} from '@ecomm/optimizely-hooks'
import { addAtomDebugLabel } from '@ecomm/utils'
import { format } from 'date-fns'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { pipe } from 'fp-ts/lib/function'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const devSelectionAtom = atomWithStorage(
  'optimizely-dev-selection',
  {
    expirement: '',
    variation: ''
  },
  {
    getItem: key =>
      JSON.parse(
        sessionStorage.getItem(key) ||
          JSON.stringify({ expirement: '', variation: '' })
      ),
    setItem: (key, newValue) =>
      Promise.resolve(sessionStorage.setItem(key, JSON.stringify(newValue))),
    removeItem: key => sessionStorage.removeItem(key)
  }
)

addAtomDebugLabel(devSelectionAtom, 'Optimizely Dev Selection')

function DevTools({ handleClose }: { readonly handleClose: () => void }) {
  const [_, disablePerm, disableTemp] = useDevMode()

  const handleTemp = useCallback(() => {
    disableTemp()
    location.reload()
  }, [])

  const optimizelyVariations = useOptimizelyVariations()

  const experiments = useMemo(() => {
    return pipe(
      optimizelyVariations,
      O.map(R.keys),
      O.getOrElse<readonly string[]>(() => [])
    )
  }, [optimizelyVariations])

  const [selection, setSelection] = useAtom(devSelectionAtom)

  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  const [experimentKey, setexperimentKey] = useState(selection.expirement)
  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  const [variationId, setVariationId] = useState(selection.variation)

  const variations = useMemo(() => {
    return pipe(
      optimizelyVariations,
      O.chain(R.lookup(experimentKey)),
      O.getOrElse<readonly string[]>(() => [])
    )
  }, [experimentKey])

  const [log, setLog] = useState<ReadonlyArray<string>>([])

  const force = useOptimizelyForceVariation(experimentKey, variationId)

  useEffect(() => {
    force()
  }, [])

  const handleClick = () => {
    force()
      .then(t => {
        setLog(prev => [
          `[${format(
            new Date(),
            'HH:mm:ss'
          )}] - set ${experimentKey} to ${variationId}. Success: ${t}`,
          ...prev
        ])
        setSelection({ expirement: experimentKey, variation: variationId })
        return null
      })
      .catch(err =>
        setLog(prev => [
          `[${format(new Date(), 'HH:mm:ss')}] - ERROR! ${err}`,
          ...prev
        ])
      )
  }

  return (
    <div
      style={{
        background: '#cbd5e1',
        minHeight: 100,
        minWidth: 300,
        padding: 4,
        border: '1px solid #25262b'
      }}
    >
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          background: 'none',
          border: 'none',
          fontSize: '24px',
          cursor: 'pointer'
        }}
        title="close"
      >
        X
      </button>
      <h2>Optimizely Dev Tools</h2>
      <p>This is on by default when running local development.</p>
      <p>To disable set GATSBSY_DEV_MODE to false.</p>
      <p>
        Forced variations are kept in session storage and will reset when you
        close the page.
      </p>
      <p>
        This is only for development and testing of the experience on the site
        and should not be used for testing any metrics or reporting.
      </p>
      <table>
        <tr>
          <td>
            <label>Select experiment</label>
          </td>
          <td>
            <select onChange={e => setexperimentKey(e.target.value)}>
              <option disabled selected value="">
                {' '}
                --{' '}
              </option>
              {experiments.map((ex, i) => (
                <option key={`${ex}-${i}`}>{ex}</option>
              ))}
            </select>
          </td>
        </tr>
        <tr>
          <td>
            <label>Select variation</label>
          </td>
          <td>
            <select onChange={e => setVariationId(e.target.value)}>
              <option disabled selected value="">
                {' '}
                --{' '}
              </option>
              {variations.map((v, i) => (
                <option key={`${v}-${i}`}>{v}</option>
              ))}
            </select>
          </td>
        </tr>
      </table>
      <br />
      <button onClick={handleClick}>Force variation</button>
      <div
        style={{
          background: 'black',
          color: 'white',
          padding: 5,
          margin: 5,
          height: 100,
          overflowY: 'scroll'
        }}
      >
        {log.map((t, i) => (
          <pre key={`${t}-${i}`}>{t}</pre>
        ))}
      </div>
      <div style={{ marginTop: '16px' }}>
        <button onClick={disablePerm}>Disable dev tools permanently</button>
        <br />
        <small>To restore remove the disable_dev key from local storage.</small>
      </div>
      <div style={{ marginTop: '16px' }}>
        <button onClick={handleTemp}>Disable Dev Tools Temporarly</button>
        <br />
        <small>To restore remove the disable_dev param from the URL</small>
      </div>
    </div>
  )
}

export function OptimizelyDev() {
  const [open, setOpen] = useState(false)

  return (
    <div
      style={{
        fontFamily: 'monospace',
        position: 'fixed',
        bottom: '84px',
        left: '10px',
        zIndex: 100
      }}
    >
      {open ? (
        <DevTools handleClose={() => setOpen(false)} />
      ) : (
        <button
          onClick={() => setOpen(true)}
          style={{
            borderRadius: '50%',
            width: 64,
            height: 64,
            cursor: 'pointer',
            border: '1px solid #25262b',
            background: '#25262b',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            fontSize: '32px'
          }}
          title="Open Dev Tools"
        >
          <div>💻</div>
        </button>
      )}
    </div>
  )
}
