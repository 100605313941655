import * as O from 'fp-ts/lib/Option'

// Dec 2024 - Jan 2025 free Olympus gift overrides
// See https://simplisafe.atlassian.net/browse/ECP-12098
const freeOlympusPromoCodeMap = new Map<string, string>()
// 12/12 - 12/17, 12/27 - 1/2, 1/20 - 1/22, 1/23 - 1/26
freeOlympusPromoCodeMap.set('SIMPLIFAST50SAFE', 'SIMPLIFAST50SAFEPRO')
// 12/18 - 12/26, 1/3 - 1/5, 1/6 - 1/14, 1/15 - 1/19
// freeOlympusPromoCodeMap.set('SIMPLIFAST20NEW', 'SIMPLIFAST20PRO')
// freeOlympusPromoCodeMap.set('SIMPLI20CAMS', 'SIMPLI20CAMSPRO')
// 1/27 - 1/28
// freeOlympusPromoCodeMap.set('TBD - ADD LATER', 'SIMPLIFAST40CAMPRO')

export const usePlanChoicePromoOverride =
  (): ((siteMonitoringPromoCode: string) => O.Option<string>) =>
  (siteMonitoringPromoCode: string) =>
    O.fromNullable(freeOlympusPromoCodeMap.get(siteMonitoringPromoCode))
