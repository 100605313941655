import { CheckMark } from '@ecomm/shared-icons'

export function OdmonMAPSComparisonTable() {
  return (
    <table
      className="table w-full table-fixed border-separate border-spacing-y-0 place-items-center items-center rounded-3xl px-3 py-10 md:px-8 md:py-8"
      data-testid="MonitoringComparisonTable"
    >
      <thead>
        <tr>
          <th
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid pb-2 text-sm font-normal first:text-left font-bold md:pb-6 md:text-lg"
            colSpan={2}
          >
            <strong>Compare Plans</strong>
          </th>
          <th
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid pb-2 text-sm font-bold first:text-left md:pb-6 md:text-lg"
            colSpan={1}
          >
            Core
          </th>
          <th
            className="border-neutral-medium-100 border-b border-l border-r border-t-0 border-solid pb-2 text-sm first:text-left font-bold md:pb-6 md:text-lg"
            colSpan={1}
          >
            Pro
          </th>
          <th
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid pb-2 text-sm first:text-left font-bold md:pb-6 md:text-lg"
            colSpan={1}
          >
            Pro Plus
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Professional monitoring:</strong> Our security agents are
            ready around the clock to request dispatch in the event of a
            break-in, fire, or medical emergency.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>SimpliSafe® Active Guard Outdoor Protection: </strong>A
            double layer of outdoor defense — advanced AI detects threats and
            live agents intervene to proactively prevent crime outside your home
            while your system is armed.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-xs first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <svg className="h-8 w-8">
              <use height="100%" href="/icons/miss.svg#Miss" width="100%"></use>
            </svg>
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-xs md:py-6 md:text-base leading-tight"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
            <span className="block">Available 8pm-6am,</span>
            <span className="block">local time</span>
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-xs first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
            <span className="block">Available 24/7</span>
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Video Verification: </strong>If your cameras capture video
            evidence during an alarm, agents will relay information to emergency
            dispatch, resulting in faster police response compared to unverified
            alarms.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Intruder intervention: </strong>Agents can use the Smart
            Alarm Indoor Camera to see and speak to intruders to help stop crime
            in real time during an alarm.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Unlimited camera recordings</strong> &amp; 30 day cloud
            storage.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Control your system in the app: </strong>Arm, disarm,
            confirm or cancel alarms using the app.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>Built-in cellular connection and back-up battery: </strong>
            The Base Station includes a cellular connection that keeps you
            linked to professional monitoring and a back-up battery that lasts
            up to 24 hours in case there’s a power outage.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={2}
          >
            <strong>No contracts. No cancellation fees:</strong> You’ll never be
            locked into a long term contract, so you can cancel any time.
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-b border-l border-r border-t-0 border-solid border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
          <td
            className="border-neutral-medium-100 border-b border-l-0 border-r-0 border-t-0 border-solid py-2 text-center text-sm first:text-left md:py-6 md:text-base"
            colSpan={1}
          >
            <CheckMark className="h-6 w-6 rotate-[15deg] text-complementary-green-100" />
          </td>
        </tr>
        <tr>
          <td
            className="border-neutral-medium-100 border-l border-l-0 border-r border-r-0 text-center text-sm first:text-left md:text-2xl md:text-base"
            colSpan={2}
          >
            <span className="mt-4 inline-block">
              <strong>Price</strong>
            </span>
          </td>
          <td
            className="border-neutral-medium-100 border-l border-l-0 border-r border-r-0 text-center text-sm first:text-left md:text-2xl md:text-base"
            colSpan={1}
          >
            <span className="mt-4 inline-block">$1.06/</span>
            <span className="block md:inline-block">day</span>
          </td>
          <td
            className="border-neutral-medium-100 border-y-0 border-l border-l border-r border-r border-solid text-center text-sm first:text-left md:text-2xl md:text-base"
            colSpan={1}
          >
            <span className="mt-4 inline-block">$1.66/</span>
            <span className="block md:inline-block">day</span>
          </td>
          <td
            className="border-neutral-medium-100 border-l border-l-0 border-r border-r-0 text-center text-sm first:text-left md:text-2xl md:text-base"
            colSpan={1}
          >
            <span className="mt-4 inline-block">$2.66/</span>
            <span className="block md:inline-block">day</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
