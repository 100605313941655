import {
  useComparisonTableQuery,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery,
  useUserReviewsDataQuery
} from '@ecomm/lander'
import {
  type ConfidenceBarProps,
  type FeatureSectionDataProps,
  ComparisonTable,
  ConfidenceBar,
  FAQs,
  FeatureSection,
  GuaranteeSection,
  parseJSONDataFromContentful,
  useCompetitivePageFAQsData
} from '@ecomm/scout'
import { UserReviews } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import React from 'react'

import {
  type HeroBannerData,
  type HeroBannerProps,
  HeroBanner
} from '../HeroBanner'
import {
  type OptionsComparisonDataProps,
  OptionsComparison
} from '../OptionsComparison'
import { getSystemBannerData, SystemBanner } from '../SystemBanner'
import { type TextSectionProps, TextSection } from '../TextSection'
import { useCompetitiveComponentsQuery } from './useCompetitiveComponentsQuery'
import {
  type CompetitiveComponentsProps,
  getComparisonTableDataByCompetitor,
  getHeroBannerTitleByCompetitor
} from './utils'

export function CompetitiveComponents({
  competitor
}: CompetitiveComponentsProps) {
  const faqsData = useCompetitivePageFAQsData()
  const isTabletAndUp = useMediaQuery('TabletAndUp')
  const staticQuery = useCompetitiveComponentsQuery()
  const guaranteeAsset = useGuaranteeAssetQuery()

  // @ts-expect-error - this needs a zod schema to change the type from unknown
  const quoteWizardData = staticQuery.quoteWizard

  const heroBannerInfo: HeroBannerData = parseJSONDataFromContentful(
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    staticQuery,
    'heroBanner'
  )
  const heroBannerData: HeroBannerProps = {
    image: isTabletAndUp
      ? // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.heroBannerAsset
      : // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.mobileheroBannerAsset,
    title: getHeroBannerTitleByCompetitor(competitor, heroBannerInfo.title),
    text: heroBannerInfo?.text
  }
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    staticQuery,
    'confidenceBar'
  )
  const guaranteeSectionCopy = useGuaranteeCopyQuery()
  const { userReviewsData } = useUserReviewsDataQuery()
  const { comparisonTable } = useComparisonTableQuery('1zTyewofJ6lKWySS451GBg')
  const comparisonTableData = getComparisonTableDataByCompetitor(
    competitor,
    comparisonTable.content
  )
  const systemBannerData = getSystemBannerData({
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    copy: staticQuery.systemBanner,
    image: isTabletAndUp
      ? // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.tabletAndUpSystemBannerAsset
      : // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.mobileSystemBannerAsset,
    quoteWizard: quoteWizardData
  })
  const textSectionData: TextSectionProps = parseJSONDataFromContentful(
    // @ts-expect-error - this needs a zod schema to change the type from unknown

    staticQuery,
    'textSection'
  )
  const featureSectionData: FeatureSectionDataProps =
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    parseJSONDataFromContentful(staticQuery, 'featureSection')

  const optionsComparisonData: OptionsComparisonDataProps =
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    parseJSONDataFromContentful(staticQuery, 'optionsComparison')
  const optionsComparisonImages = [
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    staticQuery.optionsComparisonDIYAsset,
    isTabletAndUp
      ? // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.optionsComparisonProInstallAsset
      : // @ts-expect-error - this needs a zod schema to change the type from unknown
        staticQuery.mobileOptionsComparisonProInstallAsset
  ]

  return (
    <>
      <HeroBanner data={heroBannerData} />
      <ComparisonTable data={comparisonTableData} />
      <TextSection data={textSectionData} />
      <ConfidenceBar data={confidenceBarData} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={guaranteeAsset.guaranteeAsset}
        quoteWizard={quoteWizardData}
      />

      <FeatureSection
        data={featureSectionData}
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        image={staticQuery.featureSectionAsset}
      />

      <OptionsComparison
        data={optionsComparisonData}
        images={optionsComparisonImages}
      />
      <UserReviews {...userReviewsData.content} />
      <FAQs data={faqsData} />
      <SystemBanner data={systemBannerData} />
    </>
  )
}
