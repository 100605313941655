import {
  getExpertReviews,
  QuizBanner,
  useExpertReviewsAssetQuery,
  useExpertReviewsQuery,
  useExpertSectionAssetQuery,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery,
  useQuoteWizardAssetQuery,
  useUserReviewsDataQuery
} from '@ecomm/lander'
import {
  ConfidenceBar,
  ExpertSection,
  ExpertsReviews,
  FAQs,
  GuaranteeSection
} from '@ecomm/scout'
import { useMoversPageFAQsData } from '@ecomm/scout/src/components/FAQs/useMoversPageFAQsData'
import { UserReviews } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import React from 'react'

import {
  useMoversComponentsQuery,
  useStaticMoversData
} from './useMoversComponentsQuery'

export function MoversComponents() {
  const staticQuery = useMoversComponentsQuery()
  const { locale } = useEnv()
  const faqsData = useMoversPageFAQsData()
  const { confidenceBarData } = useStaticMoversData()
  const guaranteeAsset = useGuaranteeAssetQuery()
  const quoteWizardAsset = useQuoteWizardAssetQuery()

  const { expertReviews: expertReviewsData } = useExpertReviewsQuery()
  const expertReviewsAssets = useExpertReviewsAssetQuery([
    '5X5OsfxAW6Fc2Zj3HZDlHC',
    '7eH4KhA049wM5ZTOOmWyWd'
  ])
  const expertReviews = getExpertReviews(
    expertReviewsAssets.items,
    locale,
    expertReviewsData.content.reviews
  )

  const expertSectionAssetData = useExpertSectionAssetQuery()

  const guaranteeSectionCopy = useGuaranteeCopyQuery()
  const { userReviewsData } = useUserReviewsDataQuery()
  const expertsSection = {
    image: expertSectionAssetData,
    title: 'Need help? Our experts are here.'
  }

  return (
    <>
      <ConfidenceBar data={confidenceBarData.content} />
      <ExpertsReviews reviews={expertReviews} />
      <QuizBanner
        image={quoteWizardAsset}
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        quoteWizard={staticQuery.quoteWizard}
      />
      <FAQs data={faqsData} />
      <UserReviews {...userReviewsData.content} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={guaranteeAsset.guaranteeAsset}
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        quoteWizard={staticQuery.quoteWizard}
      />
      <ExpertSection data={expertsSection} />
    </>
  )
}
