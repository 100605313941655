import { Link } from '@ecomm/framework'
import { useTrackOdmonPlanSelection } from '@ecomm/tracking'
import type { ReactNode } from 'react'

import { FeatureListItem } from './FeatureListItem'
import { featureListItemsLeftData, featureListItemsRightData } from './data'

type StandardMonitoringModalContentProps = {
  readonly atcButton?: ReactNode
  readonly price: string
}

export function StandardMonitoringModalContent({
  atcButton = null,
  price
}: StandardMonitoringModalContentProps) {
  const trackCTA = useTrackOdmonPlanSelection()

  return (
    <div
      className="prose md:prose-md lg:prose-lg whitespace-pre-line max-w-[750px] px-4 py-10 md:px-8 md:py-12 lg:p-16"
      data-component="StandardMonitoringModalContent"
    >
      <h2>Standard Monitoring</h2>
      <p>
        A team of security experts ready to help you when your alarm goes off.
        They&apos;ll walk you through any emergency and dispatch help in a snap
        - day or night.
      </p>

      <div className="grid grid-cols-2 gap-2 md:gap-8 lg:gap-16">
        <div>
          {featureListItemsLeftData.map((f, idx) => (
            <FeatureListItem
              isFeatured={f.isFeatured}
              key={`FeatureListItemsLeft-${idx}`}
            >
              {f.children}
            </FeatureListItem>
          ))}
        </div>
        <div>
          {featureListItemsRightData.map((f, idx) => (
            <FeatureListItem
              isFeatured={f.isFeatured}
              key={`FeatureListItemsRight-${idx}`}
            >
              {f.children}
            </FeatureListItem>
          ))}
        </div>
      </div>

      <div className="mt-6 grid-cols-2 gap-2 md:grid md:gap-8 lg:gap-16">
        <div>
          <p>
            <strong>{price}</strong>
            <br />
            No contracts. Cancel any time.
          </p>
        </div>
        <div>
          <div onClick={() => trackCTA('standard', 'modal')}>{atcButton}</div>
          <div className="mt-4">
            <p className="text-xs">
              *See <Link to="/legal/terms-of-service">Terms of Service</Link>{' '}
              for details and conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
