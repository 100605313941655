import { gql } from '@apollo/client/index.js'

export const EXPERT_SECTION_ASSET_QUERY = gql`
 query ExpertSectionAsset($preview: Boolean! $locale: String!){
    expertSectionAsset: asset(
      id:"4l8DenaxSNBl1LH3mmDLMv",
      locale : $locale,
      preview : $preview
    ) {
      url
      originalHeight : height
      originalWidth : width
      description
  }
}`
