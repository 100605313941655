import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useLocale } from '@ecomm/data-hooks'
import { useOnClickOutside } from '@ecomm/shared-hooks'
import { ChevronDown } from '@ecomm/shared-icons'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import type { Locale } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { document } from 'browser-monads-ts'
import classNames from 'classnames'
import { type ReactNode, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useTracking } from 'react-tracking'
import { match, P } from 'ts-pattern'

import { useCallOnEvent } from '../../hooks/useCallOnEvent'
import { AccountDropdownMenu } from './AccountDropdownMenu'
import {
  getPackagesMenuLeftItems,
  getPackagesMenuMiddleItems,
  getServiceMenuItems,
  NARROWEST_DESKTOP_SIZE
} from './data'
import { PackagesDropdownMenu } from './PackagesDropdownMenu'
import type { HeaderRedesignSchema } from './schema'
import { ServicesDropdownMenu } from './ServicesDropdownMenu'
import { devThrowError } from '@ecomm/error-handling'

type NavMenuProps = HeaderRedesignSchema & {
  readonly text: string
  readonly icon?: ReactNode
  readonly displayInSideNav?: boolean
  readonly mobileIcon?: ReactNode
  readonly dropdownMenu?: string
  readonly onToggleDropdown: (close?: boolean) => void
  readonly showDropdown: boolean
  readonly showOverlay?: boolean
  /**
   * Set mainNavDesktop to true if your NavMenu instance is being rendered inside the main nav in desktop mode
   */
  readonly mainNavDesktop?: boolean
  /**
   * Set sideNav to true if your NavMenu instance is being rendered inside the side nav
   */
  readonly sideNav?: boolean
}

const getDropdownUrls = ({
  dropdownMenu,
  locale,
  isOdmonVariant
}: {
  readonly dropdownMenu?: string
  readonly locale: Locale
  readonly isOdmonVariant: boolean
}) => {
  if (dropdownMenu === 'ServicesDropdownMenu') {
    return getServiceMenuItems(locale, isOdmonVariant).map(item => item.url)
  } else if (dropdownMenu === 'PackagesDropdownMenu') {
    return [
      ...getPackagesMenuLeftItems(locale),
      ...getPackagesMenuMiddleItems(locale, isOdmonVariant)
    ].map(item => item.url)
  } else {
    return []
  }
}

const renderChevronDown = ({
  showDropdown,
  icon
}: { readonly showDropdown: boolean; readonly icon?: ReactNode }) => {
  return (
    <ChevronDown
      className={classNames(
        'transition-transform duration-200',
        icon
          ? 'ml-0.5 mt-0.5 h-3 w-3 stroke-2'
          : 'ml-2 mt-1 h-4 w-4 stroke-[4px] min-[1126px]:mb-[5px]',
        {
          'rotate-180': showDropdown,
          'rotate-0': !showDropdown
        }
      )}
    />
  )
}

export function NavMenu({
  images,
  text,
  onToggleDropdown,
  showDropdown,
  icon = null,
  mobileIcon = null,
  displayInSideNav = false,
  dropdownMenu = '',
  showOverlay = true,
  mainNavDesktop = false,
  sideNav = false
}: NavMenuProps) {
  const locale = useLocale()
  const { isVariant: isOdmonVariant } = useOdmonExperience()

  const ref = useRef<HTMLDivElement>(null)

  const [isChildActive, setIsChildActive] = useState(false)
  const location = useLocation()
  const { trackEvent } = useTracking()

  useEffect(() => {
    const dropdownUrls = getDropdownUrls({
      dropdownMenu,
      locale,
      isOdmonVariant
    })
    setIsChildActive(dropdownUrls.includes(location.pathname))
  }, [location.pathname, dropdownMenu])

  const onClickOutside = () =>
    showDropdown &&
    document.body.clientWidth >= NARROWEST_DESKTOP_SIZE &&
    onToggleDropdown()

  const onButtonClick = () => {
    trackEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      label: text
    })
    trackNavigationClick({
      action: 'click',
      navElement: text
    })
    onToggleDropdown()
  }

  useOnClickOutside(onClickOutside, ref)

  useCallOnEvent(() => mainNavDesktop && onToggleDropdown(true), 'scroll')

  return (
    <div className={classNames(!showOverlay && 'relative')} ref={ref}>
      <button
        className={classNames(
          'text-neutral-black min-[1126px]:hover:text-primary-100 h-16 w-full cursor-pointer items-center border-0 border-b border-solid border-b-neutral-300 bg-transparent p-0 px-4 transition-[color] duration-200 min-[1126px]:h-auto min-[1126px]:w-auto min-[1126px]:border-none min-[1126px]:p-0 min-[1126px]:text-white',
          icon
            ? 'grid grid-cols-1 justify-items-center gap-0 text-[13px]'
            : 'inline-flex gap-3 text-lg font-medium min-[1126px]:h-full',
          displayInSideNav &&
            (icon ? 'hidden min-[1126px]:block' : 'text-white'),
          showDropdown && 'min-[1126px]:!text-primary-100 border-b-transparent'
        )}
        data-testid={text.includes('Shop') ? 'header-shop-button' : null}
        onClick={onButtonClick}
      >
        {icon}
        {mobileIcon ? <div className="mt-2 w-6">{mobileIcon}</div> : null}
        <div className="flex w-full justify-between min-[1126px]:h-full min-[1126px]:items-center">
          <span
            className={classNames(mobileIcon && 'font-bold', {
              'min-[1126px]:mb-[1px] min-[1126px]:grid min-[1126px]:content-center min-[1126px]:self-end min-[1126px]:border-0 min-[1126px]:border-b-2 min-[1126px]:border-solid min-[1126px]:border-b-transparent':
                !icon,
              'min-[1126px]:!border-b-primary-100 min-[1126px]:border-b-[5px] min-[1126px]:pb-[21px] min-[1126px]:pt-[24px]':
                isChildActive && !showDropdown
            })}
          >
            {text}
          </span>
          {renderChevronDown({ showDropdown, icon })}
        </div>
      </button>
      {match(dropdownMenu)
        .with(
          'ServicesDropdownMenu',
          () =>
            showDropdown && (
              <ServicesDropdownMenu
                images={images}
                isOdmonVariant={isOdmonVariant}
                onToggleDropdown={onToggleDropdown}
                quoteWizard={{
                  jebbitUrl: '',
                  sys: { id: '0' },
                  type: 'floating',
                  id: '0',
                  ntExperiencesCollection: { items: [] }
                }}
              />
            )
        )
        .with(
          'PackagesDropdownMenu',
          () =>
            showDropdown && (
              <PackagesDropdownMenu
                images={images}
                isOdmonVariant={isOdmonVariant}
                onToggleDropdown={onToggleDropdown}
                quoteWizard={{
                  jebbitUrl: '',
                  sys: { id: '0' },
                  type: 'floating',
                  id: '0',
                  ntExperiencesCollection: { items: [] }
                }}
              />
            )
        )
        .with('AccountDropdownMenu', () => (
          <AccountDropdownMenu
            onToggleDropdown={onToggleDropdown}
            showDropdown={showDropdown}
            sideNav={sideNav}
          />
        ))
        .with(P.string, str => {
          devThrowError(
            Error(`dropdown menu prop is of an unexpected type: ${str}`)
          )
          return null
        })
        .exhaustive()}
      {showOverlay ? (
        <div
          className={classNames(
            'absolute left-0 top-[286px] z-[9] hidden h-screen w-full bg-black transition-opacity duration-300 min-[1126px]:block',
            {
              'opacity-40': showDropdown,
              'pointer-events-none opacity-0': !showDropdown
            }
          )}
          data-testid={`dropdown-background-${text}`}
          onClick={() => onToggleDropdown()}
          style={{ height: 'calc(100vh - 286px)' }}
        />
      ) : null}
    </div>
  )
}
