import {
  ContentfulRichText,
  GatsbyImage,
  Modal,
  NewFlag
} from '@ecomm/shared-components'
import React from 'react'

import type { ProductModalSchema } from './schema'

export type Props = {
  readonly modal: ProductModalSchema
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
  readonly isNew: boolean
}

function ProductModal({ modal, open, setOpen, isNew }: Props) {
  return (
    <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
      <div
        className="flex max-w-screen-lg flex-col-reverse gap-8 p-8 lg:flex-row prose md:prose-md lg:prose-lg lg:items-center"
        data-component="modal_content"
      >
        <div className="relative flex-1 overflow-hidden rounded-2xl">
          <GatsbyImage
            className={`z-[-1] h-full w-full lg:h-fit`}
            image={modal.image}
          />
          {isNew ? (
            <div className="absolute right-0 top-8 mr-4">
              <NewFlag hasBackground />
            </div>
          ) : null}
        </div>
        <div className="my-auto flex-1 justify-items-start py-6 pl-0 pr-12 text-xl">
          <h3 className="mb-4" data-component="modal_headline">
            {modal.headline}
          </h3>
          <ContentfulRichText raw={modal.description.raw} />
        </div>
      </div>
    </Modal>
  )
}

export default ProductModal
