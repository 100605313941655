import { TypeOf, z } from '@simplisafe/ewok'

const idSchema = z.object({
  id: z.string()
})

export const youtubeVideoSchema = z.object({
  sys: idSchema,
  __typename: z.literal('YoutubeVideo'),
  title: z.string(),
  link: z.string(),
  text: z.string()
})

export type YoutubeVideoProps = TypeOf<typeof youtubeVideoSchema>
