import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { getContentfulImageFromGatsbyData } from '@ecomm/contentful-image-utils'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'

import { ReviewCardSchema } from '@ecomm/home-landing-data'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const asset = getContentfulImageFromGatsbyData(node.data.target)
      return <ContentfulImage classNameOverride="w-80" {...asset} />
    }
  }
}

export default function ReviewCard({
  image,
  iconSize,
  imagePosition,
  text,
  textAlignment,
  className = ''
}: ReviewCardSchema & { readonly className?: string }) {
  return (
    <div
      className={classNames(
        'bg-neutral-light-50 rounded-base relative flex w-full flex-col items-center justify-center gap-4 overflow-hidden',
        className
      )}
      data-component="review-card"
    >
      {image ? (
        <ContentfulImage
          classNameOverride={classNames('w-full', {
            'max-w-[40px]': iconSize === 'Extra Small',
            'max-w-[64px]': iconSize === 'Small',
            'max-w-[96px]': iconSize === 'Medium',
            'max-w-[128px]': iconSize === 'Large',
            'max-w-[256px]': iconSize === 'Extra Large'
          })}
          {...getContentfulImageFromGatsbyData(image)}
        />
      ) : null}
      {text ? (
        <div
          className={classNames({
            'prose-headings:mb-0 prose-headings:text-lg prose-headings:font-bold absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-4':
              imagePosition === 'Background',
            'text-left': textAlignment === 'Left',
            'text-right': textAlignment === 'Right',
            'text-center': textAlignment === 'Center'
          })}
          data-component="text-container"
        >
          <ContentfulRichText {...text} optionsCustom={options} />
        </div>
      ) : null}
    </div>
  )
}
