import { TypeOf, z } from '@simplisafe/ewok'
import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'

export const faqSchema = z.object({
  primary: z
    .object({
      question: z.object({
        question: z.string()
      }),
      answer: contentfulRichTextSchema
    })
    .array()
})

export type FAQSchema = TypeOf<typeof faqSchema>
