import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useLocale } from '@ecomm/data-hooks'
import { GatsbyImage } from '@ecomm/shared-components'
import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { handleGTMTrackingEvent } from '@ecomm/tracking'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Link } from '@ecomm/framework'
import { useCallback } from 'react'

import CartLink from './CartLink'
import ProgressBar from './ProgressBar'
import type { HeaderFragment } from './schema'

type HeaderProps = {
  readonly data: HeaderFragment
}

//This for now is hardcoded, but once we need progress bar in another place, we might need to move this to contentful or another global place.
const progressBarList = [
  {
    percentage: 10,
    text: 'Review System'
  },
  {
    percentage: 50,
    text: 'Choose Monitoring'
  },
  {
    percentage: 100,
    text: 'Checkout'
  }
]

function Header({ data }: HeaderProps) {
  const { mobileMenu } = data
  const locale = useLocale()

  const handleClick = useCallback(() => {
    handleGTMTrackingEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      eventLabel: 'Home'
    })
    trackNavigationClick({
      action: 'click',
      navElement: 'Home'
    })
  }, [])

  return (
    <header className="bg-neutral-black h-12 text-white md:h-24 lg:h-28">
      <div className="max-w-8xl mx-auto flex h-full w-full items-center justify-between gap-3 px-4 md:px-8">
        <Link
          aria-label="Home"
          className="flex w-28 flex-shrink-0 justify-self-start md:w-[150px]"
          onClick={handleClick}
          role="link"
          state={{ source: 'header' }}
          style={{ textDecoration: 'inherit' }}
          to={getLogoLinkUrl('/')}
        >
          <SimpliSafeLogo className="h-7 w-28 md:h-9 md:w-40" />
        </Link>
        <div className="mr-5 flex grow items-center justify-end">
          {pipe(
            O.fromNullable(mobileMenu),
            O.map(menu =>
              menu.map(link => (
                <Link
                  className="hover:text-primary-100 flex items-center text-white"
                  key={link.title}
                  onClick={handleClick}
                  state={{ source: 'header' }}
                  style={{ textDecoration: 'inherit' }}
                  to={link.url}
                >
                  {link.image ? (
                    <GatsbyImage
                      className="!h-8 !w-8 md:hidden"
                      image={link.image}
                      loading="eager"
                    />
                  ) : null}
                  <span className="hidden md:block">{link.title}</span>
                </Link>
              ))
            ),
            O.toNullable
          )}
        </div>

        <CartLink />

        {locale === 'en-GB' ? (
          <ProgressBar
            currentStep={progressBarList[0]}
            steps={progressBarList}
          />
        ) : null}
      </div>
    </header>
  )
}

export default Header
