import { Link } from '@ecomm/framework'
import { GatsbyImage, type GatsbyImageSchema } from '@ecomm/shared-components'

type ItemBaseProps = {
  readonly image: GatsbyImageSchema
  readonly title: string
  readonly text: string
  readonly linkText: string
}

type ButtonProps = {
  readonly onClick: React.MouseEventHandler<HTMLButtonElement>
  readonly linkUrl?: never
}

type LinkProps = {
  readonly onClick?: never
  readonly linkUrl: string
}

type ItemProps = ItemBaseProps & (ButtonProps | LinkProps)

export function Item({
  image,
  onClick,
  title,
  text,
  linkText,
  linkUrl
}: ItemProps) {
  return (
    <div className="md:flex md:flex-1 lg:flex-col">
      <GatsbyImage
        className="max-h-[245px] rounded-[20px] md:max-h-[205px] md:min-w-[286px] md:max-w-[286px] md:flex-1 lg:max-h-[294px] lg:min-h-[294px] lg:max-w-none"
        image={image}
      />
      <div className="md:ml-8 lg:ml-0">
        <h3 className="mb-2 mt-6 text-[28px] font-bold leading-[32px] md:mt-2 lg:mt-6">
          {title}
        </h3>
        <p className="mb-0 text-base md:max-w-[313px] lg:max-w-none">{text}</p>

        {onClick ? (
          <button
            className="btn btn-outlined btn-outlined-primary mt-6 w-fit"
            onClick={onClick}
            type="button"
          >
            {linkText}
          </button>
        ) : linkUrl ? (
          <Link
            className="btn btn-outlined !px-4 btn-outlined-primary mt-6 block w-fit"
            to={linkUrl}
          >
            {linkText}
          </Link>
        ) : null}
      </div>
    </div>
  )
}
