import { gatsbyHeroBannerSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

import { componentTypes } from '../../components/ContentMapper/schema'

export const featuresAlarmLayoutSchema = z.object({
  components: z.array(gatsbyHeroBannerSchema),
  promoBannerStyle: z.string(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const featuresAlarmMonitoringTemplateSchema = z.object({
  contentful_id: z.string(),
  slug: z.string(),
  breadcrumbTitle: z.string().nullish(),
  layout: featuresAlarmLayoutSchema,
  content: componentTypes.array().nonempty()
})

export type FeaturesAlarmMonitoringTemplateFragment = TypeOf<
  typeof featuresAlarmMonitoringTemplateSchema
>
