import { gql } from '@apollo/client/index.js'

export const EXPERTS_REVIEWS_ASSET_QUERY = gql`query ExpertReviewsAsset($locale : String! $preview : Boolean! $ids : [String]! ) {
  expertReviewsAssets: assetCollection(
    locale : $locale,
    preview : $preview,
    where: {sys: {id_in: $ids}},
  ) {
    items {
      sys {
        id
      }
      title
      description
      originalWidth: width
      originalHeight: height
      url
    }
  }
}`
