import { useCartQuantity } from '@ecomm/shared-hooks'
import { Link } from '@ecomm/framework'
import React from 'react'

import { cartItem } from '../Header/data'

export default function CartLink() {
  const { cartQuantity } = useCartQuantity()

  return (
    <Link
      className="hover:border-b-primary-100 relative grid grid-cols-1 justify-items-center gap-0 border-0 border-b border-solid border-b-transparent p-0 text-[10px] text-white no-underline transition-[border-bottom-color] duration-300 min-[1126px]:h-auto min-[1126px]:text-[13px]"
      to={cartItem.url || '#'}
    >
      {cartQuantity > 0 && (
        <div
          className="bg-primary-100 text-neutral-black absolute -right-1 -top-1 h-4 w-4 rounded-full pl-[5px] text-[11px]"
          data-component="cart-quantity"
          data-testid="cart-quantity"
        >
          <span>{cartQuantity}</span>
        </div>
      )}
      {cartItem.icon}
      <div className={'mt-0 flex min-[1126px]:mt-1'}>
        <span>{cartItem.text}</span>
      </div>
    </Link>
  )
}
