import { usePlanChoiceDynamicNonIntentStayExperience } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'

import type { OptInOrOutProps } from '../../OptInOrOut/types'
import { OdmonMAPSCoreMonitoring } from '../OdmonMAPSCoreMonitoring'
import { OdmonHybridMAPS } from '../OdmonHybridMAPS'
import { MonitoringPlan } from '../../../DraftCart/types'

type Props = OptInOrOutProps & {
  readonly json: { readonly id: string; readonly tag: string }
}

export function OdmonPlanChoiceDynamicNonIntentPush({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  json,
  priceToggles
}: Props) {
  const planChoiceDynamicNonIntentStayExperience =
    usePlanChoiceDynamicNonIntentStayExperience()

  switch (json?.tag) {
    case 'variant-1':
      return (
        <OdmonHybridMAPS
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
          defaultMonitoringPlan={MonitoringPlan.odmonOvernight}
        />
      )
    case 'control':
    default:
      return (
        <div>
          <Experience
            {...planChoiceDynamicNonIntentStayExperience.data}
            component={OdmonPlanChoiceDynamicNonIntentStay}
            experiences={planChoiceDynamicNonIntentStayExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              priceToggles
            }}
          />
        </div>
      )
  }
}

export function OdmonPlanChoiceDynamicNonIntentStay({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  json,
  priceToggles
}: Props) {
  switch (json?.tag) {
    case 'variant-1':
      return (
        <OdmonHybridMAPS
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
          defaultMonitoringPlan={MonitoringPlan.interactive}
        />
      )
    case 'control':
    default:
      return (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
  }
}
