import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useMicroCopy } from '@ecomm/micro-copy'
import { Phone } from '@ecomm/shared-icons'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import React from 'react'
import { useTracking } from 'react-tracking'

import { GuidedSystemBuilder } from '../GuidedSystemBuilder'
import IconDropdown from '../IconDropdown'
import { StickyPopoverLink } from '../StickyPopoverLink'
import CartLink from './CartLink'
import type { CTAMenuProps } from './schema'

const dropdownUrls = [
  {
    url: 'https://webapp.simplisafe.com/',
    label: 'Login'
  },
  {
    url: 'https://webapp.simplisafe.com/#/register',
    label: 'Create account'
  }
]

export default function CTAMenu({
  phoneNumber = '',
  cartQuantity = 0,
  showShopCTA = true,
  headerType,
  quoteWizard
}: CTAMenuProps) {
  const { locale } = useEnv()
  const isUS = locale === 'en-US'
  const isLanderOrPLA = headerType === 'lander' || headerType === 'pla'

  const microCopy = useMicroCopy()

  const { trackEvent } = useTracking()
  const trackShopNowButtonClick = () => {
    trackEvent({
      label: 'Shop now',
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: 'Shop now'
    })
  }

  return (
    <nav className="prose-a:text-white ml-auto flex h-full items-center gap-2 md:gap-4">
      {
        /**
         * Gatsby Link should only be used for internal navigation, not a phone number
         * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links
         * This throws an error in tests that we have to summarily ignore
         */
        phoneNumber ? (
          <Link
            className={classNames(
              'hover:text-primary-100 inline-flex no-underline',
              { hidden: isUS, 'lg:hidden': isLanderOrPLA }
            )}
            to={`tel:${phoneNumber}`}
          >
            <span className="hidden text-sm lg:block ">{phoneNumber}</span>
            <span className="inline-flex md:hidden">
              <Phone className="h-6 w-6" />
            </span>
          </Link>
        ) : null
      }
      <IconDropdown
        className={classNames('z-[2]', {
          'hover:text-primary-100 hidden lg:inline-flex': !isUS,
          'inline-flex': isUS,
          'lg:hidden': isLanderOrPLA
        })}
        links={dropdownUrls}
      />
      <CartLink
        className={classNames({ 'lg:hidden': isLanderOrPLA })}
        quantity={cartQuantity}
      />
      {showShopCTA ? (
        <Link
          aria-label={headerType === 'pla' ? 'See more systems' : 'Shop now'}
          className={classNames(
            'btn btn-solid-primary hidden !px-4 min-w-fit md:block',
            { 'lg:order-4': !isUS }
          )}
          data-testid="header-shop-now-button"
          onClick={trackShopNowButtonClick}
          to={
            headerType === 'pla'
              ? '/home-security-shop-packages'
              : '/home-security-shop'
          }
        >
          {headerType === 'pla' ? 'See more systems' : 'Shop now'}
        </Link>
      ) : null}
      {quoteWizard ? (
        <StickyPopoverLink
          linkText={quoteWizard.buttonText ?? microCopy['take-our-quiz-label']}
        >
          <GuidedSystemBuilder {...quoteWizard} />
        </StickyPopoverLink>
      ) : null}
    </nav>
  )
}
