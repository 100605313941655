import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track lead email submit
 * @param submit_email_type location of the lead submission
 */
export function trackLeadSubmit(submit_email_type: string) {
  getRudderstack(r => r.track('lead_submit_email', { submit_email_type }))
}

export function trackLeadSaveQuote(save_quote_type: string) {
  getRudderstack(r => r.track('lead_save_quote', { save_quote_type }))
}
