import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { possibleEmbeddedSchema } from './ColoredSectionEmbeddedEntry'

export const themes = {
  grayWithOrange: 'Gray with Orange',
  navyWithWhiteText: 'Navy with White Text',
  navyWithOrange: 'Navy with Orange',
  orangeWithNavyText: 'Orange with Navy Text',
  lightGrayWithNavyText: 'Light Gray with Navy Text'
}

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12397
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export const coloredSectionSchema = z.object({
  content: z
    .object({
      text: z.object({
        raw: z.string(),
        references: possibleEmbeddedSchema.array().optional()
      })
    })
    .array()
    .default([]),
  disclaimer: contentfulRichTextSchema.nullish(),
  headline: z.string().nullish(),
  theme: z
    .enum([
      'Navy with White Text',
      'Gray with Orange',
      'Navy with Orange',
      'Orange with Navy Text',
      'Light Gray with Navy Text'
    ])
    .optional(),
  __typename: z.literal('ContentfulColoredSection')
})

export type ColoredSectionSchema = TypeOf<typeof coloredSectionSchema>
