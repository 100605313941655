import { useActivate } from '@ecomm/tracking'
import { prop } from '@simplisafe/ewok'
import { isNotNil } from '@simplisafe/ewok'
import { safeFind, safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import { Maybe, None } from 'monet'
import propEq from 'ramda/src/propEq'
import React, { type ReactElement, useEffect, useState } from 'react'

import type { ContentfulVariationContainer } from '../../../graphql'
import { mapPageComponentToTemplate, type PageProps } from '../Page'

type Variant = NonNullable<
  NonNullable<ContentfulVariationContainer['variations']>[number]
>

export type ContentfulVariationContainerComponentProps = {
  readonly data: ContentfulVariationContainer
  // Custom render function for contexts that don't use getMappedComponent
  readonly renderVariant?: (variant: Variant) => ReactElement | null
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

export default function ContentfulVariationContainerComponent({
  data,
  location,
  pageContext,
  renderVariant
}: ContentfulVariationContainerComponentProps) {
  const experimentKey = prop('experimentKey', data) || ''

  const variation = useActivate(experimentKey)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    // we want to return null during the static build to prevent content from popping out during hydration
    setReady(true)
  }, [])

  const variants = (prop('variations', data) || []).filter(isNotNil)
  // Get the mapping of variation name to Contentful entry id
  // https://www.contentful.com/help/optimizely-app/#using-optimizely-to-pick-the-right-variation
  // TODO switch to safePath once frontend is on TS 4 and has updated monda version
  const variantEntryMapping = safeProp('meta', data)
    .chain(safeProp('internal'))
    .chain(safeProp('content'))
    .cata<Record<string, string>>(
      () => ({}),
      content => JSON.parse(content)
    )
  // @ts-expect-error TS(2349) FIXME: This expression is not callable.
  const getVariantForId = (entryId: string) =>
    safeFind<Variant>(propEq('contentful_id', entryId))(variants)

  const activeVariantEntryId = Maybe.fromNull(variation).chain(_variation =>
    safeProp(_variation, variantEntryMapping)
  )
  const activeVariant = activeVariantEntryId.chain(getVariantForId)
  const controlVariant = safeProp('control', variantEntryMapping).chain(
    getVariantForId
  )
  const selectedVariant = activeVariant.catchMap(() =>
    variation === '' ? controlVariant : None()
  )

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Variant' is not assignable to pa... Remove this comment to see the full error message
  const renderCustomOrMappedVariant = (variant: Variant) =>
    renderVariant
      ? renderVariant(variant)
      : mapPageComponentToTemplate(variant, pageContext, location)

  return ready ? (
    selectedVariant
      .chain(variant => Maybe.fromNull(renderCustomOrMappedVariant(variant)))
      .orNull()
  ) : (
    <div className="h-32" />
  )
}

export const query = graphql`
  #graphql
  fragment variationContainer on ContentfulVariationContainer {
    id
    internal {
      type
    }
    experimentId
    experimentKey
    experimentTitle
    meta {
      internal {
        content
      }
    }
    # Note: Each fragment included under variations here needs to also request the contentful_id field in order to be mapped to a variation
    variations {
      ... on ContentfulBmsSensors {
        contentful_id
        ...bmsSensors
      }
      ... on ContentfulExpandableMonitoringPlan {
        contentful_id
        ...expandableMonitoringPlanFragment
      }
      ... on ContentfulProductPageHeroV2 {
        contentful_id
        ...productPageHero
      }
      ... on ContentfulSmallTextSection {
        contentful_id
        ...smallTextSectionFragment
      }
      ... on ContentfulTwoColumn {
        contentful_id
        ...contentfulTwoColumnFragment
      }
      ... on ContentfulHeader {
        contentful_id
        ...contentfulHeaderFragment
      }
      ... on ContentfulModalPromoPopup {
        contentful_id
        ...modalPromoPopup
      }
      ... on ContentfulGroupSection {
        contentful_id
        ...nonCyclicalGroupSectionFragment
      }
      ... on ContentfulHeroBanner {
        contentful_id
        ...heroBannerFragment
      }
      ... on ContentfulScoutPlaceholder {
        contentful_id
        ...scoutPlaceholderFragment
      }
      ... on ContentfulDeviceVariations {
        contentful_id
        ...deviceVariationsFragment
      }
      ... on ContentfulBanner {
        contentful_id
        ...contentfulBanner
      }
      ... on ContentfulProductCard {
        contentful_id
        ...productCard
      }
      ... on ContentfulModal {
        contentful_id
        ...modalFragment
      }
      ... on ContentfulContentCollection {
        contentful_id
        ...nonCyclicalContentCollectionFragment
      }
      ... on ContentfulFindYourPerfectSystem {
        contentful_id
        ...aliasedTermsAndConditionsQuoteWizard
      }
      ... on ContentfulGuidedSystemBuilder {
        contentful_id
        jebbitUrl
        type
        internal {
          type
        }
      }
    }
  }

  # Since ContentfulHeroBanner can now contain a variation container, we introduce this fragment
  # to prevent a circular query
  # see https://simplisafe.atlassian.net/browse/ECP-5330
  fragment variationContainerModalButton on ContentfulVariationContainer {
    contentful_id
    id
    internal {
      type
    }
    experimentId
    experimentKey
    experimentTitle
    meta {
      internal {
        content
      }
    }
    # Note: Each fragment included under variations here needs to also request the contentful_id field in order to be mapped to a variation
    variations {
      ... on ContentfulModal {
        contentful_id
        ...modalFragment
      }
      ... on ContentfulButton {
        contentful_id
        ...contentfulButtonFragment
      }
    }
  }
`
