import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const duplexSmallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema
})

export const duplexImageLinkSchema = z.object({
  __typename: z.literal('ContentfulImageLink'),
  image: gatsbyImageSchema
})

export const possibleEmbeddedSchema = z.union([
  duplexSmallTextSchema,
  duplexImageLinkSchema,
  guidedSystemBuilderSchema
])

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12405
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export const duplexSectionSchema = z.object({
  __typename: z.literal('ContentfulDuplex'),
  left: possibleEmbeddedSchema.array(),
  right: possibleEmbeddedSchema.array()
})

export type PossibleEmbeddedComponent = TypeOf<typeof possibleEmbeddedSchema>
export type DuplexSmallText = TypeOf<typeof duplexSmallTextSchema>
export type DuplexImageLink = TypeOf<typeof duplexImageLinkSchema>
export type DuplexSectionSchema = TypeOf<typeof duplexSectionSchema>
