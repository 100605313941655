import { Map as ImmutableMap } from 'immutable'

export const comparisonTableApolloMock = {
  comparisonTable: {
    content: {
      header: { competitors: ['ADT'], titles: ['ADT'], icons: ['ADT'] },
      body: [
        {
          feature:
            'Lifetime warranty and 20% discount on hardware for subscribers*',
          values: [true, false]
        },
        {
          feature: 'No contracts or hidden fees. Cancel anytime.',
          values: [true, false]
        }
      ],
      footer: [
        '* Requires Pro Premium plan.',
        '*** Requires Fast Protect™ Monitoring plan.'
      ]
    }
  }
}
export const videoButtonAssetMockData = {
  videoButtonAsset: {
    url: 'https://images.ctfassets.net/v6awd0kabi65/485NQEr4JUie3FE2IIlZ7B/1e97e3549a5e54728a1bf00595e63e35/image_143_3x.png',
    originalHeight: 294,
    originalWidth: 543,
    description: 'See intruders. Speak to them',
    title: 'Real time agents'
  }
}

export const expertReviewsMock = {
  expertReviews: {
    content: {
      reviews: [
        {
          id: 1,
          key: 'us-news',
          text: '“Best Home Security Systems” 5 Years Running'
        },
        {
          id: 2,
          key: 'cnet',
          text: '“SimpliSafe® is still the king.”'
        },
        {
          id: 3,
          key: 'newsweek',
          text: '“America’s Best Customer Service 2024”'
        }
      ]
    }
  }
}
export const expertSectionAssetMock = {
  expertSectionAsset: {
    url: 'test-image.png',
    originalHeight: 1773,
    originalWidth: 1855,
    description: 'Test Image',
    title: 'Test Image'
  }
}

export const expertReviewsAssetsMock = {
  items: [
    {
      title: 'Test Image',
      description: 'SimpliSafe App Control',
      originalWidth: 1000,
      originalHeight: 1254,
      url: 'test-image.png',
      sys: {
        id: '123'
      }
    }
  ]
}

export const appFeaturesCarouselAssetsMock = {
  items: [
    {
      sys: {
        id: '6LT6dvk157aAfFdKyeG2Kf'
      },
      title: 'App Control - UK Home',
      description: 'SimpliSafe App Control',
      originalWidth: 1000,
      originalHeight: 1254,
      url: 'https://images.ctfassets.net/v6awd0kabi65/6LT6dvk157aAfFdKyeG2Kf/d01c90602d62bcfa3ebcad52d66588e9/App_Screen_art.png'
    }
  ]
}

export const expertReviewsAdditionalMock = {
  content: {
    reviews: [
      {
        id: 1,
        key: 'us-news',
        text: '“Best Home Security Systems” 5 Years Running'
      },
      {
        id: 2,
        key: 'cnet',
        text: '“SimpliSafe® is still the king.”'
      },
      {
        id: 3,
        key: 'newsweek',
        text: '“America’s Best Customer Service 2024”'
      }
    ]
  }
}

export const appWidgetDataMock = {
  appWidget: {
    content: {
      tabs: [
        {
          tab: 'Cameras',
          list: [
            'Crisp, HD video with night vision',
            'Unlimited 30-day cloud storage of recordings*',
            '2-way audio on indoor and outdoor cameras',
            'Smart motion detection instantly alerts you of activity'
          ],
          title: 'Keep watch indoors and out with live camera feeds',
          disclaimer: ['*For up to 10 cameras. Monitoring plan required.']
        },
        {
          tab: 'Controls',
          list: [
            'Arm and disarm from anywhere',
            'Lock and unlock Smart Locks',
            'Check temperature sensors'
          ],
          title: 'Protect your home from anywhere'
        },
        {
          tab: 'tab 1',
          title: 'variant tab 1',
          list: ['item 1']
        }
      ],
      title: 'You’re in control with the SimpliSafe® App'
    }
  }
}
export const cameraBannerAssetMock = {
  cameraBannerAsset: {
    originalWidth: 975,
    originalHeight: 618,
    title: 'Police Camera - HP Banner Image',
    url: 'test.png',
    size: 688620,
    description: ''
  }
}

export const confidenceBarMockData = {
  confidenceBar: {
    content: {
      listItems: [
        {
          icon: 'SupportIcon',
          headline:
            'Professional monitoring under $1/day & your first month free'
        },
        {
          icon: 'Dollar',
          headline: 'No contracts. No cancellation fees. No kidding.'
        },
        {
          icon: 'SecureHomeIcon',
          headline: 'Easy to install yourself or choose optional pro install'
        },
        {
          icon: 'SecureCheckmarkIcon',
          headline: '60-day money-back guarantee with free return shipping'
        }
      ]
    }
  }
}

export const guaranteeAssetMock = {
  guranteeAsset: {
    originalWidth: 392,
    originalHeight: 392,
    title: '60 Day Guarantee',
    url: 'test-image.png',
    description: 'test-descriptio'
  }
}
export const guaranteeCopyMock = ImmutableMap({
  text_1: 'Try it. Test it. Love it or return it.',
  text_2: 'Take our quiz',
  text_3: 'Build a system'
})
