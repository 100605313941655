import { gql } from '@apollo/client/index.js'

export const HERO_BANNER_QUERY = gql`

fragment ColumnQWExperienceFragmentApollo on NtExperience {
    id: sys { id }
    name: ntName
    type: ntType
    audience: ntAudience {
      id: sys { id }
      name: ntName
    }
    config: ntConfig
    variants: ntVariantsCollection(limit: 8) {  # This could be concerning as we get many null/empty variants and might miss some variants coz of limit set to 8 only
      items {
        ... on QuoteWizard {
          ...ColumnQWBaseFragment
        }
      }
    }
  }

  fragment ColumnQWBaseFragment on QuoteWizard {
    __typename
    id: sys {id}
    sys {id} # This is needed for QWs embedded in a rich text
    jebbitUrl
    type
    buttonText
  }

  fragment homeLandingFormFragmentApollo on Form {
    __typename
    contentful_id: sys { id }
    sys {
      id
    }
    formCollection(limit: 5, where: {sys: {id_exists: true}}) {
      items {
        ... on Input {
          label: title
          name: inputName
          type
          placeholder
        }
      }
    }
    button {
      text
    }
  }

  fragment homeLandingHeroBanner on HeroBanner {
      __typename
      id: sys {
        id
      }
      sys {
        id
      }
      contentCollection(limit:5, where: {sys: {id_exists: true}}) {
        items {
          ... on HeroBannerColumn {
          backgroundColor
          description {
            json
            links {
              entries {
                block {
                  ... on QuoteWizard {
                    ...ColumnQWBaseFragment
                    ntExperiencesCollection(limit: 5) {
                      items {
                        ...ColumnQWExperienceFragmentApollo
                      }
                    }
                  }
                }
                inline {
                  ... on YoutubeVideo {
                    sys {
                      id
                    }
                    __typename
                    title
                    link
                    text
                  }
                }
              }
            }
          }
          descriptionMobile {
            json
          }
          image {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageTablet {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageMobile {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          textColor
          textAlignment
          contentVerticalAlignment
          desktopImageAlignToScreenEdge
          linkColor
          hasPromoSticker
          form {
            ... on Form {
              ...homeLandingFormFragmentApollo
            }
          }
        }
        }
      }
      floatingBadge
      hasPromoSticker
      isFullWidth
      heightType
      disclaimerText
      disclaimerTextAlignment
      roundedCorners
    }

    fragment heroBannerExperienceFragment on NtExperience {
      id: sys {id}
      name: ntName
      type: ntType
      audience: ntAudience {
        id: sys {id}
        name: ntName
      }
      config: ntConfig
      variants: ntVariantsCollection(limit: 10) {
        items {
          __typename
          ... on HeroBanner {
            ...homeLandingHeroBanner
          }
          sys {id}
        }
      }
    }

  query	Hero_Banner_Query($id: String!, $preview: Boolean!, $locale: String!) {
    heroBanner(id: $id, locale: $locale, preview: $preview) {
      sys {
        id
      }
      ...homeLandingHeroBanner
      ntExperiencesCollection(limit: 5, where: {sys: {id_exists: true}}) {
        items {
          ...heroBannerExperienceFragment
        }
      }
    }
  }
`
