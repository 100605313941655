import { type TypeOf, z } from '@simplisafe/ewok'

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12405
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export const reviewSectionSchema = z.object({
  headline: z.string().nullish(),
  reviews: z
    .object({
      company: z.string(),
      review: z.object({
        raw: z.string()
      }),
      publishDate: z.string().nullish()
    })
    .array(),
  button: z
    .object({
      text: z.string(),
      url: z.string()
    })
    .optional(),
  __typename: z.literal('ContentfulReviewSection')
})

export type ReviewSectionSchema = TypeOf<typeof reviewSectionSchema>
