import { getPartnerName } from '@ecomm/shared-cookies'
import {
  useNinetailedImpactedEvent,
  usePlanChoiceDynamicIntentPushExperience,
  usePlanChoiceDynamicNonIntentPushExperience,
  usePlanChoiceNonCameraPushExperience
} from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'

import { useOdmonCameraImpactedEvent } from '../../../../hooks/useOdmonCameraImpactedEvent'
import type { OptInOrOutProps } from '../../OptInOrOut/types'
import { OdmonMAPSCoreMonitoring } from '../OdmonMAPSCoreMonitoring'
import { OdmonMAPSProMonitoring } from '../OdmonMAPSProMonitoring'
import {
  OdmonPlanChoiceNonCameraPushDraftCart,
  OdmonPlanChoiceNonCameraPushPackage
} from './OdmonPlanChoiceNonCameraTestContainer'

import { OdmonPlanChoiceDynamicNonIntentPush } from './OdmonPlanChoiceDynamicNonIntentTestContainer'
import { OdmonPlanChoiceDynamicIntentPush } from './OdmonPlanChoiceDynamicIntentTestContainer'
import { OdmonHybridMAPS } from '../OdmonHybridMAPS'
import { MonitoringPlan } from '../../../DraftCart/types'

type OdmonHasOutdoorCameraProp = {
  readonly hasOutdoorCamera: boolean
}
type Props = OdmonHasOutdoorCameraProp &
  OptInOrOutProps & {
    readonly json: { readonly id: string; readonly tag: string }
  }

type OdmonIntentProps = {
  readonly showedOdmonIntent: boolean
  readonly quizBreakIns: boolean
  readonly quizPackageTheft: boolean
}

type PropsWithIntent = OdmonIntentProps & Props

export function OdmonPlanChoiceDraftCart({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  hasOutdoorCamera,
  json,
  priceToggles
}: Props) {
  const partnerName = getPartnerName() || ''
  const isCydcorPartner = partnerName.substring(0, 6) === 'cydcor'

  const shouldShowProInVariant = hasOutdoorCamera && !isCydcorPartner

  const planChoiceNonCameraPushExperience =
    usePlanChoiceNonCameraPushExperience()
  const planChoiceNonCameraPushTestObserver = useOdmonCameraImpactedEvent(
    planChoiceNonCameraPushExperience,
    false
  )

  switch (json?.tag) {
    case 'variant-1':
      return hasOutdoorCamera ? (
        <OdmonHybridMAPS
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
          defaultMonitoringPlan={MonitoringPlan.odmonOvernight}
        />
      ) : (
        <div>
          {planChoiceNonCameraPushTestObserver}
          <Experience
            {...planChoiceNonCameraPushExperience.data}
            component={OdmonPlanChoiceNonCameraPushDraftCart}
            experiences={planChoiceNonCameraPushExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              priceToggles
            }}
          />
        </div>
      )
    case 'control':
    default:
      return shouldShowProInVariant ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
  }
}

export function OdmonPlanChoicePackage({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  hasOutdoorCamera,
  json,
  priceToggles
}: Props) {
  const planChoiceNonCameraPushExperience =
    usePlanChoiceNonCameraPushExperience()

  const planChoiceNonCameraPushTestObserver = useOdmonCameraImpactedEvent(
    planChoiceNonCameraPushExperience,
    false
  )

  switch (json?.tag) {
    case 'variant-1':
      return hasOutdoorCamera ? (
        <OdmonHybridMAPS
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
          defaultMonitoringPlan={MonitoringPlan.odmonOvernight}
        />
      ) : (
        <div>
          {planChoiceNonCameraPushTestObserver}
          <Experience
            {...planChoiceNonCameraPushExperience.data}
            component={OdmonPlanChoiceNonCameraPushPackage}
            experiences={planChoiceNonCameraPushExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              priceToggles
            }}
          />
        </div>
      )
    case 'control':
    default:
      return hasOutdoorCamera ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
  }
}

export function OdmonPlanChoiceDynamic({
  hasOutdoorCamera,
  amountSavedWithServicePlan,
  discountWithServicePlan,
  json,
  priceToggles,
  showedOdmonIntent,
  quizBreakIns,
  quizPackageTheft
}: PropsWithIntent) {
  const planChoiceDynamicNonIntentPushExperience =
    usePlanChoiceDynamicNonIntentPushExperience()
  const planChoiceDynamicIntentPushExperience =
    usePlanChoiceDynamicIntentPushExperience()

  const planChoiceDynamicIntentPushTestObserver = useNinetailedImpactedEvent({
    baseline: planChoiceDynamicIntentPushExperience.baseline,
    experiences: planChoiceDynamicIntentPushExperience.experiences,
    name: 'nt_impacted_event',
    event: {
      hasOutdoorCamera,
      showedOdmonIntent,
      quizBreakIns,
      quizPackageTheft
    },
    shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
  })

  const planChoiceDynamicNonIntentPushTestObserver = useNinetailedImpactedEvent(
    {
      baseline: planChoiceDynamicNonIntentPushExperience.baseline,
      experiences: planChoiceDynamicNonIntentPushExperience.experiences,
      name: 'nt_impacted_event',
      event: {
        hasOutdoorCamera,
        showedOdmonIntent,
        quizBreakIns,
        quizPackageTheft
      },
      shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
    }
  )

  switch (json?.tag) {
    case 'variant-1':
      return showedOdmonIntent ? (
        <div>
          {planChoiceDynamicIntentPushTestObserver}
          <Experience
            {...planChoiceDynamicIntentPushExperience.data}
            component={OdmonPlanChoiceDynamicIntentPush}
            experiences={planChoiceDynamicIntentPushExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              priceToggles
            }}
          />
        </div>
      ) : (
        <div>
          {planChoiceDynamicNonIntentPushTestObserver}
          <Experience
            {...planChoiceDynamicNonIntentPushExperience.data}
            component={OdmonPlanChoiceDynamicNonIntentPush}
            experiences={planChoiceDynamicNonIntentPushExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              priceToggles
            }}
          />
        </div>
      )
    case 'control':
    default:
      return showedOdmonIntent ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
  }
}
