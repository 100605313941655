import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  type HeaderRedesignSchema,
  Breadcrumbs,
  Header,
  ProgressBarHeader,
  useHeaderRedesignQuery
} from '@ecomm/header'
import {
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import classNames from 'classnames'
import { type PageProps, graphql } from 'gatsby'

import ContentMapper from '../../components/ContentMapper'
import type { SeoNodeSchema } from '../../config/responseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useBreadcrumbTitleODMONOverride } from '../../hooks/useBreadcrumbTitleODMONOverride'
import { useFeaturesAlarmPageFragment } from './useFeaturesAlarmPageFragment'
import { MonitoringTemplate } from '@ecomm/monitoring/components'
import { MonitoringHeroBanner } from '../../components/MonitoringHeroBanner'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
}

type Props = Partial<PageProps> & {
  readonly data: {
    readonly contentfulMonitoringPage: unknown
  }
  readonly pageContext: PageContext
}

const renderTrustpilotUKTopBanner = (
  locale: string,
  isChooseMonitoringPage: boolean
) => {
  return locale === 'en-GB' && !isChooseMonitoringPage ? (
    <TrustpilotUKTopBanner className="mt-6 md:mt-8 lg:mt-10" />
  ) : null
}

const renderHeader = (
  isChooseMonitoringPage: boolean,
  headerData: HeaderRedesignSchema
) => {
  return isChooseMonitoringPage ? (
    <ProgressBarHeader stepVal={1} /> // 1 is harcoded for now on the basis of progressBarList in ProgressBarHeader component
  ) : (
    <Header {...headerData} />
  )
}

export default function FeaturesAlarmTemplate({
  data,
  pageContext: { seoDetails, locale }
}: Props) {
  const { layout, content, slug, breadcrumbTitle } =
    useFeaturesAlarmPageFragment(data)
  const {
    footer: { contentful_id: footerId },
    components,
    promoBannerStyle
  } = layout
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const headerData = useHeaderRedesignQuery()
  const breadcrumbData = useBreadcrumbTitleODMONOverride(slug, breadcrumbTitle)

  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  const heroBannerData = components.find(
    component => component.__typename === 'ContentfulHeroBanner'
  )

  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const isChooseMonitoringPage = ['choose-monitoring2'].includes(slug)

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <MonitoringTemplate>
        {{
          Header: renderHeader(isChooseMonitoringPage, headerData),
          PromoBanner: !isChooseMonitoringPage ? (
            <div className="relative lg:z-20">
              <PromoBannerWrapper
                experimentData={promoBannerExperiment}
                type={bannerStyle}
              />
            </div>
          ) : null,
          Content: (
            <>
              {breadcrumbData ? (
                <Breadcrumbs
                  steps={[
                    {
                      label: breadcrumbData,
                      slug
                    }
                  ]}
                />
              ) : null}
              <main
                className={classNames(
                  'max-w-8xl mx-auto',
                  breadcrumbTitle && 'mt-3 lg:mt-0'
                )}
                id="content"
              >
                {heroBannerData ? (
                  <MonitoringHeroBanner
                    heroBannerData={heroBannerData}
                    locale={locale}
                  />
                ) : null}
                {renderTrustpilotUKTopBanner(locale, isChooseMonitoringPage)}
                <ContentMapper inside={content} />
              </main>
            </>
          ),
          Footer: <Footer footerId={footerId} type="Full" />
        }}
      </MonitoringTemplate>
    </TrackingProvider>
  )
}

export const query = graphql`
  query FeaturesAlarmTemplateQuery($id: String) {
    contentfulMonitoringPage(id: { eq: $id }) {
      ...alarmMonitoringPageFragment
    }
  }
`
