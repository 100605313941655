import type { AppWidgetData, AppWidgetTab } from '../../'

const imageCarousel1 = {
  description: 'Carousel Image - Live Feed',
  title: 'Carousel Image - Live Feed',
  originalWidth: 1000,
  originalHeight: 1254,
  url: 'https://images.ctfassets.net/br4ichkdqihc/17MBm73hOXOEvW1W0KOaFJ/a525e8ab8dfdf8640a79b25c6b600f18/image__9_.png',
  sys: {
    id: ''
  }
}

const imageCarousel2 = {
  description: 'Carousel Image - Alerts',
  title: 'Carousel Image - Alerts',
  originalWidth: 1000,
  originalHeight: 1254,
  url: 'https://images.ctfassets.net/br4ichkdqihc/1pFPd7TTSUi9Etgg9J2PWR/479bf099d3fc298a9a35e8e3811a2f0d/image__10_.png',
  sys: {
    id: ''
  }
}

const imageCarousel3 = {
  description: 'Carousel Image - Motion',
  title: 'Carousel Image - Motion',
  originalWidth: 1000,
  originalHeight: 1254,
  url: 'https://images.ctfassets.net/br4ichkdqihc/3hl66TGxQW2uKyTUYLNw1m/9f2bfdd48172278d02cbdd4d5552df24/image__11_.png',
  sys: {
    id: ''
  }
}

const imageCarousel4 = {
  description: 'Carousel Image - Settings',
  title: 'Carousel Image - Settings',
  originalWidth: 1000,
  originalHeight: 1254,
  url: 'https://images.ctfassets.net/br4ichkdqihc/5xKzzKRVllB371SyoMA4Nu/a222186100e09f4adcbd051a4832baeb/image__12_.png',
  sys: {
    id: ''
  }
}

const tabs: ReadonlyArray<AppWidgetTab> = [
  {
    tab: 'Live Feed',
    title: '24/7 indoor protection',
    description: 'View live footage in crisp HD video, day or night.',
    list: [
      'Footage is clear even in the dark with night vision.',
      'Check in on pets at any time of day.'
    ],
    image: imageCarousel1
  },
  {
    tab: 'Alerts',
    title: 'Receive alerts and monitor camera activity',
    list: [
      'Know exactly when alarms are triggered.',
      'Watch video recordings when motion is detected.*',
      'Click through to your live feed on the alarm notification to use the two way audio and deter intruders.'
    ],
    image: imageCarousel2,
    disclaimer: [
      '*Requires Pro Premium Monitoring plan.',
      '**Simplisafe® will also attempt to call you, depending on the situtation.'
    ]
  },
  {
    tab: 'Motion',
    title: 'Fine-tune motion settings to reduce false alarms',
    list: [
      'Configure custom Activity Zones to ignore areas with expected motion, such as ceiling fans, TVs or hanging plants.',
      'You won’t receive alerts for areas you set to ignore.',
      'Adjust motion sensitivity, motion type and alarm trigger (All Motion or People Only).'
    ],
    image: imageCarousel3
  },
  {
    tab: 'Settings',
    title: 'Configure your camera settings for ultimate control',
    list: [
      'Set camera behavior for Off, Home and Away modes, including when the privacy shutter is open/closed.',
      'Select video quality and night vision options.',
      'Toggle audio and siren on/off.',
      'Customize notifications.',
      'Check battery life.'
    ],
    image: imageCarousel4
  }
]

const data: AppWidgetData = { tabs }

export { data }
