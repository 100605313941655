import { Accordion, ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'

import type { Output as ComponentProps } from '../../hooks/useLocalizedData'
import type { schema } from './schema'
import { useOnFaqExpand } from './useOnFaqExpand'
import { trackFAQExpand } from '@ecomm/cdp-tracking'

type FAQsProps = ComponentProps<typeof schema>

export function FAQs({ data }: { readonly data: FAQsProps }) {
  const { title, questions } = data
  const trackGTMFAQExpand = useOnFaqExpand()

  const handleToggle = (number: number, isOpen: boolean) => {
    trackGTMFAQExpand(number, isOpen)
    isOpen && trackFAQExpand(number)
  }

  return (
    <div data-component="ScoutFAQs">
      {title ? (
        <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
          <div className="text-center text-4xl font-bold">
            <ContentfulRichText raw={title} />
          </div>
        </div>
      ) : null}
      {questions.map((question, idx) => {
        return (
          <div className="my-4" key={idx}>
            <Accordion
              {...question}
              onToggle={isOpen => handleToggle(idx + 1, isOpen)}
            />
          </div>
        )
      })}
    </div>
  )
}
