import { gql } from '@apollo/client/index.js'

export const USER_REVIEWS_QUERY = gql`query userReviewsQuery($locale : String! $preview : Boolean!){
  userReviewsData: json(
    id: "5S5vhfcSNQqBcrZZhwVXWO",
    locale : $locale,
    preview : $preview,
  ) {
    content
  }
}`
