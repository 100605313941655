import React from 'react'

type YoutubeVideoProps = {
  readonly link: string
  readonly title: string
}

export function YoutubeVideo({ link, title }: YoutubeVideoProps) {
  return (
    <div
      className="relative h-0 pt-[56.25%]"
      style={{
        maxWidth: '100%',
        width: '720px'
      }}
    >
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="rounded-base absolute left-0 top-0 h-full w-full overflow-hidden"
        frameBorder="0"
        src={link}
        style={{
          height: '480px',
          maxHeight: '100%',
          maxWidth: '100%',
          width: '720px'
        }}
        title={title}
      ></iframe>
    </div>
  )
}
