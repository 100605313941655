import { z } from '@simplisafe/ewok'
export const userReviewsSchema = z.object({
  userReviewsData: z.object({
    content: z.object({
      reviews: z
        .array(
          z.object({
            name: z.string(),
            text: z.string()
          })
        )
        .readonly()
    })
  })
})
