import {
  type RenderNode,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { type V2HeroBannerSchema as HeroBannerSchema } from '@ecomm/shared-components'
import classNames from 'classnames'

//This component is an adapted version of the scout's HeroBanner that uses content-type HeroBanner to render the content.
//TODO: Create a content type for this new version or adapt the existing HeroBanner so it renders similar
export function HeroBanner({
  contentCollection,
  disclaimerText
}: HeroBannerSchema) {
  const {
    description,
    descriptionMobile,
    image,
    imageTablet,
    imageMobile,
    textColor,
    logo
  } = contentCollection.items[0]

  // Moved descriptionCustomOptions in order to handle textColor variable
  const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <p
          className={classNames('w-[74%] text-xl leading-7', {
            'text-neutral-black': textColor === 'neutralBlack',
            'text-white': textColor === 'neutralWhite'
          })}
        >
          {children}
        </p>
      ),
      [BLOCKS.HEADING_1]: (_, children) => (
        <h1
          className={classNames('leading-12 mb-9 mt-6 text-7xl font-medium', {
            'text-neutral-black': textColor === 'neutralBlack',
            'text-white': textColor === 'neutralWhite'
          })}
        >
          {children}
        </h1>
      )
    }
  }

  return (
    <section
      className="relative w-full lg:px-8 lg:pt-8"
      data-testid="HeroBanner"
    >
      <div className="max-w-content lg:rounded-base relative mx-auto w-full lg:overflow-hidden">
        {image ? (
          <ContentfulImage
            className="hidden lg:block"
            {...image}
            width={1302}
          />
        ) : null}
        {imageTablet ? (
          <ContentfulImage
            className="hidden md:block md:h-[542px] lg:hidden"
            {...imageTablet}
            width={1302}
          />
        ) : null}
        {imageMobile ? (
          <ContentfulImage
            className="block h-72 md:hidden"
            {...imageMobile}
            width={767}
          />
        ) : null}
        <div className="absolute top-0 hidden h-full w-full md:block">
          <div className="relative mx-auto grid h-full max-w-screen-xl grid-cols-12 px-10 py-4 ">
            <div className="absolute bottom-4 right-8 text-right">
              {logo ? (
                <ContentfulImage
                  classNameOverride="hidden h-full w-3/5 md:inline-block"
                  {...logo}
                />
              ) : null}
            </div>
            <div className="place-self-center md:col-span-9 lg:col-span-6">
              {disclaimerText ? (
                <span className="bg-primary-100 rounded-full px-3 py-1 font-light uppercase text-black">
                  {disclaimerText}
                </span>
              ) : null}
              {documentToReactComponents(
                description.json,
                descriptionCustomOptions
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="prose-h1:text-center prose-h1:my-6 prose-h1:text-heading-2-size prose-h1:leading-h2-height prose-h1:mb-6 prose-h1:md:mb-8 prose-h1:text-neutral-black md:hidden">
        {descriptionMobile?.json
          ? documentToReactComponents(
              descriptionMobile.json,
              descriptionCustomOptions
            )
          : null}
      </div>
    </section>
  )
}
