import { parseContentfulJson } from '@ecomm/micro-copy'
import { graphql, useStaticQuery } from 'gatsby'

import { subHeroTextSchema } from '@ecomm/home-landing-data'

export function SubHeroText() {
  const data = subHeroTextSchema.parse(
    useStaticQuery(graphql`
      #graphql
      query PrivacySubHeroText {
        copy: contentfulJson(contentful_id: { eq: "4uxxPPYtoJikwaxIu7RZed" }) {
          ...json
        }
      }
    `)
  )
  const copy = parseContentfulJson(data, 'copy')

  return (
    <div className="text-center" data-component="SubHeroText">
      <p className="px-9">{copy.get('text')}</p>
      <hr className="mt-12" />
    </div>
  )
}
