import { graphql, useStaticQuery } from 'gatsby'
import { odmonMediaPageSchema } from '@ecomm/home-landing-data'
import {
  useConfidenceBarQuery,
  useExpertReviewsAssetQuery,
  useExpertSectionAssetQuery,
  useQuoteWizardAssetQuery
} from '@ecomm/lander'
import { useMonitoringPageAccoladesData } from '@ecomm/scout'

const odmonMediaPageQuery = graphql`
  query OdmonPageQuery {
    expertReviewsAssets: allContentfulAsset(
      filter: {
        contentful_id: {
          in: ["6uVhRdfLw8y6L3x78SiCuM", "6Fk167pQyK44F3g51qYJ5U", "5X5OsfxAW6Fc2Zj3HZDlHC", "7eH4KhA049wM5ZTOOmWyWd", "3DTUGIquyV64OFfWua1sqK"]
        }
      }
    ) {
      nodes {
        contentful_id
        gatsbyImageData(width: 320)
        description
      }
    }
    quizImage: contentfulAsset(
      contentful_id: { eq: "7bO64u6XmUiU9HojJJEE59" }
    ) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
      description
    }
    theLighthouseImage: contentfulAsset(
      contentful_id: { eq: "1QeaQoU0gMUut5yzmcCUFM" }
    ) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
      description
    }
    theBeaconImage: contentfulAsset(
      contentful_id: { eq: "PCWgU0BaVnG3Htfi31nmH" }
    ) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
      description
    }
    expertSectionAsset: contentfulAsset(contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }) {
      gatsbyImageData(placeholder: BLURRED)
    }
    quoteWizardAsset: contentfulAsset(contentful_id: { eq: "1Cm3jebuXKFPPMEicZlUk6" }) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      description
    }

    expertReviews: contentfulJson(contentful_id: { eq: "2143Uo0jib8jsfRyWv2DDQ" }) {
      ...json
    }
    userReviews: contentfulJson(contentful_id: { eq: "5c0ifXe6betVaeMrA88fTy" }) {
      ...json
    }
    videoBanner: contentfulJson(contentful_id: { eq: "6ja49cHPwu6khB1CEIwkmg" }) {
      ...json
    }
    quoteWizard: contentfulQuoteWizard(contentful_id: { eq: "3GT1zG3PE5djEurbN7NQmm" }) {
      ...homepageQWBaseFragment
      nt_experiences {
        ...homepageQWExperienceFragment
      }
    }
    featureOverview: contentfulTwoColumnImageSection(contentful_id: { eq: "1zjktbmxB9PqFwOXcmhrOj" }) {
      id
      __typename
      leftSideImage {
        gatsbyImageData
      }
      rightSideImage {
        gatsbyImageData
      }
      variant
    }
  }
`

const useOdmonMediaPageComponents = () => {
  const queryData = odmonMediaPageSchema.parse(
    useStaticQuery(odmonMediaPageQuery)
  )
  const confidenceBarData = useConfidenceBarQuery('5Xku7mN4z70gxBfEMKf66n')
  const monitoringPageAccoladesData = useMonitoringPageAccoladesData()
  const expertReviewsAssets = useExpertReviewsAssetQuery([
    '6uVhRdfLw8y6L3x78SiCuM',
    '6Fk167pQyK44F3g51qYJ5U',
    '5X5OsfxAW6Fc2Zj3HZDlHC',
    '7eH4KhA049wM5ZTOOmWyWd',
    '3DTUGIquyV64OFfWua1sqK'
  ])

  const expertSectionAsset = useExpertSectionAssetQuery()
  const quoteWizardAssetApollo = useQuoteWizardAssetQuery(
    '1Cm3jebuXKFPPMEicZlUk6'
  )

  return {
    ...queryData,
    confidenceBarData,
    monitoringPageAccoladesData,
    expertReviewsAssets,
    expertSectionAsset,
    quoteWizardAssetApollo
  }
}

export default useOdmonMediaPageComponents
