import { gql } from '@apollo/client/index.js'

export const QUOTE_WIZARD_ASSET_QUERY = gql`
  query quoteWizzardAsset($id : String! $locale : String! $preview : Boolean!) {
    quoteWizardAsset: asset(id:  $id, locale : $locale, preview : $preview) {
      originalWidth : width
      originalHeight : height
      title
      url
      size
      description
    }
  }
`
