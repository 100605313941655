import { Identify } from '@ninetailed/experience.js'
import { useCallback, useState } from 'react'

import { useNinetailed } from './useNinetailed'

/**
 * Ninetailed util hook used to make identify calls. This hook is designed to
 * only fire once to avoid companent useEffect hooks from repeatedly hitting the API.
 */
export function useIdentify() {
  const [called, setCalled] = useState(false)
  const { identify } = useNinetailed()

  return useCallback(
    (traits: Parameters<Identify>[1]) => {
      const fn = () => {
        setCalled(true)
        identify('', traits)
      }

      !called && fn()
    },
    [called, identify]
  )
}
