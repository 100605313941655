import { Experience } from '@ecomm/shared-ninetailed-experience'
import {
  type V2HeroBannerSchema as HeroBannerSchema,
  V2HeroBanner as HeroBanner
} from '@ecomm/shared-components'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { mapApolloExperiences } from '@ecomm/shared-ninetailed'
import { useMediaQuery } from '@ecomm/shared-hooks'

import { useHeroBannerQuery } from '../../hooks/useHeroBannerQuery'

type HeroBannerProps = HeroBannerSchema & {
  readonly extraContent?: React.ReactNode
  gatsbyHeroBannerDataContentfulId: string
}

export function HeroBannerTestContainer(props: HeroBannerProps) {
  const isDesktop = useMediaQuery('DesktopAndUp')
  const { gatsbyHeroBannerDataContentfulId, ...heroBannerProps } = props
  const isOdmonVariant = useOdmonExperience().isVariant
  const nestedHeroBannerData = useHeroBannerQuery('4MiGTqTgEN15rSWljwhpKU')

  const nestedMappedExperiences = mapApolloExperiences<HeroBannerSchema>(
    nestedHeroBannerData.ntExperiencesCollection?.items
  )

  return (
    <div>
      {/* Only replace the variant arm with the nested experiment in those cases where we
        would be replacing the hero banner in the first place. This is the id of the baseline. */}
      {isOdmonVariant &&
      gatsbyHeroBannerDataContentfulId === '41zon7oHfcvnkkfRF4wB4z' ? (
        <Experience
          {...nestedHeroBannerData}
          component={HeroBanner}
          experiences={nestedMappedExperiences}
          key={nestedHeroBannerData.id}
          passthroughProps={{
            ...nestedHeroBannerData,
            floatingBadgeDiscount: heroBannerProps.floatingBadgeDiscount,
            heroStickerImage: heroBannerProps.heroStickerImage,
            isFullWidth: isDesktop ? nestedHeroBannerData.isFullWidth : true
          }}
        />
      ) : (
        <HeroBanner {...heroBannerProps} />
      )}
    </div>
  )
}
