import { LoadingSpinner } from '@ecomm/checkout-icons'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { useEffect } from 'react'
import { match } from 'ts-pattern'

import type { PaymentState } from '../ZuoraPaymentForm/ZuoraPaymentForm'

export type PaymentFormBannerProps = {
  readonly paymentState: PaymentState
}

/**
 * Loading any one of the Loader/Error/Payment Form section based on the state.
 * Adding empty element inside BannerLoading for mandatory children.
 */
export function PaymentFormBanner({ paymentState }: PaymentFormBannerProps) {
  const microCopy = useMicroCopy()
  const trackMetricEvent = useTrackMetricEvent()

  useEffect(() => {
    paymentState === 'error' && trackMetricEvent('payment-form-error')
  }, [paymentState, trackMetricEvent])

  return match(paymentState)
    .with('processing', () => (
      <div
        className="grid grid-cols-12 gap-4 h-full min-h-[350px] md:min-h-[400px] lg:min-h-[600px] py-8 px-4 md:py-8"
        data-testid="BannerLoading_paymentProcessing"
      >
        <div className="justify-self-center col-span-12 relative max-w-full">
          <div className="text-center">
            <h3>Please wait while your payment is processing.</h3>
            <LoadingSpinner
              className="block my-8 mx-auto"
              fillColor="var(--primary-100)"
            />
          </div>
        </div>
      </div>
    ))
    .with('complete', () => (
      <div
        className="grid grid-cols-12 gap-4 h-full min-h-[350px] md:min-h-[400px] lg:min-h-[600px] py-8 px-4 md:py-8"
        data-testid="BannerLoading_paymentComplete"
      >
        <div className="justify-self-center col-span-12 relative max-w-full">
          <div className="text-center">
            <h3>Thanks! Please wait while we finalize your order.</h3>
            <LoadingSpinner
              className="block my-8 mx-auto"
              fillColor="var(--primary-100)"
            />
          </div>
        </div>
      </div>
    ))
    .with('error', () => (
      <div
        className="grid grid-cols-12 gap-4 h-full min-h-[350px] md:min-h-[400px] lg:min-h-[600px] py-8 px-4 md:py-8"
        data-testid="BannerLoading_paymentError"
      >
        <div className="justify-self-center col-span-12 relative max-w-full">
          <div className="text-center">
            <div className="rounded-base overflow-hidden px-4 py-8 md:py-8">
              <h3>Something isn&apos;t right. Try reloading the page.</h3>
              <p>{microCopy['if-the-issue-persists']}</p>
            </div>
          </div>
        </div>
      </div>
    ))
    .with('loading', () => null)
    .with('ready', () => null)
    .exhaustive()
}
