import { contentfulImageSchema } from '@ecomm/contentful/components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const appWidgetDataSchema = z.object({
  appWidget: z.object({
    content: z.object({
      tabs: z.array(
        z.object({
          tab: z.string(),
          title: z.string(),
          description: z.string().optional(),
          list: z.array(z.string()),
          disclaimer: z.array(z.string()).optional()
        })
      )
    })
  })
})

export const appFeaturesCarouselAssetsSchema = z.object({
  items: z.array(
    contentfulImageSchema.extend({
      sys: z.object({
        id: z.string()
      })
    })
  )
})

export type AppWidgetType = TypeOf<typeof appWidgetDataSchema>
