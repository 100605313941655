import { useMicroCopy } from '@ecomm/micro-copy'
import { useFormStyles, useHandleFormSubmission } from '@ecomm/promotions-hooks'
import { leadingSlashIt } from '@simplisafe/ewok'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { Link } from '@ecomm/framework'
import { useTracking } from 'react-tracking'

import { PromoButton } from '../PromoButton'
import { EmailFormSchema } from './schema'

export function LeadCaptureForm() {
  const microCopy = useMicroCopy()
  const { defaultEmail, handleEmailSubmit } = useHandleFormSubmission()
  const { textStyles } = useFormStyles()
  const { trackEvent } = useTracking()

  const onClickPrivacyPolicy = () => {
    trackEvent({ event: 'promo_privacy_policy' })
  }

  const onClickPromoButton = () => {
    trackEvent({ event: 'submitLead' })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ email: defaultEmail }}
      onSubmit={(values, formikBag) => {
        handleEmailSubmit(values.email, errorText =>
          formikBag.setErrors({ email: errorText })
        )
      }}
      validationSchema={EmailFormSchema}
    >
      {({ isSubmitting, errors, values }) => (
        <Form data-component="LeadCaptureForm">
          <div className="flex items-end gap-2">
            <div className="flex w-full flex-col gap-1">
              <label
                className={`font-medium ${textStyles} max-lg:text-neutral-black`}
                htmlFor="lead-email"
              >
                Email address
              </label>
              <Field
                className={classNames(
                  'border-neutral-medium-100 h-[51px] w-full rounded-xl border border-solid pl-5 text-[16px]',
                  {
                    'text-sale': values.email && errors.email,
                    'border-sale': values.email && errors.email
                  }
                )}
                id="lead-email"
                name="email"
                placeholder={'Enter your email'}
                type="email"
              />
            </div>
            <PromoButton
              isSubmitting={isSubmitting}
              onClick={onClickPromoButton}
              text={microCopy['claim-offer']}
            />
          </div>
          <p className="text-sale m-0 min-h-[20px] p-0 pl-1 pt-1 text-xs">
            {values.email ? errors.email : null}
          </p>
          <p
            className={`max-lg:text-neutral-black m-0 p-0 text-xs font-thin max-lg:text-center ${textStyles}`}
          >
            You may receive email offers from us. Read{' '}
            <Link
              className={`max-lg:text-neutral-black font-semibold ${textStyles}`}
              onClick={onClickPrivacyPolicy}
              to={leadingSlashIt('/legal/privacy-policy')}
            >
              Privacy Policy
            </Link>
            .
          </p>
        </Form>
      )}
    </Formik>
  )
}
