import {
  landerBelowTheFoldSchema,
  useAppFeaturesCarouselAssetsQuery,
  useExpertReviewsAssetQuery
} from '@ecomm/lander'
import { graphql, useStaticQuery } from 'gatsby'

const partnerPageQuery = graphql`
query PartnerPageBodyQuery {
  videoButtonAsset: contentfulAsset(
    contentful_id: { eq: "485NQEr4JUie3FE2IIlZ7B" }
  ) {
    gatsbyImageData(placeholder: BLURRED)
    description
    title
  }
  contentfulAffirmBanner(
    contentful_id: { eq: "4VmV1UBXepTJFhRn8xKvni" }
  ) {
    description {
      raw
    }
    title
    internal {
      type
    }
  }
  quoteWizardAsset: contentfulAsset(
    contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }
  ) {
    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    description
  }
  cameraBannerAsset: contentfulAsset(
    contentful_id: { eq: "5mbs4LEkQgjIZpoFklL2ur" }
  ) {
    gatsbyImageData(placeholder: BLURRED)
  }
  appFeaturesCarouselAssets: allContentfulAsset(
    filter: {
      contentful_id: {
        in: [
          "6rmX3DElVdPZn4Hw3JNqV9"
          "50wlqz44dQwAzOoxzmPO90"
          "38iheGuzSVg8kPQ1aokAnw"
          "ZYB8WxMtOe454lse5FG2D"
        ]
      }
    }
  ) {
    nodes {
      contentful_id
      id
      title
      description
      gatsbyImageData(placeholder: BLURRED)
    }
  }
  userReviewsData: contentfulJson(
    contentful_id: { eq: "5S5vhfcSNQqBcrZZhwVXWO" }
  ) {
    ...json
  }
  confidenceBar: contentfulJson(
    contentful_id: { eq: "5ynnx5XFY9LYRUyZEkN98K" }
  ) {
    ...json
  }
  appWidget: contentfulJson(
    contentful_id: { eq: "5cJhxXLT1ybrC7lgAQItHU" }
  ) {
    ...json
  }
  expertReviews: contentfulJson(
    contentful_id: { eq: "2143Uo0jib8jsfRyWv2DDQ" }
  ) {
    ...json
  }
  expertReviewsAssets: allContentfulAsset(
    filter: {
      contentful_id: {
        in: [
          "6uVhRdfLw8y6L3x78SiCuM"
          "6Fk167pQyK44F3g51qYJ5U"
          "5X5OsfxAW6Fc2Zj3HZDlHC"
          "7eH4KhA049wM5ZTOOmWyWd"
          "3DTUGIquyV64OFfWua1sqK"
        ]
      }
    }
  ) {
    nodes {
      contentful_id
      gatsbyImageData(width: 320)
      description
    }
  }
  guaranteeAsset: contentfulAsset(
    contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
  ) {
    gatsbyImageData(placeholder: BLURRED)
    description
  }
  quoteWizard: contentfulQuoteWizard(
    contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
  ) {
    ...partnerBodyQWBaseFragment
    nt_experiences {
      ...partnerBodyQWExperienceFragment
    }
  }
  guaranteeCopy: contentfulJson(
    contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
  ) {
    ...json
  }
  expertSectionAsset: contentfulAsset(
    contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }
  ) {
    gatsbyImageData(placeholder: BLURRED)
  }
  comparisonTable: contentfulJson(
    contentful_id: { eq: "1zTyewofJ6lKWySS451GBg" }
  ) {
    ...json
  }
  comparisonTableOdmonVariant: contentfulJson(
    contentful_id: { eq: "18qXWQrIu5vaqsUScRFNeI" }
  ) {
    ...json
  }
  ukAlarmMoments: contentfulAlarmMoments(contentful_id: { eq: "5CZQz1gM66nNlwBekDnig2" }) {
    ...partnersAlarmMoments
  }
}
`

const usePartnerPageBodyQuery = () => {
  const expertReviewsAssets = useExpertReviewsAssetQuery([
    '6uVhRdfLw8y6L3x78SiCuM',
    '6Fk167pQyK44F3g51qYJ5U',
    '5X5OsfxAW6Fc2Zj3HZDlHC',
    '7eH4KhA049wM5ZTOOmWyWd',
    '3DTUGIquyV64OFfWua1sqK'
  ])

  const appFeaturesCarouselAssets = useAppFeaturesCarouselAssetsQuery([
    '6rmX3DElVdPZn4Hw3JNqV9',
    '50wlqz44dQwAzOoxzmPO90',
    '38iheGuzSVg8kPQ1aokAnw',
    'ZYB8WxMtOe454lse5FG2D'
  ])
  const staticData = useStaticQuery(partnerPageQuery) || {}

  return landerBelowTheFoldSchema.parse({
    ...staticData,
    expertReviewsAssets,
    appFeaturesCarouselAssets
  })
}

export { usePartnerPageBodyQuery }
