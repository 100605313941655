import { Experience, mapExperiences } from '@ecomm/shared-ninetailed'
import React from 'react'

import ProductCard from '../ProductCard'
import type { ProductCardExperimentFragment } from '../ProductCard/schema'

type Props = {
  readonly items: readonly ProductCardExperimentFragment[]
  readonly isActiveAccordion: boolean
  readonly isAffirmExperience: boolean
}

function OdmonProductCardsList({
  items,
  isActiveAccordion,
  isAffirmExperience
}: Props) {
  return (
    <div className={'mt-4 grid gap-2 md:mt-8 md:grid-cols-2 lg:grid-cols-3'}>
      {items.map(
        (item, idx) =>
          isActiveAccordion && (
            <Experience
              key={`${idx}-${item.sku}`}
              {...item}
              component={ProductCard}
              experiences={mapExperiences<ProductCardExperimentFragment>(
                item.nt_experiences.map(exp => ({
                  ...exp,
                  variants: exp.variants.map(variant => ({
                    ...variant,
                    tooltipEvent: 'bms_tooltip'
                  }))
                }))
              )}
              passthroughProps={{
                isAffirmExperience: isAffirmExperience,
                type: 'BMS',
                tooltipEvent: 'bms_tooltip'
              }}
            />
          )
      )}
    </div>
  )
}

export default OdmonProductCardsList
