import { Footer } from '@ecomm/footer-components'
import {
  LegalContent,
  LegalRichText,
  LegalTemplate
} from '@ecomm/legal-components'
import { LEGAL_TEMPLATE_QUERY, legalTextPageSchema } from '@ecomm/legal-data'
import { PromoBannerWrapper } from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { useContentful } from '@ecomm/shared-apollo'
import { toPromoBannerStyleValue } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { getNonEmptyHead, z, type Locale } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import { type PageProps, graphql } from 'gatsby'
import { Suspense } from 'react'

import type { LegalResponseNode } from '../config/legalResponseSchema'
import { usePromoBannerExperimentQuery } from '../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useLegalLayout } from '../hooks/useLegalLayout'
import { useFooterQuery } from '@ecomm/footer-data'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: LegalResponseNode['seoDetails']
  readonly slug: LegalResponseNode['slug']
}

type Props = Partial<PageProps> & {
  readonly data: {
    readonly contentfulTextPage: {
      readonly slug: string
      readonly layout?: {
        readonly contentful_id?: string
      }
    }
  }
  readonly pageContext: PageContext
}

const legalCollectionSchema = z.object({
  textPageCollection: z.object({
    items: z.nonEmptyArray(legalTextPageSchema)
  })
})

function LegalHeader() {
  const headerData = useHeaderRedesignQuery()
  return <Header {...headerData} />
}

function LegalPromoBanner({
  promoBannerStyle
}: { readonly promoBannerStyle: string }) {
  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const promoType = toPromoBannerStyleValue(promoBannerStyle) ?? 'none'

  return (
    <PromoBannerWrapper
      experimentData={promoBannerExperiment}
      type={promoType}
    />
  )
}

const useTextPageQuery = (slug: string) => {
  const { data } = useContentful(LEGAL_TEMPLATE_QUERY, {
    slug
  })
  return pipe(
    legalCollectionSchema.parse(data, {
      path: ['legal template', 'useTextPageQuery']
    }).textPageCollection.items,
    getNonEmptyHead
  )
}

export function Content({ data, pageContext: { locale } }: Props) {
  const { footer, promoBannerStyle } = useLegalLayout(
    data.contentfulTextPage.layout?.contentful_id
  )
  const footerData = useFooterQuery(footer.id)

  const slug = data.contentfulTextPage.slug

  const textPage = useTextPageQuery(slug)

  const { displayContentOnly } = textPage

  return (
    <>
      <div className="prose md:prose-md lg:prose-lg prose-h2:mb-4 prose-h2:md:mb-5 whitespace-pre-line">
        {displayContentOnly ? (
          <main
            className="max-w-8xl mx-auto mb-16 flex flex-col p-4 md:p-8"
            id="content"
          >
            <LegalRichText text={textPage.text} />
          </main>
        ) : (
          <LegalTemplate>
            {{
              PromoBanner: (
                <LegalPromoBanner promoBannerStyle={promoBannerStyle} />
              ),
              Header: <LegalHeader />,
              Footer: <Footer {...footerData} />,
              Content: <LegalContent locale={locale} {...textPage} />
            }}
          </LegalTemplate>
        )}
      </div>
    </>
  )
}

export default function LegalPageTemplate(props: Props) {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = props.pageContext.seoDetails
  return (
    <TrackingProvider metaTitle={'legal'}>
      <SEO
        canonicalLink={canonicalLink}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={props.pageContext.locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords}
        metaTitle={metaTitle}
      />
      <Suspense fallback={null}>
        <Content {...props} />
      </Suspense>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query LegalQuery($id: String) {
    contentfulTextPage(id: { eq: $id }) {
      slug
      layout {
        contentful_id
      }
    }
  }
`
