import { CarouselSchema } from '@ecomm/partners-data'
import { FloorPlanCarousel } from '@ecomm/shared-components'

export function FloorPlanCarouselWrapper(props: CarouselSchema) {
  const { title } = props
  const items = props.items.map((item, i) => ({
    name: item.headline,
    description: item.description,
    floorplanCarouselItem: {
      image: item.image
    },
    sku: `${i}`
  }))

  return (
    <FloorPlanCarousel
      {...{ __typename: props.__typename, items, title, cropImages: false }}
    />
  )
}
