import React from 'react'
import { match, P } from 'ts-pattern'

import { NotFoundPage } from '../404'
import { CompetitiveComponents } from '../CompetitiveComponents'
import { MoversComponents } from '../MoversComponents'
import NeighbourhoodWatch from '../NeighbourhoodWatch/NeighbourhoodWatch'
import { OdmonMediaPageComponents } from '../OdmonMediaPageComponents'
import { PrivacyDeepDive } from '../PrivacyDeepDive'
import { PrivacyHeroBanner } from '../PrivacyHeroBanner'
import QuizRecommendedSystem from '../QuizRecommendedSystem'
import { QuoteWizardEmbedded } from '../QuoteWizardEmbedded'
import { SecuredSystems } from '../SecuredSystems'
import { SubHeroText } from '../SubHeroText'
import type { LayoutReferenceProps } from '@ecomm/home-landing-data'
import { devThrowError } from '@ecomm/error-handling'

export default function LayoutReference({ referenceId }: LayoutReferenceProps) {
  return match(referenceId)
    .with('404', () => <NotFoundPage />)
    .with('OdmonMediaPageComponents', () => <OdmonMediaPageComponents />)
    .with('DeepDive', () => <PrivacyDeepDive />)
    .with('CompetitiveComponents', () => <CompetitiveComponents />)
    .with('CompetitiveComponentsADT', () => (
      <CompetitiveComponents competitor="ADT" />
    ))
    .with('CompetitiveComponentsRing', () => (
      <CompetitiveComponents competitor="Ring" />
    ))
    .with('QuizRecommendedSystem', () => <QuizRecommendedSystem />)
    .with('QuoteWizard', () => <QuoteWizardEmbedded />)
    .with('NeighbourhoodWatch', () => <NeighbourhoodWatch />)
    .with('MoversComponents', () => <MoversComponents />)
    .with('PrivacyHeroBanner', () => <PrivacyHeroBanner />)
    .with('SecuredSystems', () => <SecuredSystems />)
    .with('SubHeroText', () => <SubHeroText />)
    .with('PartnerWithUs', () => {
      devThrowError(Error('PartnerWithUs is not implemented'))
      return null
    })
    .with(P.nullish, () => {
      return null
    })
    .exhaustive()
}
