import { getRudderstack } from '@ecomm/shared-window'

export function trackMonitoringAlarmCarouselTabClick(
  carousel_tab_position: number
) {
  getRudderstack(r =>
    r.track('click_monitoring_alarm_carousel', {
      carousel_tab_position
    })
  )
}

export function trackMonitoringCTA() {
  getRudderstack(r => r.track('monitoring_cta'))
}

/**
 * Track click compare plans on choose/change and features-alarm-monitoring pages.
 */
export function trackComparePlanClick() {
  getRudderstack(r => r.track('compare_plan_click'))
}
