import { trackShopNow } from '@ecomm/cdp-tracking'
import { SSButton } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React from 'react'
import { useTracking } from 'react-tracking'

function EmptyMessage() {
  const { trackEvent } = useTracking()
  const onClickHandler = () => {
    trackShopNow()
    trackEvent({ event: 'shop-now-empty-cart' })
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className={classNames('flex h-auto flex-col justify-center')}>
        <h1 className="font-arizona mb-5 text-center text-4xl md:text-[65px] md:leading-[4.7rem]">
          Your shopping cart is currently empty.
        </h1>
        <SSButton
          className="m-auto w-full md:w-48"
          color="primary"
          href="/home-security-shop"
          onClickCtaButton={onClickHandler}
          type="link"
        >
          Shop now
        </SSButton>
      </div>
    </div>
  )
}

export default EmptyMessage
