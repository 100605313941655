import type { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { PriceProvider } from '@ecomm/data-price'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { LiveChat } from '@ecomm/live-chat'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Breadcrumbs, Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  AlarmMoments,
  Collapsible,
  CollapsibleSection,
  FAQSection,
  GatsbyImage,
  NativeVideo,
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { AppWidget, FeatureSection } from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { type PageProps, graphql } from 'gatsby'
import { Suspense } from 'react'
import { match } from 'ts-pattern'

import { ConfidenceBar } from '../../components/ConfidenceBar'
import FeaturedProduct from '../../components/FeaturedProduct'
import { MeetTheSystemBanner } from '../../components/MeetTheSystemBanner'
import ProductCard from '../../components/ProductCard'
import ProductDetails from '../../components/ProductDetails'
import { RiskFree } from '../../components/RiskFree'
import { TwoColumnImageBlurbs } from '../../components/TwoColumnImageBlurbs'
import { TwoColumnImages } from '../../components/TwoColumnImages'
import { TwoColumnVideo } from '../../components/TwoColumnVideo'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import type { FeaturedProductPageSchema } from './featuredProductPageSchema'
import { useFeaturedProductPageFragment } from './useFeaturedProductPageFragment'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly slug: string
}

type Props = Partial<PageProps> & {
  readonly data: {
    readonly contentfulFeaturedProductPage: FeaturedProductPageSchema
  }
  readonly pageContext: PageContext
}

const featureSectionRichTextOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.HEADING_3]: (_, children) => (
      <h3 className="text-3xl">{children}</h3>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <h6 className="my-0 text-xs font-normal">{children}</h6>
    )
  }
}

function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  const {
    appWidget,
    alarmMoments,
    confidenceBarHeadline,
    confidenceBarItems,
    featureOverview,
    imageBlurbs,
    layout,
    meetTheProductTitle,
    product,
    productDetails,
    productNameOverride,
    productDescriptionOverride,
    riskFreeCopy,
    riskFreeImage,
    riskFreeImageVariant,
    showcaseTitle,
    showcaseVideo,
    installation,
    additionalProductsHeadline,
    additionalProducts
  } = useFeaturedProductPageFragment(data)

  const { faq, sku, name, slug } = product
  const {
    footer: { contentful_id: footerId },
    promoBannerStyle,
    liveChat
  } = layout
  const promoType = toPromoBannerStyleValue(promoBannerStyle) ?? 'none'
  const microCopy = useMicroCopy()
  const footer = useFooterQuery(footerId)

  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails

  const headerData = useHeaderRedesignQuery()

  const isUK = locale === 'en-GB'

  const showcaseSection = match(showcaseVideo)
    .with({ __typename: 'ContentfulVideo' }, _showcaseVideo => (
      <NativeVideo
        autoPlay={false}
        captionsSrcUrl={_showcaseVideo.captions}
        className="h-auto"
        containerClassName="mx-auto max-w-5xl"
        data-component="scout-video"
        poster={_showcaseVideo.posterImage || ''}
        preload="none"
        videoSrcUrlMp4={_showcaseVideo.mp4Link}
        videoSrcUrlWebm={_showcaseVideo.webmLink}
      />
    ))
    .with({ __typename: 'ContentfulImageLink' }, _showcaseImage => {
      return _showcaseImage.image ? (
        <div className="text-center">
          <GatsbyImage
            className="rounded-xl"
            image={_showcaseImage.image}
            objectFit="contain"
          />
        </div>
      ) : null
    })
    .exhaustive()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription.metaDescription}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <PriceProvider locale={locale} skus={[sku]}>
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={promoType}
        />
        <Header {...headerData} />
        <Breadcrumbs
          steps={[
            {
              label: name,
              slug
            }
          ]}
          template="Legacy/PLP"
          type="Expand My System"
        />
        <main
          className="prose max-w-8xl md:prose-md lg:prose-lg mx-auto grid grid-cols-1 gap-6 whitespace-pre-line p-4 md:gap-8 md:p-8 lg:gap-12"
          id="content"
        >
          {isUK ? <TrustpilotUKTopBanner /> : null}
          <FeaturedProduct
            product={product}
            productDescriptionOverride={O.fromNullable(
              productDescriptionOverride
            )}
            productNameOverride={O.fromNullable(productNameOverride)}
          />

          {/* Showcase section  */}
          <Collapsible isOpen={true} title={showcaseTitle}>
            <CollapsibleSection>
              {/* this can be a video or an still image */}
              {showcaseSection}
            </CollapsibleSection>
            <CollapsibleSection>
              <ConfidenceBar
                headline={confidenceBarHeadline}
                items={confidenceBarItems}
              />
            </CollapsibleSection>
            {alarmMoments ? (
              <CollapsibleSection>
                <AlarmMoments
                  cta={alarmMoments.cta}
                  description={alarmMoments.description}
                  headline={alarmMoments.headline}
                  tabs={alarmMoments.tabs}
                />
              </CollapsibleSection>
            ) : null}
          </Collapsible>

          {/* MeetTheProductSection */}
          <Collapsible
            dataComponent="MeetTheProductSection"
            isOpen={false}
            title={meetTheProductTitle}
          >
            <CollapsibleSection>
              {match(featureOverview)
                .with(
                  { __typename: 'ContentfulTwoColumnImageSection' },
                  data => <TwoColumnImages {...data} />
                )
                .with({ __typename: 'ContentfulImageLink' }, ({ image }) => (
                  <ContentfulImage
                    className="rounded-base"
                    loading="normal"
                    {...image}
                    quality={100}
                    width={1302}
                  />
                ))
                .exhaustive()}
            </CollapsibleSection>
            {appWidget ? (
              <CollapsibleSection>
                <AppWidget data={appWidget} />
              </CollapsibleSection>
            ) : null}
            <CollapsibleSection>
              <TwoColumnImageBlurbs {...imageBlurbs} />
            </CollapsibleSection>
          </Collapsible>

          {/* Product details section  */}
          {productDetails ? (
            <Collapsible
              dataComponent="ProductDetailsSection"
              isOpen={false}
              title={microCopy['product-details']}
            >
              <CollapsibleSection>
                <ProductDetails content={productDetails} />
              </CollapsibleSection>
            </Collapsible>
          ) : null}

          {/* Installation section */}
          <Collapsible isOpen={false} title="Installation">
            <CollapsibleSection>
              {installation.__typename === 'ContentfulFeatureSection' ? (
                <FeatureSection
                  {...installation}
                  className="!items-start"
                  richTextOptions={featureSectionRichTextOptions}
                />
              ) : (
                <TwoColumnVideo
                  description={installation.rightSide.text}
                  video={{
                    videoSrcUrlWebm: installation.leftSide.webmLink,
                    videoSrcUrlMp4: installation.leftSide.mp4Link,
                    captionsSrcUrl: installation.leftSide.captions,
                    poster: installation.leftSide.posterImage ?? '',
                    preload: 'none',
                    muted: false
                  }}
                />
              )}
            </CollapsibleSection>
          </Collapsible>

          {/* Additional products section */}
          {additionalProductsHeadline ? (
            <h2 className="mb-0">{additionalProductsHeadline}</h2>
          ) : null}
          {additionalProducts ? (
            <div
              className="grid md:grid-cols-3 lg:grid-cols-4"
              data-component="ProductList"
            >
              {additionalProducts.map((item, index) => (
                <ProductCard
                  key={`${index}-${item.sku}`}
                  {...item}
                  addToCartButton
                  className="border-neutral-light-100 m-2 border-2 border-solid"
                  disableMonthlyPricing
                  disablePromo
                  isAffirmExperience={false}
                  productLink={item.slug ? `/${item.slug}` : null}
                />
              ))}
            </div>
          ) : null}

          {faq ? (
            <FAQSection
              collapsible
              faq={faq}
              title={microCopy['faq-title-short']}
            />
          ) : null}
          <RiskFree
            copy={riskFreeCopy}
            product={product}
            productImage={riskFreeImage}
            productImageVariant={riskFreeImageVariant}
            sku={sku}
            variant={product.variant}
          />
          <MeetTheSystemBanner />
        </main>
        <Footer {...footer} type="Full" />
        {liveChat ? <LiveChat /> : null}
      </PriceProvider>
    </TrackingProvider>
  )
}

export default function FPPTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const fppTemplateQuery = graphql`
  #graphql
  query FppTemplate($id: String) {
    contentfulFeaturedProductPage(id: { eq: $id }) {
      ...fppPage
    }
  }
`
