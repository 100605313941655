import { voidFn } from '@simplisafe/ewok'
import classNames from 'classnames'
import React from 'react'

type ImageLoading = Record<
  string,
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
>

const imageLoading: ImageLoading = {
  high: {
    // @ts-expect-error - react doesn't know about the fetchpriority attribute
    fetchPriority: 'high',
    decoding: 'sync'
  },
  normal: {},
  lazy: {
    decoding: 'async',
    loading: 'lazy'
  }
}

type Props = {
  readonly url: string
  /** ONLY 1 image per page can have a high loading prop */
  readonly loading?: 'high' | 'lazy' | 'normal'
  readonly quality?: number
  readonly width?: number
  readonly height?: number
  readonly description: string
  readonly className?: string
  readonly classNameOverride?: string
  readonly originalWidth: number
  readonly originalHeight: number
  readonly format?: 'unset' | 'webp'
  readonly onClick?: (element: React.MouseEvent<HTMLImageElement>) => void
  readonly style?: React.CSSProperties
}

/**
 * [Uses Contentful's Images API](https://www.contentful.com/developers/docs/references/images-api/) to request an
 * image of the correct size, format, and quality.
 *
 * Width and height are the maximum dimensions of the image.
 * You will generally only need to pass the width or height to an image since
 * the image will be resized to fit within these dimensions while maintaining its aspect ratio based on the original height and width.
 *
 * When providing a width or height you should always provide dimensions that match with the container the image will be in.
 * If you have a div with a max width of 525px, then the image should have a width set to 525.
 *
 * `srcSet` is used to automatically load higher quality images when the user's device supports it,
 * so providing larger numbers will often result in large file sizes being loaded.
 *
 * An image with a 525 width will size up on for an iphone 13 to 3x it's size, so the largest image will be 1575px wide.
 *
 * When adding images keep an eye on the network tab. Images should never be larger than 20 kB.
 * If an image is larger than 20 kB, consider reducing the quality or dimensions.
 *
 * Prominent images above the fold should be loaded eagerly.
 *
 * [Uses HTML's `loading` attribute to lazy load images by default.](https://developer.mozilla.org/en-US/docs/Web/Performance/Lazy_loading)
 *
 *
 * @param url The URL of the image to request. In a GraphQL query this is the assets URL.
 * @param loading The loading attribute for the image. Defaults to 'lazy'.
 * @param quality The quality of the image. Defaults to 50.
 * @param width The width of the image.
 * @param height The height of the image.
 * @param description The alt text of the image.
 * @param className Adds to the default className.
 * @param classNameOverride Overrides the default className.
 * @param originalWidth The original width of the image. Used for calculating aspect ratio.
 * @param originalHeight The original height of the image. Used for calculating aspect ratio.
 * @param format The format of the final image. By default, if the value is not passed, it'll turn the image to a webp for better performance. But if the value is "unset", it will get the default extension of the image
 */
function ContentfulImage({
  url: _url,
  loading = 'lazy',
  height = 800,
  width = 800,
  quality = 50,
  description,
  className = '',
  classNameOverride = '',
  originalWidth,
  originalHeight,
  format = 'webp',
  onClick = voidFn,
  style = {}
}: Props) {
  const url = _url.replace(
    'https://downloads.ctfassets.net',
    'https://images.ctfassets.net'
  )

  /**
   * Ensures src is not set before image is added to the DOM. In some cases this appears to cause an extraneous
   * request for the fallback src image w/o regard for srcset. In Firefox this causes a NS_BINDING_ABORTED error although the image
   * still displays for the user. (https://github.com/facebook/react/issues/20682#issuecomment-1252673568)
   */
  const addSrc = (src: string) => (img: HTMLImageElement) => {
    img && img.setAttribute('src', src)
  }

  const finalFormat = format !== 'unset' ? `&fm=${format}` : ''

  return (
    <img
      alt={description}
      className={classNames([
        classNameOverride === ''
          ? `bg-neutral-light-50 w-full ${className}`
          : classNameOverride
      ])}
      data-testid="contentful-image"
      height="auto"
      onClick={onClick}
      ref={addSrc(
        `${url}?w=${Math.round(width)}&h=${Math.round(height)}&q=${Math.round(
          quality
        )}${finalFormat}`
      )}
      srcSet={`
      ${url}?w=${Math.round(width)}&h=${Math.round(height)}&q=${Math.round(
        quality
      )}${finalFormat} 1x,

        ${url}?w=${Math.round(width * 1.25)}&h=${Math.round(
          height * 1.25
        )}&q=${Math.round(quality)}${finalFormat} 1.5x,
        ${url}?w=${Math.round(width * 1.75)}&h=${Math.round(
          height * 1.75
        )}&q=${Math.round(quality)}${finalFormat} 2x,
      `}
      style={{
        objectFit: 'cover',
        aspectRatio: `${Math.round(originalWidth)}/${Math.round(
          originalHeight
        )}`,
        ...style
      }}
      width="auto"
      {...imageLoading[loading]}
    />
  )
}

export default React.memo(ContentfulImage)
