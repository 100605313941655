import * as F from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useMemo } from 'react'

import { useGetDescriptionTokenReplacements } from './useGetDescriptionTokenReplacements'

export const useGetCartLineItemDescription = (
  planProductPrice: number,
  productInformation?: string
) => {
  const descriptionTokenReplacement =
    useGetDescriptionTokenReplacements(planProductPrice)

  return useMemo(
    () =>
      F.pipe(
        productInformation,
        O.fromNullable,
        O.map(descriptionTokenReplacement),
        O.getOrElse(() => '')
      ),
    [descriptionTokenReplacement, productInformation]
  )
}
