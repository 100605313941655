import React from 'react'

import { getMappedComponent } from '../../utils/getMappedComponent'
import type {
  ComponentTypes,
  ContentCollectionProps
} from '@ecomm/home-landing-data'

export const mapPageComponents = (components: readonly ComponentTypes[]) =>
  components.map((componentData, index) =>
    getMappedComponent(componentData, index)
  )

export default function ContentCollection({ content }: ContentCollectionProps) {
  return <>{mapPageComponents(content)}</>
}
