import {
  useMonthsOfFreeServiceText,
  useSitewidePromoEndDateDisplay
} from '@ecomm/promotions-hooks'
import { useCallback } from 'react'
import {
  replaceTokensWithMonths,
  replaceTokensWithPrice,
  replaceTokensWithVisaGiftCardEligibilityDate
} from '@ecomm/micro-copy'
import { getLocale } from '@ecomm/utils'
import * as F from 'fp-ts/lib/function'

export const useGetDescriptionTokenReplacements = (
  planProductPrice: number
) => {
  const locale = getLocale()
  const { endDate } = useSitewidePromoEndDateDisplay()
  const monthsOfService = useMonthsOfFreeServiceText(true)

  return useCallback(
    (description: string) => {
      return F.pipe(
        description,
        d => replaceTokensWithPrice(locale, d, planProductPrice),
        d => replaceTokensWithMonths(d, monthsOfService),
        d => replaceTokensWithVisaGiftCardEligibilityDate(d, endDate)
      )
    },
    [endDate, monthsOfService, planProductPrice, locale]
  )
}
