import {
  leadGenCapture,
  type LeadGenCaptureProps,
  type LeadGenResponseProps
} from '@ecomm/data-leads'
import { usePartnerPromoBannerData } from '@ecomm/promotions-hooks'
import { LeadCaptureForm } from '@ecomm/shared-components'
import { getCookie, getPartnerCookie, setCookie } from '@ecomm/shared-cookies'
import {
  brazeTrackPromoView,
  setBrazeAttributeGoogleClientId,
  useLeadGenCaptureV2,
  useGoogleAnalytics,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { trackLeadCapture } from '@ecomm/tracking'
import { voidFn } from '@simplisafe/ewok'
import classNames from 'classnames'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { navigate } from 'gatsby'
import { Link } from '@ecomm/framework'
import type React from 'react'
import { useState } from 'react'
import { useTracking } from 'react-tracking'

import { PartnerOfferText, PromoBannerBaseTemplate } from '../..'
import { CartBanner } from '../../lib/CartBanner'
import type {
  LeadCaptureFormPropsOnSubmit,
  PromoBannerPageType,
  SimpliSafeCSSProperties
} from '../../types'
import { useIdentify } from '@ecomm/shared-ninetailed'
import { useLocale } from '@ecomm/data-hooks'

export type PartnerBannerProps = {
  readonly showCta?: boolean
  readonly type?: PromoBannerPageType
}

export type Span = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type Spans = readonly [Span, Span?, Span?]

export const COOKIE_LEAD_CAPTURE_SUBMITTED =
  'partnerBanner_isLeadCaptureSubmitted'

export function PartnerBanner({ showCta = true, type }: PartnerBannerProps) {
  const {
    backgroundColor,
    discountSecondaryText,
    discountText,
    displayEmailInputField,
    lineItemText,
    linkPath,
    logoDescription,
    logoUrl,
    monthsOfService,
    partnerName,
    primaryOfferText,
    primaryTextColor,
    secondaryOfferText,
    secondaryOfferTextMobile,
    secondaryTextColor
  } = usePartnerPromoBannerData()
  const cookiePartnersData = getPartnerCookie()

  const styles: SimpliSafeCSSProperties = {
    '--btn-accent': 'var(--promo-banner-bg)',
    '--btn-primary': 'var(--promo-primary-text)',
    '--promo-banner-bg': backgroundColor,
    '--promo-primary-text': primaryTextColor || 'var(--white)',
    '--promo-secondary-text': secondaryTextColor || 'var(--white)',
    '--prose-links-color': 'var(--promo-primary-text)'
  }

  const [isLeadCaptureSubmitted, setIsLeadCaptureSubmitted] = useState(
    !!getCookie(COOKIE_LEAD_CAPTURE_SUBMITTED)
  )
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })
  const identify = useIdentify()
  const [clientId] = useGoogleAnalytics()
  const locale = useLocale()

  const leadGenCaptureV2 = useLeadGenCaptureV2()

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (
    email: string,
    onFailure: (message: string) => void
  ) => {
    const handleLeadCaptureFailure = () => {
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      onFailure("We've encountered an error. Please try again later.")
    }

    const handleLeadCaptureSuccess = async (value: LeadGenResponseProps) => {
      setIsLeadCaptureSubmitted(true)
      setCookie(COOKIE_LEAD_CAPTURE_SUBMITTED, true)
      brazeTrackPromoView()
      trackLeadCapture(value, trackEvent, optimizelyTrackSiteEvents)
      identify(value)

      // set google client id for UK as custom braze attribute ECP-12023
      locale === 'en-GB' &&
        clientId &&
        setBrazeAttributeGoogleClientId(clientId)

      console.info(
        `In function PartnerBanner trackLeadCapture called with ${JSON.stringify(
          { leadId: value.leadId, externalId: value.externalId }
        )}`
      )
    }

    const leadGenParams: LeadGenCaptureProps = {
      email,
      source: partnerName || cookiePartnersData?.partnerName
    }

    leadGenCapture(leadGenParams)()
      .then(res => {
        return pipe(
          res,
          E.match(_ => {
            handleLeadCaptureFailure()
            return {}
          }, handleLeadCaptureSuccess)
        )
      })
      .catch(_ => handleLeadCaptureFailure())

    leadGenCaptureV2({
      source: partnerName || cookiePartnersData?.partnerName || '',
      email
    })
  }

  const colCount = 1 + (displayEmailInputField ? 1 : 0) + (logoUrl ? 1 : 0)

  /* Provide larger click target for link unless email input is shown so we don't trigger navigation when interacting with email input.
  Inspired by https://css-tricks.com/block-links-the-search-for-a-perfect-solution/ */
  const isBannerClickable = linkPath && !displayEmailInputField

  const onBannerClick = (event: React.MouseEvent) => {
    linkPath && navigate(linkPath)
    event.preventDefault()
  }

  const partnerOfferText = (
    <PartnerOfferText
      discountText={
        discountSecondaryText
          ? `${discountText} ${discountSecondaryText}`
          : discountText
      }
      isCompact={colCount > 1}
      isSingleLine={false}
      lineItemText={lineItemText}
      monitoringText={monthsOfService}
      primaryText={primaryOfferText}
      secondaryText={secondaryOfferText}
      secondaryTextMobile={secondaryOfferTextMobile}
    />
  )

  return type !== 'none' ? (
    <div style={styles}>
      <PromoBannerBaseTemplate
        dataComponent="PartnerBanner"
        onClick={isBannerClickable ? () => onBannerClick : voidFn}
        role="banner"
        style={{ cursor: isBannerClickable ? 'pointer' : 'auto' }}
      >
        {type === 'cart' ? (
          <CartBanner />
        ) : (
          <div className="text-inherit grid grid-cols-12 w-full items-center gap-4 md:gap-8 lg:gap-16">
            {logoUrl ? (
              <div className="col-span-12 md:col-span-3 lg:col-span-4 self-center lg:mx-md">
                <img
                  alt={logoDescription}
                  src={logoUrl}
                  style={{
                    display: 'block',
                    margin: 'auto',
                    maxWidth: '350px',
                    width: '100%'
                  }}
                />
              </div>
            ) : null}
            <div
              className={classNames(
                'max-w-full relative self-center justify-self-center col-span-12',
                {
                  'md:col-span-4': colCount === 3,
                  'md:col-span-7 lg:col-span-8': colCount === 2
                }
              )}
            >
              {linkPath ? (
                <Link
                  style={{
                    color: 'unset',
                    textDecoration: 'none'
                  }}
                  to={linkPath}
                >
                  {partnerOfferText}
                </Link>
              ) : (
                <>{partnerOfferText}</>
              )}
            </div>
            {displayEmailInputField ? (
              <div className="relative max-w-full justify-self-center col-span-12 md:col-span-5 lg:col-span-4">
                <div className="text-xs prose md:prose-md lg:prose-lg whitespace-pre-line text-inherit">
                  <LeadCaptureForm
                    buttonVariant="outlined"
                    ctaButtonHref="/home-security-shop"
                    ctaButtonText="Shop now"
                    isSubmitted={isLeadCaptureSubmitted}
                    label="Enter your email"
                    legal={
                      <span>
                        You may receive email offers from us in accordance with
                        our{' '}
                        <Link to="/legal/privacy-policy">
                          <strong>Privacy Policy</strong>
                        </Link>
                        .
                      </span>
                    }
                    onSubmit={handleEmailSubmit}
                    placeholder="enter your email"
                    showCta={showCta}
                    submitButtonText="Get offer"
                    successMessage="Your code will automatically apply at checkout."
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </PromoBannerBaseTemplate>
    </div>
  ) : null
}
