import {
  GatsbyHeroBannerSchema,
  V2HeroBanner as HeroBanner,
  V2HeroBannerSchema as HeroBannerSchema
} from '@ecomm/shared-components'
import { useLocale } from '@ecomm/data-hooks'

import { useHeroBannerQuery } from '../../hooks/useHeroBannerQuery'
import { Experience, mapApolloExperiences } from '@ecomm/shared-ninetailed'

type ShopHeroBannerProps = GatsbyHeroBannerSchema & {
  readonly heightType: 'any' | 'responsive'
  readonly className: string
}

export function ShopHeroBanner(heroBanner: ShopHeroBannerProps) {
  const isUs = useLocale() === 'en-US'
  const heroBannerData = useHeroBannerQuery(heroBanner.id)

  return isUs ? (
    <Experience
      {...{ ...heroBannerData, heightType: heroBanner.heightType }}
      component={HeroBanner}
      experiences={mapApolloExperiences<HeroBannerSchema>(
        heroBannerData.ntExperiencesCollection.items
      )}
      id={heroBanner.id}
      key={heroBanner.id}
      passthroughProps={{
        className: heroBanner.className
      }}
    />
  ) : (
    <HeroBanner
      className={heroBanner.className}
      {...heroBannerData}
      heightType={heroBanner.heightType}
    />
  )
}
