import { ContentfulRichText } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import { format, parse } from 'date-fns'
import { Link } from '@ecomm/framework'
import React from 'react'

import type { ReviewSectionSchema } from './schema'

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12405
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export function ReviewSection({
  headline,
  reviews,
  button
}: ReviewSectionSchema) {
  const { locale } = useEnv()
  const parseFormat = locale === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'

  return (
    <section>
      {headline ? <h2 className="w-full text-center">{headline}</h2> : null}
      <ul className="grid w-full list-none grid-cols-12 gap-4 p-0 md:gap-8">
        {reviews.map(review => (
          <li
            className={classNames(
              'rounded-base bg-neutral-light-50 col-span-12 m-0 flex h-full flex-col justify-center px-8 py-6 text-center lg:px-12 lg:py-8',
              {
                'md:col-span-6': reviews.length === 2,
                'md:col-span-4': reviews.length > 2,
                'lg:col-span-3': reviews.length > 3
              }
            )}
            key={`Review-${review.company}`}
          >
            <span className="text-heading-4-size mt-0 font-light">
              <ContentfulRichText raw={review.review.raw} />
            </span>
            <p className="mb-1 mt-0 font-bold">{review.company}</p>
            {review.publishDate ? (
              <span className="text-base">
                {format(
                  parse(review.publishDate, 'yyyy-mm-dd', new Date()),
                  parseFormat
                )}
              </span>
            ) : null}
          </li>
        ))}
      </ul>
      {button ? (
        <Link
          className="btn btn-solid-primary mx-auto mt-8 block w-full md:w-fit"
          to={button.url}
        >
          {button.text}
        </Link>
      ) : null}
    </section>
  )
}
