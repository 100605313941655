import { gql } from '@apollo/client/index.js'

export const APP_FEATURES_CAROUSEL_ASSET_QUERY = gql`
query AppFeaturesCarouselAssets($locale : String! $preview : Boolean! $ids : [String]! ) {
  appFeaturesCarouselAssets: assetCollection(
    locale : $locale
    preview : $preview,
    where: {sys: {id_in: $ids}},
    ) {
    items {
      sys {
        id
      }
      description
      originalWidth: width
      originalHeight: height
      url
    }
  }
}`
