import { FloorPlanCarousel } from '@ecomm/shared-components'
import { stringToKebabCase } from '@ecomm/utils'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

import { FeatureSection } from '../FeatureSection'
import type { AnchorSectionSchema } from './schema'

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12400
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export function AnchorSection({
  name,
  component,
  classNames
}: AnchorSectionSchema) {
  return (
    <div
      className={classNames}
      data-testid="anchor-section"
      id={stringToKebabCase(name)}
    >
      {match(component)
        .with(
          { __typename: literal('ContentfulFeatureSection').value },
          data => <FeatureSection {...data} className="rounded-lg" />
        )
        .with(
          {
            __typename: literal('ContentfulCarousel').value
          },
          data => {
            const items = data.items.map(item => ({
              ...item,
              name: item.headline,
              floorplanCarouselItem: {
                image: item.image
              }
            }))

            return (
              // @ts-expect-error
              <FloorPlanCarousel description={data.description} items={items} />
            )
          }
        )
        .exhaustive()}
    </div>
  )
}
