import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  ContentfulImage,
  ContentfulImageSchema
} from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'

import { InstallationSmallTextSchema } from '@ecomm/home-landing-data'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const target: ContentfulImageSchema = node.data.target
      return (
        <div className="order-1 col-span-12 md:row-span-2 md:col-span-2 flex items-center justify-center md:justify-end">
          <ContentfulImage
            className="w-full"
            classNameOverride="md:mr-0 h-20"
            {...target}
          />
        </div>
      )
    }
  }
}
export function InstallationSmallText({ text }: InstallationSmallTextSchema) {
  return (
    <div
      className={classNames(
        'w-full md:grid-rows-2 md:gap-2 lg:flex lg:justify-center lg:px-12 lg:gap-4 items-center text-center rounded-base gap-4 bg-neutral-light-50 px-4 py-6 grid grid-cols-12',
        'prose-h2:col-span-12 lg:prose-h2:max-w-xs prose-h2:order-2 md:prose-h2:col-span-10 md:col-start-3 prose-h2:mb-0 md:prose-h2:text-3xl',
        'prose-p:col-span-12 prose-p:order-3 md:prose-p:col-span-10 md:col-start-3'
      )}
    >
      <ContentfulRichText {...text} optionsCustom={options} />
    </div>
  )
}
