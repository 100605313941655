import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../../image'

export const affirmReviewGridSchema = z.object({
  __typename: z.literal('ContentfulReviewSection'),
  headline: z.string(),
  reviews: z
    .object({
      logo: contentfulImageQuerySchema,
      review: contentfulRichTextSchema
    })
    .array()
})

export type AffirmReviewGridSchema = TypeOf<typeof affirmReviewGridSchema>
