import { gql } from '@apollo/client/index.js'

export const AFFIRM_BANNER_QUERY = gql`
  query affirmBannerQuery($preview: Boolean! $locale: String!){
  affirmBanner(id: "4VmV1UBXepTJFhRn8xKvni",
    preview : $preview,
    locale : $locale, ) {
    description {
      json
    }
    title
    __typename
    }
}
`
