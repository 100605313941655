import { gql } from '@apollo/client/index.js'

export const COMPARISON_TABLE_QUERY = gql`
query ComparisonTableQuery($id : String! $locale : String! $preview : Boolean!){
  comparisonTable: json(
    id:$id,
    locale : $locale,
    preview : $preview,
  ) {
    content
  }
}`
