import { type TypeOf, z } from '@simplisafe/ewok'
import { gql } from '@apollo/client/index.js'
import { useContentful } from '@ecomm/shared-apollo'

export const freeCameraBanner = z.object({
  header: z.string(),
  title: z.string(),
  text: z.string(),
  disclaimer: z.string(),
  image: z.string()
})

export const jsonFreeCameraBanner = z.object({
  content: freeCameraBanner
})

export type FreeCameraBanner = TypeOf<typeof freeCameraBanner>
export type JSONFreeCameraBanner = TypeOf<typeof jsonFreeCameraBanner>

export const FREE_CAMERA_BANNER_QUERY = gql`
  query FREE_CAMERA_BANNER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    json(
      preview: $preview
      locale: $locale
      id: $id
    ) {
    content 
  }
  }
`

export function useFreeCameraBannerQuery() {
  const jsonContent = useContentful(FREE_CAMERA_BANNER_QUERY, {
    id: '1UTx77q31vp3CiKUHRQjC4'
  })

  const freeCameraData = jsonFreeCameraBanner.parse(jsonContent?.data?.json)

  return freeCameraData.content
}
