import { trackAlarmContinueShopping } from '@ecomm/cdp-tracking'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  GatsbyImage,
  type GatsbyImageSchema,
  Modal
} from '@ecomm/shared-components'
import { Link } from '@ecomm/framework'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export type Props = {
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
  readonly price: string
  readonly quantity: number
  readonly title: string
  readonly image?: GatsbyImageSchema | null
}

function ProductCartModal({
  open,
  setOpen,
  price,
  quantity,
  title,
  image
}: Props) {
  const microCopy = useMicroCopy()

  const handleContinueShopping = () => {
    setOpen(false)
    trackAlarmContinueShopping()
  }

  return (
    <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
      <div className="px-16">
        <div className="p-4">
          <div className="prose-headings:mt-0 mb-8 flex flex-row items-start justify-center md:px-16 md:py-4">
            <StaticImage
              alt={microCopy['add-to-cart']}
              className="mr-4 max-h-[40px] max-w-[40px] py-2"
              src="./add_to_cart.png"
            />

            <h3>{microCopy['added-to-your-cart']}</h3>
          </div>

          <hr className="text-neutral-medium mx-0 my-4 border border-b-0 border-solid border-current"></hr>

          <div className="flex flex-row items-center">
            <div className="basis-1/6 pr-2">
              {image ? (
                <GatsbyImage
                  className="h-full max-h-[60px] w-full"
                  image={image}
                  objectFit="contain"
                />
              ) : null}
            </div>
            <div className="basis-3/6 pr-2">
              {title} ({quantity})
            </div>
            <div className="basis-2/6 text-right">{price}</div>
          </div>

          <hr className="text-neutral-medium mx-0 my-4 border border-b-0 border-solid border-current"></hr>

          <div>
            <button
              className="my-4 w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-lg underline hover:bg-transparent hover:no-underline md:w-1/2"
              data-testid="product-cart-modal-continue-button"
              onClick={handleContinueShopping}
            >
              {microCopy['continue-shopping']}
            </button>
            <Link
              className="rounded-base bg-btn-primary hover:bg-btn-dark float-right my-4 min-h-[3rem] w-full px-4 py-2 text-center text-lg text-white no-underline md:w-1/2"
              data-testid="product-cart-modal-checkout-button"
              to="/cart"
            >
              {microCopy['checkout']}
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProductCartModal
