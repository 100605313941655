import { window } from 'browser-monads-ts'

export type LineItemsWithQty = {
  readonly [sku: string]: number
}

const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
const isbase64 = (str: string) => base64regex.test(str)

export const getLineItemsFromHash = (hash: string): LineItemsWithQty =>
  // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
  window && !!hash && isbase64(hash) ? JSON.parse(window.atob(hash)) : {}
