import { createMockGatsbyImage } from '@ecomm/contentful-schemas'
import { PackageFragment } from '@ecomm/partners-hooks'

const packageImageMock = createMockGatsbyImage(
  'https://images.ctfassets.net/v6awd0kabi65/6it8EmlEzRJeiH2ghgkBFB/b7d51a283fd36502bb2570f255847927/carousel_essentials.jpg?w=170&h=150&q=50&fm=webp 170w,\nhttps://images.ctfassets.net/v6awd0kabi65/6it8EmlEzRJeiH2ghgkBFB/b7d51a283fd36502bb2570f255847927/carousel_essentials.jpg?w=340&h=300&q=50&fm=webp 340w,\nhttps://images.ctfassets.net/v6awd0kabi65/6it8EmlEzRJeiH2ghgkBFB/b7d51a283fd36502bb2570f255847927/carousel_essentials.jpg?w=680&h=600&q=50&fm=webp 680w'
)

export const mockPackageData: PackageFragment = {
  description: {
    raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"The Indoor Camera Kit is perfect for protecting your home from intruders. It’s got a motion sensor to monitor main passages and entry sensors to cover your entry points. Plus, an Indoor Camera to power 24/7 Live Guard Protection.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"24/7 alarm monitoring","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Ability to add extra sensors","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"No contracts","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"}],"nodeType":"unordered-list"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
  },
  displayName: 'LifeLock Indoor Kit',
  image: packageImageMock,
  product: 'lifelock-indoor-en-US',
  products: [
    {
      isNew: false
    }
  ],
  slug: 'lifelock-indoor-kit',
  offerTagExtraText: {
    raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[{"type":"superscript"}],"value":"1 sup","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
  }
}
