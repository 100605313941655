import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { coloredSectionSchema } from '@ecomm/shared-sections'
import { type TypeOf, z } from '@simplisafe/ewok'

import { layoutSchema } from '../../Layout/schema'
import { trustPilotSchema } from '../../trustPilot'

const extendedAssetSchema = gatsbyImageSchema.extend({
  __typename: z.literal('ContentfulAsset'),
  contentful_id: z.string()
})

const reviewsHeroBannerSchema = z.object({
  __typename: z.literal('ContentfulHeroBanner'),
  content: z
    .object({
      __typename: z.literal('ContentfulHeroBannerColumn'),
      descriptionMobile: z.object({
        raw: z.string(),
        references: z.array(extendedAssetSchema).optional()
      })
    })
    .array()
})

const reviewCardSchema = z.object({
  __typename: z.literal('ContentfulReviewCard'),
  image: gatsbyImageSchema.nullish(),
  text: z
    .object({
      raw: z.string(),
      references: z.array(extendedAssetSchema).optional()
    })
    .nullish(),
  textAlignment: z.enum(['Left', 'Center', 'Right']).nullish(),
  imagePosition: z
    .enum([
      'Top',
      'Top-Center',
      'Right',
      'Bottom',
      'Bottom-Center',
      'Left',
      'Background'
    ])
    .nullish(),
  iconSize: z
    .enum([
      'Extra Small',
      'Small',
      'Medium',
      'Large',
      'Extra Large',
      'Responsive'
    ])
    .nullish()
})

const reviewGridSchema = z.object({
  __typename: z.literal('ContentfulReviewGrid'),
  leftContent: reviewCardSchema.array().nonempty(),
  rightContent: reviewCardSchema.array().nonempty()
})

const componentsSchema = z.union([
  reviewGridSchema,
  z.object({
    __typename: z.literal('ContentfulLink'),
    text: z.string(),
    url: z.string()
  }),
  reviewsHeroBannerSchema,
  coloredSectionSchema,
  trustPilotSchema
])

export const reviewsLayoutSchema = layoutSchema.extend({
  components: z.array(componentsSchema),
  promoBannerStyle: z.string(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const reviewsPageContentSchema = z.object({
  slug: z.string(),
  breadcrumbTitle: z.string().nullish(),
  layout: reviewsLayoutSchema,
  showPopupQuoteWizard: z.boolean().nullable().optional()
})

export type ReviewGridSchema = TypeOf<typeof reviewGridSchema>
export type ReviewCardSchema = TypeOf<typeof reviewCardSchema>
export type ReviewsHeroBannerSchema = TypeOf<typeof reviewsHeroBannerSchema>
