import type { AppWidgetData } from '@ecomm/scout'

import {
  useAffirmBannerQuery,
  useAppWidgetQuery,
  useCameraBannerAssetQuery,
  useComparisonTableQuery,
  useConfidenceBarQuery,
  useExpertReviewsQuery,
  useExpertSectionAssetQuery,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery,
  useQuoteWizardAssetQuery,
  useUserReviewsDataQuery,
  useVideoButtonAssetQuery
} from './queries'
export const useStaticQueries = () => {
  const { expertReviews: expertReviewsData } = useExpertReviewsQuery()
  const videoButtonAssetData = useVideoButtonAssetQuery()
  const expertSectionAssetData = useExpertSectionAssetQuery()
  const affirmBannerData = useAffirmBannerQuery()
  const { appWidget } = useAppWidgetQuery()
  const confidenceBarData = useConfidenceBarQuery('5ynnx5XFY9LYRUyZEkN98K')
  const guaranteeAsset = useGuaranteeAssetQuery()
  const cameraBannerAsset = useCameraBannerAssetQuery()
  const guaranteeCopy = useGuaranteeCopyQuery()
  const quoteWizardAsset = useQuoteWizardAssetQuery()
  const { userReviewsData: reviewsData } = useUserReviewsDataQuery()
  const { comparisonTable } = useComparisonTableQuery('1zTyewofJ6lKWySS451GBg')

  return {
    expertReviewsData,
    videoButtonAssetData,
    expertSectionAssetData,
    affirmBannerData,
    appWidget: <AppWidgetData>appWidget.content,
    confidenceBarData,
    guaranteeAsset,
    cameraBannerAsset,
    guaranteeCopy,
    quoteWizardAsset,
    reviewsData,
    comparisonTable
  }
}
