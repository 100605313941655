import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import {
  useCurrentPromoBannerData,
  useLeadCaptureEmailSubmitted
} from '@ecomm/promotions-hooks'
import {
  COOKIE_RETURN_TO_QW,
  setCookie,
  updateLeadData
} from '@ecomm/shared-cookies'
import {
  brazeTrackGuidedSystemBuilderRecommendation,
  convertQueryParamsToObject,
  fbTrackLeadCreated,
  ReferrerUrlContext,
  setBrazeAttributeGoogleClientId,
  setFirstName,
  trackLeadCapture,
  useGoogleAnalytics,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { useLeadGenCaptureV2 } from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import { type MaybeT, None } from '@simplisafe/ewok'
import {
  type leadGenRecommendationsV2Response,
  leadGenRecommendationsV2
} from '@simplisafe/ss-ecomm-data/leads/quoteWizard/recommendations'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useContext, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'
import { useIdentify } from '@ecomm/shared-ninetailed'
import {
  convertGuidedSystemBuilderParamsToPackageComponents,
  convertParamsObjectToResponsesArray
} from '@ecomm/home-landing-data'

const LEAD_SOURCE_TYPE_GSB = 'guided_system_builder'

/**
 * Returns value for lead source for GuidedSystemBuilder leads
 * @returns
 */
function getLeadSourceGSB() {
  return `Guidedsystembuilder_${getDeviceType().toLowerCase()}`
}

/**
 * Captures and tracks Guided System Builder lead submission in our database and braze
 */
export const useGuidedSystemBuilderCaptureLeadAndTrack = () => {
  const [clientId] = useGoogleAnalytics()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const identify = useIdentify()
  const [error, setError] = useState<Error | null>(null)
  const [leadGenResponse, setLeadGenResponse] = useState<
    MaybeT<leadGenRecommendationsV2Response>
  >(None())
  const [trackLeadCaptureCompleted, setTrackLeadCaptureCompleted] =
    useState(false)
  const locale = useLocale()
  const location = useLocation()
  const { trackEvent } = useTracking({
    appSection: 'GuidedSystemBuilderRecommendation'
  })
  const captureLead = useLeadGenCaptureV2()
  const { setIsSubmitted } = useLeadCaptureEmailSubmitted()

  const { getFreeGiftItemsWithServicePlan } = usePriceContext()

  const withMonitoringGiftItem = pipe(
    getFreeGiftItemsWithServicePlan(
      'simplisafe-custom-home-security-system-en-US'
    ),
    O.toNullable
  )

  const { hasFreeGiftItem } = useCurrentPromoBannerData()

  const hasFreeCam =
    hasFreeGiftItem &&
    withMonitoringGiftItem &&
    withMonitoringGiftItem.some(
      item => item.sku && item.sku.match(/CM006-01DWW.*/)
    )

  const camInResponse = location.search.indexOf('sc%2C') !== -1 //jebbit string for cam

  const shouldIncludeScout = camInResponse && hasFreeCam !== null && !hasFreeCam

  const channel = useContext(ReferrerUrlContext).channel

  useEffect(() => {
    const email = new URLSearchParams(location.search).get('email')
    const phone = new URLSearchParams(location.search).get('phone')
    const name = new URLSearchParams(location.search).get('name')
    const components = convertGuidedSystemBuilderParamsToPackageComponents(
      location.search,
      shouldIncludeScout,
      locale === 'en-US'
    )

    phone && updateLeadData({ phone })

    email
      ? leadGenRecommendationsV2({
          components,
          email
        })(e => {
          setError(e)
        })(response => {
          setLeadGenResponse(response)
          response.cata(
            () =>
              setError(
                new Error('Missing response from leadGenRecommendations')
              ),
            async res => {
              setCookie(COOKIE_RETURN_TO_QW, res.attributeHash, cookiesOption)

              await trackLeadCapture(
                {
                  leadStatus: res.leadStatus.toString(),
                  return_code: res.return_code.toString(),
                  email: res.email || '',
                  externalId: res.externalId || '',
                  leadId: res.leadId || '',
                  leadSource: res.leadSource || '',
                  message: res.message
                },
                trackEvent,
                optimizelyTrackSiteEvents,
                channel,
                clientId
              )
              const identifyBody = {
                email: email,
                firstName: name,
                dynamicUrl: 'product/system/' + res.attributeHash,
                ...(phone ? { phone } : {})
              }
              identify(identifyBody)

              // set google client id for UK as custom braze attribute ECP-12023
              locale === 'en-GB' &&
                clientId &&
                setBrazeAttributeGoogleClientId(clientId)

              setTrackLeadCaptureCompleted(true)
              console.info(
                `In useGuidedSystemBuilderCaptureLeadAndTrack called trackLeadCapture with ${JSON.stringify(
                  { leadId: res.leadId, externalId: res.externalId }
                )}`
              )
              // When a lead is captured from any source, hide PromoBanner lead form ECP-10763
              setIsSubmitted(true)

              const responses = convertParamsObjectToResponsesArray(
                convertQueryParamsToObject(location.search)
              )
              name && setFirstName(name)
              brazeTrackGuidedSystemBuilderRecommendation(
                res.attributeHash,
                location.search,
                responses
              )
              res.email && (await fbTrackLeadCreated(res.email))
            }
          )
        })
      : setError(new Error('email not found in query parameter'))

    email &&
      captureLead({
        ...(name ? { firstName: name } : {}),
        ...(phone ? { phone } : {}),
        email,
        source: getLeadSourceGSB(),
        siteMetadata: {
          sourceType: LEAD_SOURCE_TYPE_GSB
        }
      })
  }, [
    location.search,
    trackEvent,
    shouldIncludeScout,
    optimizelyTrackSiteEvents
  ])

  return [error, leadGenResponse, trackLeadCaptureCompleted] as const
}
