import { Link } from '@ecomm/framework'
import React from 'react'

import { useOnLiveGuardCtaClick } from './useOnLiveGuardCtaClick'
import { trackMonitoringCTA } from '@ecomm/cdp-tracking'

type AlarmMomentsCtaProps = {
  readonly text: string
  readonly url: string
}

export function AlarmMomentsCta({ text, url }: AlarmMomentsCtaProps) {
  const trackOnClick = useOnLiveGuardCtaClick()

  return (
    <Link
      className={`rounded-base border-btn-primary text-btn-primary hover:bg-btn-primary mt-4 w-full cursor-pointer border border-solid px-4 py-3 text-center no-underline transition-colors duration-200 ease-in-out hover:text-white md:mt-6 md:w-fit`}
      onClick={() => {
        trackOnClick()
        trackMonitoringCTA()
      }}
      to={url}
    >
      {text}
    </Link>
  )
}
