import { ContentfulImage } from '@ecomm/contentful/components'
import { EmbeddedPartnerHeaderSchema } from '@ecomm/partners-data'
import { SimpliSafeLogoWhite } from '@ecomm/shared-icons'

const defaultPartnerLogoHeightInPixels = 25

export function EmbeddedPartnerHeader(data: EmbeddedPartnerHeaderSchema) {
  const offerTextContainer = (
    <div className="mr-0 pb-4 text-sm tracking-widest md:py-0 md:text-lg">
      {data.offerText} *
    </div>
  )

  const partnerLogo = data.partnerLogo ? (
    <ContentfulImage
      className="bg-transparent"
      loading="normal"
      {...data.partnerLogo}
      height={data.partnerLogoHeight || defaultPartnerLogoHeightInPixels}
      quality={100}
    />
  ) : null

  const aspectRatio = data.partnerLogo
    ? data.partnerLogo.originalWidth / (data.partnerLogo.originalHeight || 1)
    : 0
  const partnerLogoMobileHeight =
    (data.partnerLogoHeight || defaultPartnerLogoHeightInPixels) * 0.8
  const partnerLogoMobileWidth = partnerLogoMobileHeight * aspectRatio

  return (
    <header
      className={
        'bg-neutral-black flex h-auto w-full flex-col justify-center px-3 text-white md:h-[72px] md:px-8'
      }
      style={{ backgroundColor: data.backgroundColor }}
    >
      <div className="mx-auto flex h-[50px] items-center gap-x-3 md:h-auto">
        <SimpliSafeLogoWhite className="mt-[6px] h-7 w-24 md:h-8 md:w-40" />

        {data.showPlusSign ? (
          <div className="mb-1 text-4xl font-thin md:mb-3 md:text-6xl">+</div>
        ) : null}

        {data.partnerLogo ? (
          <>
            <div className="hidden md:block">{partnerLogo}</div>
            <div
              className="md:hidden"
              style={{
                height: partnerLogoMobileHeight,
                width: partnerLogoMobileWidth
              }}
            >
              {partnerLogo}
            </div>
          </>
        ) : null}

        {data.offerText ? (
          <div className="ml-3 hidden md:block">{offerTextContainer}</div>
        ) : null}
      </div>
      {data.offerText ? (
        <div className="text-right md:hidden">{offerTextContainer}</div>
      ) : null}
    </header>
  )
}
