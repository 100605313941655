import { affirmBannerApolloSchema } from './affirmBannerSchema'

export const affirmBannerApolloMock = affirmBannerApolloSchema.parse({
  __typename: 'AffirmBanner',
  description: {
    json: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Select Affirm at checkout. You’ll pay at the monthly installment that works best for you. It’s easy to sign up. And there’s no late fees or surprises.',
              marks: [],
              data: {}
            }
          ]
        }
      ]
    }
  },
  title: 'Buy now, pay later with'
})
