import type { Block, Inline } from '@contentful/rich-text-types'
import { useLocale } from '@ecomm/data-hooks'
import { Link } from '@ecomm/framework'
import {
  GuidedSystemBuilder,
  guidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import type { Locale } from '@ecomm/utils'
import { type TypeOf, z } from '@simplisafe/ewok'
import { match } from 'ts-pattern'

const buttonSchema = z.object({
  __typename: z.literal('ContentfulButton'),
  url: z.string(),
  buttonText: z.string()
})

const possibleEmbeddedSchema = z.union([
  buttonSchema,
  guidedSystemBuilderSchema
])

type PossibleEmbeddedComponent = TypeOf<typeof possibleEmbeddedSchema>

const getMappedComponent = (
  component: PossibleEmbeddedComponent,
  locale: Locale
) =>
  match(component)
    .with({ __typename: 'ContentfulButton' }, buttonProps => (
      <Link
        className="btn btn-solid-primary mt-4 !px-4 block w-fit min-w-[190px] md:mt-6"
        to={buttonProps.url}
      >
        {buttonProps.buttonText}
      </Link>
    ))
    .with({ __typename: 'ContentfulQuoteWizard' }, quoteWizardProps =>
      locale === 'en-US' ? (
        <Experience
          {...quoteWizardProps}
          component={GuidedSystemBuilder}
          experiences={mapExperiences(quoteWizardProps.nt_experiences)}
          key={quoteWizardProps.id}
        />
      ) : (
        <GuidedSystemBuilder {...quoteWizardProps} />
      )
    )
    .exhaustive()

export default function FeatureSectionEmbeddedEntry({
  node
}: {
  readonly node: Block | Inline
}) {
  const locale = useLocale()
  const target = node.data.target
  return getMappedComponent(target, locale)
}
