import { useReady } from '@ecomm/shared-hooks'
import { useTrackingAffirmInformationIcon } from '@ecomm/tracking'
import classNames from 'classnames'
import React, { useEffect } from 'react'

import { AffirmInformationLogoIcon } from '../AffirmInformationLogoIcon'
import { AffirmClient } from '../types/affirm'
import { trackAffirmInformationalClick } from '@ecomm/cdp-tracking'

type Props = {
  readonly affirmClient?: AffirmClient | null
  readonly title?: string
  readonly description?: string
  readonly discountedPrice: number
  readonly className?: string
  readonly isBms?: boolean
  readonly isAffirmExperience: boolean
}

export function AffirmMonthlyPricingBanner({
  affirmClient = null,
  title = 'Monthly prices presented using',
  description = 'Prices displayed are an example of a 24-mo. payment plan. Order total must be $150+ to select Affirm at checkout.',
  discountedPrice,
  className = '',
  isBms = false,
  isAffirmExperience = false
}: Props) {
  const trackIconClickEvent = useTrackingAffirmInformationIcon('content')
  const isReady = useReady()
  const experimentDescription =
    'Prices displayed are an example of a 36-mo. payment plan. Order total must be $150+ to select Affirm at checkout.'

  useEffect(() => {
    affirmClient &&
      affirmClient.ui.ready(() => {
        affirmClient && affirmClient.ui.refresh()
      })
  }, [affirmClient, discountedPrice])

  return (
    <div
      className={classNames(
        'bg-neutral-light-50 rounded-[20px] leading-[normal]',
        className,
        !isBms && 'p-2 md:px-6'
      )}
      data-component="AffirmMonthlyPricingBanner"
    >
      <div
        className={classNames(
          'mt-2 flex items-end justify-center',
          isBms && 'whitespace-nowrap md:justify-start'
        )}
      >
        <h3 className="m-0 whitespace-nowrap pr-2 text-base font-bold md:text-lg md:font-medium">
          {title}
        </h3>
        {affirmClient && isReady ? (
          <AffirmInformationLogoIcon
            height="29px"
            iconSize="22px"
            onClick={() => {
              trackIconClickEvent()
              trackAffirmInformationalClick('content')
            }}
            priceInCents={discountedPrice}
            width="63px"
          />
        ) : null}
      </div>
      <p
        className={classNames(
          'mt-1 text-center text-xs leading-5 text-neutral-dark',
          isBms && 'md:text-left'
        )}
      >
        {isAffirmExperience ? experimentDescription : description}
      </p>
    </div>
  )
}
