import { expertSectionSchema } from './schema'

const data = {
  image: {
    expertSectionAsset: {
      url: 'https://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png',
      originalHeight: 1773,
      originalWidth: 1855,
      description: '',
      title: 'security expert w/ headset'
    }
  }
}

export const useExpertSectionData = () => {
  return expertSectionSchema.parse(data)
}
