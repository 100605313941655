import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy'
import {
  appFeaturesCarouselAssetsSchema,
  expertsReviewsAssetSchema
} from '@ecomm/scout'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const nhwWrappedSchema = z.object({
  quoteWizardAsset: gatsbyImageSchema,
  appFeaturesCarouselAssets: appFeaturesCarouselAssetsSchema,
  expertReviewsAssets: expertsReviewsAssetSchema,
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  guaranteeCopy: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  }),
  expertReviews: jsonSchema,
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  nhwLogo: gatsbyImageSchema,
  nhwDescription: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  }),
  ceoComment: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type NhwWrappedSchema = TypeOf<typeof nhwWrappedSchema>
