import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import ColoredSectionEmbeddedEntry from './ColoredSectionEmbeddedEntry'
import { type ColoredSectionSchema, themes } from './schema'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => <ColoredSectionEmbeddedEntry node={node} />
  }
}

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12397
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export function ColoredSection({
  headline,
  content,
  disclaimer,
  theme
}: ColoredSectionSchema) {
  return (
    <div
      className={classNames(
        'rounded-base h-auto w-full px-4 py-6 md:p-8 lg:p-16',
        {
          'bg-neutral-light-50':
            theme === themes.grayWithOrange || themes.lightGrayWithNavyText,
          'bg-neutral-black':
            theme === themes.navyWithWhiteText ||
            theme === themes.navyWithOrange,
          'bg-primary-100': theme === themes.orangeWithNavyText
        }
      )}
      data-component={ColoredSection.name}
      data-testid={ColoredSection.name}
    >
      <h2
        className={classNames('w-full text-center', {
          'text-white':
            theme === themes.navyWithWhiteText ||
            theme === themes.navyWithOrange,
          'mb-6':
            theme === themes.orangeWithNavyText ||
            theme === themes.navyWithWhiteText ||
            theme === themes.lightGrayWithNavyText
        })}
      >
        {headline}
      </h2>
      <div
        className={classNames('grid grid-cols-1 gap-4 md:gap-8 lg:gap-16', {
          'md:grid-cols-1': content.length <= 1,
          'md:grid-cols-2': content.length > 1
        })}
      >
        {content.map((smallText, index) => (
          <div
            className={classNames(
              'rounded-base prose-h3:mb-sm prose-h3:text-center prose-h3:text-body-size prose-h3:font-bold col-span-1',
              {
                //It only will get yellow background when theme is gray with orange and it's the second column or when the theme is navy with orange.
                'bg-primary-100':
                  (theme === themes.grayWithOrange && index === 1) ||
                  theme === themes.navyWithOrange,
                //The second column currently shows first in mobile devices (only with gray-with-orange theme)
                'order-1 md:order-2':
                  theme === themes.grayWithOrange && index === 1,
                'order-2 md:order-1':
                  theme === themes.grayWithOrange && index !== 1,
                'prose-p:text-white prose-a:text-white':
                  theme === themes.navyWithWhiteText,
                'p-4 md:p-8':
                  theme !== themes.navyWithWhiteText &&
                  theme !== themes.orangeWithNavyText &&
                  theme !== themes.lightGrayWithNavyText,
                'text-center':
                  theme === themes.navyWithWhiteText ||
                  theme === themes.orangeWithNavyText ||
                  theme === themes.lightGrayWithNavyText
              }
            )}
            data-testid="ColoredColumn"
            key={index}
          >
            <ContentfulRichText
              optionsCustom={options}
              raw={smallText.text.raw}
              references={smallText.text.references}
            />
          </div>
        ))}
      </div>
      {disclaimer ? (
        <div
          className={classNames(
            'prose-p:whitespace-pre-line prose-p:text-xs mt-4 w-full px-8 text-center md:mt-12',
            {
              'prose-p:text-white':
                theme === themes.navyWithWhiteText ||
                theme === themes.navyWithOrange
            }
          )}
        >
          <ContentfulRichText raw={disclaimer.raw} />
        </div>
      ) : null}
    </div>
  )
}
