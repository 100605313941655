import React, { useRef, useState } from 'react'

import Review from './Review'
import { UserReview } from '.'

function Carousel({ items }: { readonly items: readonly UserReview[] }) {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [activeSlide, setActiveSlide] = useState(0)

  /* istanbul ignore next */
  const scrollToSlide = (index: number) => {
    const scrollToIndex = (index: number) => {
      const scrollWidth = carouselRef?.current?.scrollWidth || 0
      const slideWidth = scrollWidth / items.length
      carouselRef?.current?.scrollTo({
        left: slideWidth * index,
        behavior: 'smooth'
      })
    }
    setActiveSlide(index)

    carouselRef.current && scrollToIndex(index)
  }

  /* istanbul ignore next */
  const handleScroll = () => {
    const updateActiveSlide = () => {
      const scrollWidth = carouselRef?.current?.scrollWidth || 0
      const slideWidth = scrollWidth / items.length
      const newIndex = Math.round(
        (carouselRef?.current?.scrollLeft || 0) / slideWidth
      )
      setActiveSlide(newIndex)
    }

    carouselRef.current && updateActiveSlide()
  }

  return (
    <div data-component="carousel">
      <div
        className="mx-auto flex w-full snap-x snap-mandatory overflow-scroll rounded-2xl pb-0"
        data-component="slideContainer"
        onScroll={handleScroll}
        ref={carouselRef}
      >
        {items.map((review, index) => (
          <div
            className="bg-complementary-green-100 flex w-full flex-shrink-0 snap-center flex-col justify-center p-8"
            key={index}
          >
            <Review type="big" {...review} />
          </div>
        ))}
      </div>

      <div className="my-4 flex flex-row justify-center gap-4">
        {items.map((_, index) => (
          <button
            aria-label={`Slide ${index + 1}`}
            className={`h-2 w-2 cursor-pointer rounded-full border-none p-0 ${
              index === activeSlide
                ? 'bg-neutral-black'
                : 'bg-neutral-light-100'
            }`}
            data-component="dot"
            key={index}
            onClick={() => scrollToSlide(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default Carousel
