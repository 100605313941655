import { useApplyPromoCode } from '@ecomm/promotions-hooks'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

interface Props {
  readonly header: string
  readonly title: string
  readonly text: string
  readonly disclaimer: string
  readonly image: string
}

function FreeProductBanner({ header, title, text, disclaimer, image }: Props) {
  useApplyPromoCode(O.none, false)

  return (
    <div className="py-4 pr-4 rounded-2xl bg-neutral-light-50 flex flex-row gap-3 justify-between align-middle">
      <img alt={title} className="my-auto w-[68px] md:w-[107px]" src={image} />
      <div className="flex flex-col items-start grow">
        <h4 className="capitalize text-xs md:text-base font-bold m-0">
          {header}
        </h4>
        <h4 className="md:text-3xl font-bold m-0 mt-1 md:mt-0">{title}</h4>
        <div className="flex flex-col">
          <p className="text-sm md:text-base my-2">{text}</p>
          <small className="text-[8px] md:text-[10px]">{disclaimer}</small>
        </div>
      </div>
    </div>
  )
}

export { FreeProductBanner }
