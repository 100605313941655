import { useLocale } from '@ecomm/data-hooks'
import {
  useCurrentPromoBannerData,
  useDisplayMonitoringDiscount,
  usePromoDiscountText,
  usePromoWithMonitoringDiscountText,
  useTopBannerVisible
} from '@ecomm/promotions-hooks'
import {
  type V2HeroBannerSchema as HeroBannerSchema,
  type GatsbyHeroBannerSchema,
  V2HeroBanner as HeroBanner
} from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import { mapApolloExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import type React from 'react'
import { useEffect, useState } from 'react'

import { useHeroBannerQuery } from '../../hooks/useHeroBannerQuery'
import { HeroBannerTestContainer } from './HeroBannerTestContainer'

type HeroBannerProps = GatsbyHeroBannerSchema & {
  readonly extraContent?: React.ReactNode
  readonly hasPromoSticker?: boolean
}

//This component was meant to extract the business logic from the shared HeroBanner component and to prevent having too many lines of code in the template
export default function HomeLandingHeroBanner({
  extraContent = null,
  hasPromoSticker = false,
  ...gatsbyHeroBannerData
}: HeroBannerProps) {
  const locale = useLocale()
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isPromoTopBanner = useTopBannerVisible()
  const heroBannerData = useHeroBannerQuery(gatsbyHeroBannerData.contentful_id)

  const [placeholders, setPlaceholders] = useState<string | null>(null)
  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const discountTextWithMonitoring = usePromoWithMonitoringDiscountText()
  const discountTextWithoutMonitoring = usePromoDiscountText()
  const discountText = displayMonitoringDiscount
    ? discountTextWithMonitoring
    : discountTextWithoutMonitoring

  const { heroStickerImage } = useCurrentPromoBannerData()

  const mappedMainExperiences = mapApolloExperiences<HeroBannerSchema>(
    heroBannerData.ntExperiencesCollection?.items
  )

  useEffect(() => {
    discountText.cata(
      () => setPlaceholders(null),
      text => setPlaceholders(text.replace('%', ''))
    )
  }, [discountText])

  const discountPercentageText = !isPromoTopBanner ? placeholders : null

  return locale === 'en-US' ? (
    <Experience
      {...heroBannerData}
      component={HeroBannerTestContainer}
      experiences={mappedMainExperiences}
      id={heroBannerData.id}
      key={heroBannerData.id}
      passthroughProps={{
        ...heroBannerData,
        floatingBadgeDiscount: discountPercentageText,
        hasPromoSticker: !!hasPromoSticker,
        heroStickerImage: heroStickerImage,
        isFullWidth: isDesktop ? heroBannerData.isFullWidth : true,
        extraContent: extraContent,
        gatsbyHeroBannerDataContentfulId: gatsbyHeroBannerData.contentful_id
      }}
    />
  ) : (
    <HeroBanner
      {...heroBannerData}
      floatingBadgeDiscount={discountPercentageText}
      hasPromoSticker={!!hasPromoSticker}
      heroStickerImage={heroStickerImage}
      isFullWidth={isDesktop ? heroBannerData.isFullWidth : true}
    />
  )
}
