import { gql } from '@apollo/client/index.js'

export const HERO_BANNER_QUERY = gql`
  fragment monitoringHeroBanner on HeroBanner {
    __typename
    id: sys {id}
    contentful_id: sys {id}
    contentCollection {
      items {
        ... on HeroBannerColumn {
          backgroundColor
          description {
            json
          }
          descriptionMobile {
            json
          }
          logo {
            title
            description
            contentType
            fileName
            size
            url
            originalWidth: width
            originalHeight: height
          }
          image {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageTablet {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageMobile {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          textColor
          textAlignment
          contentVerticalAlignment
          linkColor
          hasPromoSticker
        }
      }
    }
    floatingBadge
    isFullWidth
    heightType
    disclaimerText
    disclaimerTextAlignment
    roundedCorners
  }

  fragment heroBannerExperienceFragment on NtExperience {
    id: sys {id}
    name: ntName
    type: ntType
    audience: ntAudience {
      id: sys {id}
      name: ntName
    }
    config: ntConfig
    variants: ntVariantsCollection(limit: 20) {
      items {
        ... on HeroBanner {
          ...monitoringHeroBanner
        }
      }
    }
  }

  query HeroBannerQuery($id: String!, $preview: Boolean!, $locale: String!) {
    heroBanner(id: $id, locale: $locale, preview: $preview) {
      ...monitoringHeroBanner
      ntExperiencesCollection(limit: 20) {
        items {
          ...heroBannerExperienceFragment
        }
      }
    }
  }
`
