import { z } from '@simplisafe/ewok'
import type { TypeOf } from 'zod'

export const quoteWizardAssetSchema = z.object({
  quoteWizardAsset: z.object({
    originalWidth: z.number(),
    originalHeight: z.number(),
    title: z.string(),
    url: z.string(),
    size: z.number(),
    description: z.string()
  })
})

export type QuoteWizardAsset = TypeOf<typeof quoteWizardAssetSchema>
