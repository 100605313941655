import {
  getExpertReviews,
  QuizBanner,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery
} from '@ecomm/lander'
import {
  ComparisonTable,
  ConfidenceBar,
  ExpertSection,
  ExpertsReviews,
  type ExpertsReviewsProps,
  GuaranteeSection,
  MonitoringAccolades,
  parseJSONDataFromContentful,
  transformToComparisonTableData,
  TwoColumnImages
} from '@ecomm/scout'
import {
  PageWrapper,
  QuoteWizardModal,
  type UserReview,
  UserReviews,
  VideoSection,
  type VideoSectionProps
} from '@ecomm/shared-components'
import type React from 'react'

import useOdmonMediaPageComponents from './useOdmonMediaPageComponents'
import { RecommendedItems } from './components/RecommendedItems'
import { useState } from 'react'
import { useCompetitiveComponentsQuery } from '../CompetitiveComponents/useCompetitiveComponentsQuery'

export function OdmonMediaPageComponents() {
  const [showQuizModal, setShowQuizModal] = useState(false)
  const guaranteeCopy = useGuaranteeCopyQuery()
  const guaranteeAsset = useGuaranteeAssetQuery()
  // @ts-expect-error - this needs a zod schema to change the type from unknown
  const comparisonTableQuery: Record<string, unknown> =
    useCompetitiveComponentsQuery()
  const data = useOdmonMediaPageComponents()

  const {
    expertReviewsAssets,
    quoteWizard,
    expertSectionAsset,
    featureOverview,
    confidenceBarData,
    monitoringPageAccoladesData,
    quoteWizardAssetApollo
  } = data

  const expertReviews: ExpertsReviewsProps = parseJSONDataFromContentful(
    data,
    'expertReviews'
  )

  const videoBanner: VideoSectionProps = parseJSONDataFromContentful(
    data,
    'videoBanner'
  )

  const userReviews: { readonly reviews: readonly UserReview[] } =
    parseJSONDataFromContentful(data, 'userReviews')

  const expertReviewsData = getExpertReviews(
    expertReviewsAssets.items,
    'en-US',
    expertReviews.reviews
  )

  const openQuoteWizardModal: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault()
    setShowQuizModal(true)
  }

  const comparisonTableData = transformToComparisonTableData(
    comparisonTableQuery,
    'comparisonTable'
  )

  return (
    <PageWrapper>
      <div
        className="max-w-8xl mx-auto px-4 mb-16 flex flex-col gap-6 md:gap-8 lg:gap-12"
        data-component="OdmonMediaPageComponents"
      >
        <ExpertsReviews reviews={expertReviewsData} />
        <QuizBanner
          backgroundColor="bg-[#E0DDD8]"
          image={quoteWizardAssetApollo}
          imageOdmon={data.quoteWizardAsset}
          quoteWizard={data.quoteWizard}
          text="Answer a few questions and we’ll customize a security system uniquely designed for your home and needs."
          title="Find the right system for your family."
        />
        <VideoSection {...videoBanner} />
        <div>
          <h3 className="text-3xl font-bold text-center mb-11">
            Keep danger outside. Feel safe inside.
          </h3>
          <ConfidenceBar data={confidenceBarData.content} />
        </div>

        <div>
          <h2 className="mb-4 md:mb-9 text-center -mx-1 md:mx-0">
            Introducing the Outdoor Camera Series 2
          </h2>
          <TwoColumnImages
            data={{
              image1: featureOverview.leftSideImage,
              image2: featureOverview.rightSideImage
            }}
          />
        </div>

        <RecommendedItems
          images={[
            data.theBeaconImage,
            data.theLighthouseImage,
            data.quizImage
          ]}
          openQuoteWizardModal={openQuoteWizardModal}
          title="Shop outdoor security packages"
        />

        <ComparisonTable data={comparisonTableData} />

        <UserReviews
          reviews={userReviews.reviews}
          showBanner={false}
          title="Trusted by millons every day"
        />
        <MonitoringAccolades
          data={{ ...monitoringPageAccoladesData, title: '' }}
        />
        <GuaranteeSection
          copy={guaranteeCopy}
          image={guaranteeAsset.guaranteeAsset}
          quoteWizard={quoteWizard}
        />
        <ExpertSection data={{ image: expertSectionAsset }} />
      </div>
      <QuoteWizardModal
        data={quoteWizard}
        onRequestClose={() => setShowQuizModal(false)}
        show={showQuizModal}
      />
    </PageWrapper>
  )
}
