import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../../image'

export const financingSmallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  internalTitle: z.string(),
  text: z.object({
    raw: z.string(),
    references: contentfulImageQuerySchema.array()
  }),
  iconPosition: z.string().nullish()
})

export type FinancingSmallTextSchema = TypeOf<typeof financingSmallTextSchema>
