import { BlogArticle } from '@ecomm/blog-components'
import {
  BLOG_ARTICLE_QUERY,
  blogArticleSchema,
  normalizeSlug
} from '@ecomm/blog-data'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { Breadcrumbs, Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { useContentful } from '@ecomm/shared-apollo'
import {
  TrustpilotUKTopBanner,
  toPromoBannerStyleValue
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { Suspense } from 'react'

import { getNonEmptyHead, z } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import type { SeoNodeSchema } from '../../config/blogPageResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useBlogLayout } from '../../hooks/useBlogLayout'

export type PageContext = {
  readonly slug: string
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly fbConfig: {
    readonly appId?: string
    readonly sdkUrl?: string
  }
}

type Props = {
  readonly pageContext: PageContext
  readonly path: string
}

const blogCollectionSchema = z.object({
  blogCollection: z.object({
    items: z.nonEmptyArray(blogArticleSchema)
  })
})

export function Content({
  pageContext: { locale, seoDetails, fbConfig, slug }
}: Props) {
  const { promoBannerStyle, footer } = useBlogLayout()
  const footerId =
    footer?.id || locale === 'en-US'
      ? '2SCKVa13yRuLSU4GuooHlb'
      : '5m1zxp97jhi0O96SH5dwGQ'
  const footerData = useFooterQuery(footerId)

  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails

  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  const { data: rawData } = useContentful(BLOG_ARTICLE_QUERY, {
    slug: normalizeSlug(slug),
    site: locale === 'en-US' ? 'US' : 'UK'
  })

  const contentfulBlogPost = pipe(
    blogCollectionSchema.parse(rawData, {
      path: ['BlogArticleTemplate', 'contentfulBlogPost']
    }).blogCollection.items,
    getNonEmptyHead
  )

  const { title } = contentfulBlogPost

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink ?? ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords ?? []}
        metaTitle={metaTitle}
      />
      <PromoBannerWrapper
        experimentData={promoBannerExperiment}
        type={bannerStyle}
      />
      <Header {...headerData} />
      <Breadcrumbs
        steps={[
          {
            label: 'Blog',
            slug: 'blog'
          },
          {
            label: title,
            slug
          }
        ]}
      />
      <main
        className="prose md:prose-md lg:prose-lg whitespace-pre-line"
        data-testid="blog-article-template"
        id="content"
      >
        {locale === 'en-GB' && (
          <TrustpilotUKTopBanner className="mt-7 md:mt-8"></TrustpilotUKTopBanner>
        )}
        <BlogArticle {...contentfulBlogPost} facebookProps={fbConfig} />
      </main>
      {footer ? <Footer {...footerData} type="Full" /> : null}
    </TrackingProvider>
  )
}

export default function BlogArticleTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}
