import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click on checkout with affirm.
 */
export function trackCheckoutAffirm() {
  getRudderstack(r => {
    r.track('checkout_affirm')
  })
}

/**
 * Track click on affirm info icon.
 * Params:
 * section: A brief description of the section where the event is being called
 */
export function trackAffirmInformationalClick(section: string) {
  getRudderstack(r => {
    r.track('affirm_informational_click', { section })
  })
}
