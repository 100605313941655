import {
  GatsbyImage,
  GuidedSystemBuilder,
  type GuidedSystemBuilderSchema
} from '@ecomm/shared-components'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

import DuplexText from './DuplexText'
import type {
  DuplexImageLink,
  DuplexSectionSchema,
  DuplexSmallText,
  PossibleEmbeddedComponent
} from './schema'

const getMappedComponent = (
  component: PossibleEmbeddedComponent,
  index: number
) =>
  match(component)
    .with(
      { __typename: literal('ContentfulSmallText').value },
      (data: DuplexSmallText) =>
        O.some(<DuplexText key={index} text={data.text.raw} />)
    )
    .with(
      { __typename: literal('ContentfulQuoteWizard').value },
      (data: GuidedSystemBuilderSchema) =>
        O.some(
          <div className="rounded-base bg-neutral-light-100 w-full px-4 py-6 md:p-8">
            <GuidedSystemBuilder {...data} key={index} />
          </div>
        )
    )
    .with(
      { __typename: literal('ContentfulImageLink').value },
      (data: DuplexImageLink) =>
        O.some(
          <GatsbyImage
            className="rounded-base col-span-2 hidden h-auto self-stretch first:block md:col-span-1 md:block lg:col-span-2 lg:h-fit"
            image={data.image}
            imgClassName="object-contain md:object-cover"
            key={index}
          />
        )
    )
    .otherwise(() => O.none)

const mapDuplexComponents = (
  components: readonly PossibleEmbeddedComponent[]
) =>
  components.map((componentData, index) =>
    pipe(getMappedComponent(componentData, index), O.toNullable)
  )

/**
 * ⚠️⚠️⚠️ WARNING ⚠️⚠️⚠️
 *
 * This is undergoing a refactor to remove Gatsby's data layer and migrate to Apollo.
 *
 * ECP-12406
 *
 * Please reach out to Josh and the platform team if you need to make any changes.
 *
 */
export function DuplexSection({ left, right }: DuplexSectionSchema) {
  const quoteWizardMobile = right.filter(
    item => item.__typename === 'ContentfulQuoteWizard'
  )
  const imageLink = right.filter(
    item => item.__typename === 'ContentfulImageLink'
  )

  return (
    <div
      className="grid w-full grid-cols-2 items-start gap-4 md:gap-8"
      data-testid={DuplexSection.name}
    >
      <div className="col-span-2 md:order-2 lg:col-span-1">
        {mapDuplexComponents(quoteWizardMobile)}
      </div>
      <div className="col-span-2 grid grid-cols-2 md:order-1 md:gap-6 lg:col-span-1 lg:row-span-2 lg:gap-0">
        {mapDuplexComponents(left)}
      </div>
      <div className="col-span-2 grid grid-cols-2 gap-4 md:order-3 md:gap-6 lg:col-span-1">
        {mapDuplexComponents(imageLink)}
      </div>
    </div>
  )
}
