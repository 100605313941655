import { AuthenticationComponent } from '@ecomm/auth-components'
import { PriceProvider } from '@ecomm/data-price'
import { ErrorBoundary } from '@ecomm/error-handling'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { LiveChat } from '@ecomm/live-chat'
import { useMicroCopy } from '@ecomm/micro-copy'
import { PartnerUtmCampaign } from '@ecomm/partners/components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  Breadcrumbs,
  Header as HeaderRedesign,
  useHeaderRedesignQuery
} from '@ecomm/header'
import {
  AffirmBanner,
  Header,
  PageWrapper,
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import { Phone } from '@ecomm/shared-icons'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useEffect, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import { AuthenticationType } from '../../components/Authentication/types'
import DraftCart from '../../components/DraftCart'
import type { DraftCartFragment } from '../../components/DraftCart/types'
import SaveMySystemModal from '../../components/SaveMySystemModal'
import SystemHero from '../../components/SystemHero'
import SystemHeroItem from '../../components/SystemHeroItem'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import { ProductProvider } from '../../contexts/ProductContext'
import { NinetailedAA } from '../../experiments/NinetailedAA'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useResetDraftCart } from '../../hooks/draftCart/useResetDraftCart'
import { aggregateProducts } from '../../utils/productAggregator'
import { aggregateShopSkus } from '../../utils/skuAggregator'
import { useFooterIntersectionObserver } from './hooks'
import SensorGroups from './SensorGroups'
import { useBmsPageFragment } from './useBmsPageFragment'
import { useAffirmBannerQuery } from '@ecomm/lander'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly ninetailedPoCFlag: boolean
}

type Props = Partial<PageProps> & {
  readonly data: unknown
  readonly pageContext: PageContext
}

function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  //@ts-expect-error
  const bmsPage = useBmsPageFragment(data)
  const microcopy = useMicroCopy()
  const affirmBannerData = useAffirmBannerQuery()

  const { ref, style } = useFooterIntersectionObserver()

  const monitoringToggles = bmsPage.cartSummary.priceToggles

  const {
    heroItems,
    heroText,
    cartSummary,
    sensorGroups,
    layout,
    modal,
    authentication
  } = bmsPage

  const {
    header,
    footer: { contentful_id: footerId },
    liveChat
  } = layout

  const footer = useFooterQuery(footerId)

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const shopSkuSet = useMemo(() => {
    return aggregateShopSkus({
      draftCart: { ...cartSummary, priceToggles: monitoringToggles },
      heroItems,
      sensorGroups
    })
  }, [heroItems, sensorGroups, cartSummary])

  const products = useMemo(
    () => aggregateProducts({ sensorGroups }),
    [sensorGroups]
  )
  const isSS2Upgrade = authentication?.type === AuthenticationType.ss2

  const renderAffirmBanner = () => {
    return (
      locale === 'en-US' &&
      affirmBannerData && (
        <div className="mt-5">
          <AffirmBanner data={affirmBannerData} />
        </div>
      )
    )
  }

  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  const headerData = useHeaderRedesignQuery()

  const progressBarProps =
    locale === 'en-GB'
      ? {
          steps: ['Review System', 'Choose Monitoring', 'Checkout'],
          progress: 12
        }
      : null

  const headerComponent = header ? (
    <Header {...header} progressBarProps={progressBarProps} type="slim">
      {locale === 'en-US' && (
        <a
          className="hover:text-primary-100 ml-auto text-white no-underline"
          href={`tel:${microcopy['us-phone-number']}`}
        >
          <span className="hidden md:block">
            {microcopy['bms-phone-number-label']}
          </span>
          <span className="sr-only">{microcopy['bms-phone-number-label']}</span>
          <Phone className="h-6 w-6 md:hidden" />
        </a>
      )}
    </Header>
  ) : (
    <></>
  )

  // Force wipe Draft Cart state when leaving BMS page.
  // TODO: This functionality can be removed when Jotai Providers are
  // template scoped: https://simplisafe.atlassian.net/browse/ECP-8723
  const wipeDraftCart = useResetDraftCart()
  useEffect(() => {
    return () => {
      wipeDraftCart()
    }
  }, [])

  /**
   * Some Providers are defined here instead of wrap-with-context/provider because
   * they're intentionally designed to be remounted or contain page-specific data.
   * - TrackingProvider: Only sends pageLoad events on initial mount
   * - PriceProvider: We need SKUs from CTFL so it relies on the GraphQL response
   * - Jotai Provider: DraftCart is BMS-specific so it shouldn't be shared w/ future shop pages
   *
   */

  const loading = !bmsPage.ready

  const mappedCartSummaries = mapExperiences<DraftCartFragment>(
    cartSummary.nt_experiences
  )

  const pageContents = (
    <PriceProvider locale={locale} skus={shopSkuSet}>
      <PageWrapper>
        {!isSS2Upgrade && (
          <PromoBannerWrapper
            experimentData={promoBannerExperiment}
            type={bannerStyle}
          />
        )}
        {header ? (
          locale === 'en-GB' ? (
            headerComponent
          ) : (
            <HeaderRedesign {...headerData} />
          )
        ) : null}
        <Breadcrumbs
          steps={[
            {
              label: bmsPage.breadcrumbTitle || bmsPage.title,
              slug: bmsPage.slug
            }
          ]}
        />
        {!loading && (
          <main
            className="prose md:prose-md lg:prose-lg whitespace-pre-line max-w-8xl mx-auto mb-16 px-4 pt-8 md:px-8 lg:pt-16"
            id="content"
          >
            {locale === 'en-GB' && (
              <TrustpilotUKTopBanner className="pb-4 md:pb-8 lg:-mt-6"></TrustpilotUKTopBanner>
            )}
            <div className="lg:mb-20">
              <SystemHero heroText={heroText}>
                {heroItems
                  ? heroItems.map(heroItem => (
                      <SystemHeroItem key={heroItem.id} {...heroItem} />
                    ))
                  : null}
              </SystemHero>
              {renderAffirmBanner()}
            </div>
            <SensorGroups sensorGroups={sensorGroups} />
            {locale === 'en-US' ? (
              <Experience
                {...cartSummary}
                component={DraftCart}
                experiences={mappedCartSummaries}
                key={cartSummary.id}
                passthroughProps={{ isSS2Upgrade }}
                priceToggles={monitoringToggles}
              />
            ) : (
              <DraftCart
                {...cartSummary}
                isSS2Upgrade={isSS2Upgrade}
                priceToggles={monitoringToggles}
              />
            )}
            <div
              className={`right-2 z-10 flex h-14 gap-x-1 will-change-scroll md:right-4 md:gap-x-4 lg:right-20 ${style}`}
            >
              {modal ? <SaveMySystemModal {...modal} /> : null}
            </div>
          </main>
        )}
        {loading ? (
          <div className="max-w-8xl mx-auto grid grid-cols-12 py-32">
            <div className="col-span-6 flex flex-col gap-y-6">
              <Skeleton className="h-16" count={2} />
              <Skeleton count={4.5} />
            </div>
          </div>
        ) : null}
        <Footer {...footer} ref={ref} type="Full" />
      </PageWrapper>
    </PriceProvider>
  )

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <ErrorBoundary>
        <Suspense>
          <PartnerUtmCampaign />
        </Suspense>
      </ErrorBoundary>
      <SEO
        canonicalLink={canonicalLink || ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription.metaDescription}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      {/* ECP-9227 -> Include 9T Proof of Concept based on env settings */}
      {locale === 'en-US' ? <NinetailedAA /> : null}
      <ProductProvider products={products}>
        {authentication ? (
          // Note: AuthenticationComponent needs to wrap PricingProvider in
          // order to delay fetching prices until the SS2 Upgrade customer
          // group is applied to the cart, so that we can send it to the pricing
          // service.
          <AuthenticationComponent language={locale} type={authentication.type}>
            {pageContents}
          </AuthenticationComponent>
        ) : (
          pageContents
        )}
        {liveChat ? <LiveChat /> : null}
      </ProductProvider>
    </TrackingProvider>
  )
}

export default function BMSTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const bmsTemplateQuery = graphql`
  #graphql
  query BmsTemplate($id: String) {
    contentfulBmsPage(id: { eq: $id }) {
      ...bmsPage
      ...bmsPageVariations
    }
  }
`
