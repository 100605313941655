import {
  COOKIE_QUIZ_BREAK_INS,
  COOKIE_QUIZ_PACKAGE_THEFT,
  COOKIE_SHOWED_ODM_INTENT,
  setCookie
} from '@ecomm/shared-cookies'
import type { BrazeQuoteWizardResponse } from '@ecomm/tracking'
import type { PackageComponents } from '@simplisafe/ss-ecomm-data/leads/quoteWizard/recommendations'
import { match, P } from 'ts-pattern'

export enum QueryParams {
  homeSize = 'hm',
  floors = 'fl',
  doors = 'dr',
  bedrooms = 'bd',
  hazards = 'hz',
  property = 'prp_type',
  installation = 'inst_apt',
  camQuantity = 'cam_qty',
  switcher = 'sw' // Added for the Switcher Quiz experiment https://simplisafe.atlassian.net/browse/ECP-12038
}

export enum HazardCodes {
  videoDoorbellPro = 'vd',
  smokeDetector = 'sm',
  panicButton = 'pnc',
  freezeSensor = 'tmp',
  simplicam = 'sc',
  waterSensor = 'ws',
  outdoorCamera = 'oc',
  bellBox = 'bb',
  breakIns = 'Deterring break-Ins'
}

export enum Installation {
  ProInstall = 'ProInstall',
  DIY = 'DIY'
}

// Added for the Switcher Quiz experiment https://simplisafe.atlassian.net/browse/ECP-12038
export enum Switcher {
  No = 'na',
  ADT = 'adt',
  Other = 'other',
  Ring = 'ring',
  SS = 'ss'
}

/**
 * Converts jebbit query params into a package components
 * Handles both encoded and unencoded params
 * @param searchParams string that contains query params, it can be url encoded
 * @returns
 *
 */
export const convertGuidedSystemBuilderParamsToPackageComponents = (
  searchParams: string,
  includeIndoorCam = false,
  isUS = true
): PackageComponents => {
  const params = new URLSearchParams(searchParams)
  const homeParameter = Number.parseInt(
    decodeURIComponent(params.get(QueryParams.homeSize) || '1').replace(
      /\D/g,
      ''
    )
  )
  // See https://simplisafe.atlassian.net/browse/ECP-10183 This variable will let us know if user has sent the address-based quiz or the regular one
  const isAddressBasedQuiz = !params.get(QueryParams.doors) && isUS
  const homeSize = !isAddressBasedQuiz
    ? homeParameter
    : match(homeParameter)
        .with(
          P.when(v => v <= 1000),
          () => 1
        )
        .with(
          P.when(v => v <= 2000),
          () => 2
        )
        .with(
          P.when(v => v <= 3000),
          () => 3
        )
        .otherwise(() => 4)
  const doors = !isAddressBasedQuiz
    ? Number.parseInt(params.get(QueryParams.doors) || '1')
    : homeSize + 2
  const hazards =
    params
      .get(QueryParams.hazards)
      ?.replace(/\+/g, '')
      .split(',')
      .map(x => x.trim()) || []

  setOdmonQuizCookies(hazards)

  const camQuantityParam = params.get(QueryParams.camQuantity)
  const camQuantity = camQuantityParam ? camQuantityParam.split(', ').length : 0
  const outdoorCameras = hazards.includes(HazardCodes.outdoorCamera)
    ? Math.max(camQuantity, 1)
    : 0

  return {
    auxiliarySiren: homeSize >= 4 ? 1 : 0,
    entrySensor: doors,
    bellBox: hazards.includes(HazardCodes.bellBox) ? 1 : 0,
    freezeSensor: hazards.includes(HazardCodes.freezeSensor) ? 1 : 0,
    keypad: 1,
    motionSensor: homeSize > 1 ? 2 : 1,
    outdoorCamera: outdoorCameras,
    panicButton: hazards.includes(HazardCodes.panicButton) ? 1 : 0,
    simplicam: 0,
    smokeDetector: hazards.includes(HazardCodes.smokeDetector) ? 1 : 0,
    videoDoorbellPro: hazards.includes(HazardCodes.videoDoorbellPro) ? 1 : 0,
    waterSensor: hazards.includes(HazardCodes.waterSensor) ? 1 : 0,
    indoorCam:
      hazards.includes(HazardCodes.simplicam) && includeIndoorCam ? 1 : 0
  }
}

/**
 * It takes a query param as described in https://docs.google.com/document/d/1lzQsygcFf658baLQikUp5SyTt-jvsSMl467ApVCs4G4/
 * and returns its correspondent Jebbit question.
 *
 * @param param string
 */
export const mapQueryParameterToQuestion = (param: string) =>
  ({
    [QueryParams.homeSize]: 'What size is your home/apartment?',
    [QueryParams.doors]: 'How many external doors do you want to protect?',
    [QueryParams.hazards]:
      'What specific types of protection are you looking for?',
    [QueryParams.floors]: 'Floors',
    [QueryParams.bedrooms]: 'Bedrooms',
    [QueryParams.property]: 'What kind of property are you looking to protect?',
    [QueryParams.installation]:
      "We've got two options for set-up. Which one sounds right to you?",
    [QueryParams.switcher]: 'Do you currently own any home security products?'
  })[param] || null

/**
 * It takes a hazard key as described in https://docs.google.com/document/d/1lzQsygcFf658baLQikUp5SyTt-jvsSMl467ApVCs4G4/
 * and returns its correspondent hazard full name.
 *
 * @param key string
 * @param matchQuizAnswers boolean: if true - will return responses matching answers in the quiz,
 * if false - will return sensor name that we recommend based on responses
 *
 */
export const keyToHazard = (key: string, matchQuizAnswers = false) =>
  ({
    [HazardCodes.videoDoorbellPro]: matchQuizAnswers
      ? 'Package theft'
      : 'Video Doorbell',
    [HazardCodes.smokeDetector]: matchQuizAnswers
      ? 'Preventing fires'
      : 'Smoke Detector',
    [HazardCodes.panicButton]: matchQuizAnswers
      ? 'Medical dispatch'
      : 'Panic Button',
    [HazardCodes.freezeSensor]: matchQuizAnswers
      ? 'Freezing or bursting pipes'
      : 'Temperature Sensor',
    [HazardCodes.simplicam]: matchQuizAnswers
      ? 'Indoor video footage'
      : 'Wireless Indoor Camera',
    [HazardCodes.waterSensor]: matchQuizAnswers
      ? 'Water damage & leaks'
      : 'Water Sensor',
    [HazardCodes.outdoorCamera]: matchQuizAnswers
      ? 'Outdoor video footage'
      : 'Outdoor Cam',
    [HazardCodes.bellBox]: matchQuizAnswers ? 'Deterring Break-ins' : 'Bell Box'
  })[key] || key

/**
 * Maps an index to the house size as described in https://docs.google.com/document/d/1lzQsygcFf658baLQikUp5SyTt-jvsSMl467ApVCs4G4/
 * @param index number
 */
export const houseSize = (index: number) => {
  const sizes = ['Small', 'Medium', 'Large', 'XL']
  return sizes[index - 1] || null
}

/**
 * Maps a type to the property type as described in https://docs.google.com/document/d/1lzQsygcFf658baLQikUp5SyTt-jvsSMl467ApVCs4G4/
 * @param type string
 */
export const propertyType = (type: string) =>
  ({ home: 'Home', apt: 'Apartment' })[type] || null

/**
 * Maps a type to the installation type as described in https://docs.google.com/document/d/1lzQsygcFf658baLQikUp5SyTt-jvsSMl467ApVCs4G4/
 * @param type string
 */
export const installationType = (type: string) =>
  ({
    [Installation.ProInstall]: 'I would like a professional to install it',
    [Installation.DIY]: "I'll set it up myself"
  })[type] || null

/**
 * Map to an answer in the Switcher quiz regarding the current use of the home security system
 * Added for the Switcher Quiz experiment https://simplisafe.atlassian.net/browse/ECP-12038
 * @param choice string
 */
export const switcherChoice = (choice: string) =>
  ({
    [Switcher.No]: 'No, I don’t own any',
    [Switcher.ADT]: 'Yes, from ADT',
    [Switcher.Other]: 'Yes, from another provider',
    [Switcher.Ring]: 'Yes, from Ring',
    [Switcher.SS]: 'Yes, from SimpliSafe'
  })[choice] || null

const convertHazardResponsesForBraze = (
  responses: string,
  matchQuizAnswers = false
) =>
  responses
    .split(',')
    .map(key => keyToHazard(key.trim(), matchQuizAnswers))
    .join(', ')

/**
 * Will convert a query params object to an array of BrazeQuoteWizardResponses, mapping each query param to their Jebbit
 * questions and answers for easy logging to Braze.
 *
 * @param queryParams
 */
export const convertParamsObjectToResponsesArray = (
  queryParams: Record<string, string>
): readonly BrazeQuoteWizardResponse[] => {
  return Object.keys(queryParams)
    .filter((param: string) => param !== 'name' && param !== 'email')
    .flatMap(param =>
      param === QueryParams.hazards
        ? [
            {
              question: `QW_RECS ${mapQueryParameterToQuestion(
                QueryParams.hazards
              )}`,
              answer: convertHazardResponsesForBraze(
                queryParams[QueryParams.hazards]
              )
            },
            {
              question: `QW_RECS What Can We Help You With?`,
              answer: convertHazardResponsesForBraze(
                queryParams[QueryParams.hazards],
                true
              )
            }
          ]
        : {
            question: `QW_RECS ${mapQueryParameterToQuestion(param)}`,
            answer: match(param)
              .with(QueryParams.homeSize, param =>
                houseSize(Number.parseInt(queryParams[param], 10))
              )
              .with(QueryParams.property, param =>
                propertyType(queryParams[param])
              )
              .with(QueryParams.installation, param =>
                installationType(queryParams[param])
              )
              .with(QueryParams.switcher, param =>
                switcherChoice(queryParams[param])
              )
              .otherwise(param => queryParams[param])
          }
    )
    .filter(elem => !!elem.question)
}

/**
 * Assess user showing ODMON intent by selecting (Outdoor Camera AND (Break-Ins OR Package Theft))
 * and set cookie for either value.
 */
const setOdmonQuizCookies = (hazards: readonly string[]) => {
  const packageTheft = hazards.includes(HazardCodes.videoDoorbellPro)
  const breakIns = hazards.includes(HazardCodes.breakIns)
  const showedIntent =
    hazards.includes(HazardCodes.outdoorCamera) && (packageTheft || breakIns)

  // construct a date two weeks from now
  const expires = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14)
  setCookie(COOKIE_QUIZ_PACKAGE_THEFT, packageTheft, { expires })
  setCookie(COOKIE_QUIZ_BREAK_INS, breakIns, { expires })
  setCookie(COOKIE_SHOWED_ODM_INTENT, showedIntent, { expires })
}
