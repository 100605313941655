import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click on alarm sensor tooltip in alarm-sensors page when the user wants to see a sensor's detail
 */
export function trackAlarmSensorsTooltip(sensor: string) {
  getRudderstack(r => {
    r.track('alarm_sensors_tooltip', {
      sensor
    })
  })
}

export function trackAlarmContinueShopping() {
  getRudderstack(r => {
    r.track('alarm_continue_shopping')
  })
}
