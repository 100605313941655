import { ODMON_PREBUILT_PACKAGES, SHIELD_SKU } from '@ecomm/data-constants'
import { useAtom } from 'jotai'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'

const useExistsOutdoorCameraInPkg = (packageSku: string) => {
  const [itemMap] = useAtom(itemQuantityAtom)
  const skus = itemMap.keySeq().toArray()
  const existsOutdoorCamera = skus.findIndex(sku => sku === SHIELD_SKU) !== -1

  const isOdmonPackage = ODMON_PREBUILT_PACKAGES.includes(packageSku)

  return existsOutdoorCamera || isOdmonPackage
}

export default useExistsOutdoorCameraInPkg
