import { useHeroBannerQuery } from '@ecomm/partners-data'
import {
  GatsbyHeroBannerSchema,
  V2HeroBanner as HeroBanner
} from '@ecomm/shared-components'

export function PartnerHerobanner(heroBanner: GatsbyHeroBannerSchema) {
  const heroBannerData = useHeroBannerQuery(heroBanner.id)
  return <HeroBanner {...heroBannerData} />
}
