import { Modal } from '@ecomm/shared-components'
import { BigChevronDown, ShieldCheck } from '@ecomm/shared-icons'
import {
  useTrackComparePlans,
  useTrackingAddMonitoring,
  useTrackingRemoveMonitoring,
  useTrackMonitoringSelectionClick,
  useTrackPlanChoiceDropdown
} from '@ecomm/tracking'
import classNames from 'classnames'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'

import { monitoringAtom } from '../../../atoms/draftCart/monitoringPlanAtom'
import useMonitoringPlan from '../../../hooks/IMAPS/useMonitoringPlan'
import { type MonitoringPlanType, MonitoringPlan } from '../../DraftCart/types'
import { ExperimentMonitoringLegalDisclaimer } from '../../LegalDisclaimer/ExperimentMonitoringLegalDisclaimer'
import { shouldRenderMonitoring } from '../helpers'
import type { OptInOrOutProps } from '../OptInOrOut/types'
import { baseCardData, priceToggleMap } from './constants'
import { OdmonMAPSComparisonTable } from './OdmonMAPSComparisonTable'
import { OdmonPlanChoiceExpCard } from './OdmonPlanChoiceExpCard'
import { trackMonitoringTogglePackagePage } from '@ecomm/cdp-tracking'

export type Props = {
  readonly defaultMonitoringPlan: MonitoringPlanType
}

export type OdmonHybridMAPSProps = OptInOrOutProps & Props

export function OdmonHybridMAPS({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  priceToggles,
  defaultMonitoringPlan
}: OdmonHybridMAPSProps) {
  const { hasMonitoring, onSelectMonitoring } = useMonitoringPlan({
    priceToggles: [
      priceToggleMap[MonitoringPlan.odmonOvernight],
      priceToggleMap[MonitoringPlan.interactive],
      priceToggleMap[MonitoringPlan.odmon247],
      priceToggleMap[MonitoringPlan.none]
    ],
    setInitialMonitoringPlan: false
  })

  const trackPlanChoiceDropdown = useTrackPlanChoiceDropdown()
  const trackComparePlans = useTrackComparePlans()
  const trackMonitoringSelection = useTrackMonitoringSelectionClick()
  const trackRemoveMonitoring = useTrackingRemoveMonitoring()
  const trackAddMonitoring = useTrackingAddMonitoring()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [showCards, setShowCards] = useState(false)
  const [activePlanType, setActivePlanType] = useState<MonitoringPlanType>(
    defaultMonitoringPlan
  )
  const [monitoring] = useAtom(monitoringAtom)

  // This will update the monitoring atom when we select a new monitoring type
  useEffect(() => {
    monitoring.type !== MonitoringPlan.none &&
      onSelectMonitoring(priceToggleMap[activePlanType].sku ?? '')
  }, [activePlanType, priceToggleMap])

  return shouldRenderMonitoring(priceToggles) ? (
    <div data-component="HybridMAPS">
      <div
        className={classNames(
          'border-neutral-medium-100 mb-4 rounded-xl border border-solid py-6 px-4 md:px-6 shadow-md sm:text-xs md:order-2  md:justify-end md:text-sm',
          { 'bg-white ': hasMonitoring },
          { 'bg-neutral-light-50': !hasMonitoring }
        )}
        data-component="ChooseMonitoringBox"
      >
        {hasMonitoring ? (
          <div className="flex flex-col md:flex-row md:order-2">
            <div className="w-full">
              {
                <>
                  {discountWithServicePlan ? (
                    <>
                      <span className="font-semibold text-orange-700">
                        You’re saving {`${discountWithServicePlan}`} today{' '}
                      </span>
                      <span>and getting </span>
                    </>
                  ) : (
                    <span>You’re getting </span>
                  )}
                  <span className="font-semibold">
                    1 free month of {priceToggleMap[activePlanType].name}
                  </span>
                </>
              }
            </div>
            <div className="mt-4 flex items-end justify-end md:m-0">
              <button
                className="text-complementary-blue-100 cursor-pointer whitespace-nowrap border-none bg-transparent font-medium"
                onClick={() => {
                  trackPlanChoiceDropdown(!showCards)
                  setShowCards(!showCards)
                }}
              >
                <span className="mr-2">Change plan</span>
                <BigChevronDown
                  className={classNames(
                    'h-3 w-3 transition-transform duration-150 align-bottom',
                    {
                      'rotate-180': showCards
                    }
                  )}
                />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row md:order-2">
            <div className="w-full">
              <p className="m-0 mb-2 font-semibold text-orange-700">
                You have removed your free month of{' '}
                {priceToggleMap[activePlanType].name}
              </p>
              <span>
                Claim your free month
                <strong>
                  {' '}
                  and save an additional {amountSavedWithServicePlan}
                </strong>
                . No contracts, cancel any time.
              </span>
            </div>
            <div className="mt-4 flex items-end justify-end md:m-0">
              <button
                className="text-complementary-blue-100 cursor-pointer whitespace-nowrap border-none bg-transparent font-medium"
                onClick={() => {
                  trackMonitoringTogglePackagePage('add-plan')
                  trackAddMonitoring()
                  onSelectMonitoring(priceToggleMap[activePlanType].sku || '')
                }}
              >
                Claim free month
              </button>
            </div>
          </div>
        )}

        {showCards ? (
          <div>
            <div className="grid grid-cols-1 gap-4 bg-white md:grid-cols-3 mt-6 md:mt-0">
              {baseCardData.map((dataProps, i) => {
                return (
                  <OdmonPlanChoiceExpCard
                    key={dataProps.monitoringType}
                    {...dataProps}
                    cardId={i}
                    isSelected={dataProps.monitoringType === activePlanType}
                    onClick={({ monitoringType }) => {
                      trackMonitoringSelection(dataProps.title.toLowerCase())
                      setActivePlanType(monitoringType)
                    }}
                  />
                )
              })}
            </div>
            <div className="inline-block md:flex justify-center md:justify-between p-5 md:pt-6 md:px-0 md:pb-0 space-y-4 md:space-y-0">
              <div className="flex justify-center md:justify-start text-xs md:text-sm md:mr-4">
                <ShieldCheck className="mt-0.5 md:mt-0 h-6 w-6 md:h-7 md:w-7 mr-3 shrink-0" />
                <span className="content-center">
                  Enjoy lifetime subscription warranty on hardware with all 3
                  plans.
                </span>
              </div>

              <div className="flex justify-center md:justify-end md:col-span-3 space-x-5">
                <button
                  className="cursor-pointer whitespace-nowrap border-none bg-transparent"
                  onClick={() => {
                    setIsOpenModal(true)
                    trackComparePlans()
                  }}
                >
                  <span className="text-complementary-blue-100 font-medium underline">
                    Compare all features
                  </span>
                </button>
                <button
                  className="cursor-pointer whitespace-nowrap border-none bg-transparent"
                  onClick={() => {
                    trackMonitoringTogglePackagePage('no-plan')
                    onSelectMonitoring('')
                    setShowCards(false)
                    trackRemoveMonitoring()
                  }}
                >
                  <span className="text-complementary-blue-100 font-medium underline">
                    Remove
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
        >
          <div
            className="flex max-w-screen-lg flex-col-reverse gap-8 p-1 md:p-2 lg:flex-row"
            data-component="modal_content"
          >
            <OdmonMAPSComparisonTable />
          </div>
        </Modal>
      </div>

      <ExperimentMonitoringLegalDisclaimer
        activePlanType={activePlanType}
        defaultMonitoringPlan={defaultMonitoringPlan}
      />
    </div>
  ) : null
}
