import { handleZuoraResponse } from '@ecomm/checkout/payment-hooks'
import { LoadingSpinner } from '@ecomm/checkout-icons'
import {
  formatCallbackRequest,
  getFailureParams,
  getResponseFrom,
  getSuccess,
  getSuccessParams
} from '@ecomm/data-zuora'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getSearchParams } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { exists, window } from 'browser-monads-ts'
import { useCallback, useEffect } from 'react'
import { handleHPMFailure, handleHPMSuccess } from '@ecomm/shared-window'

export function PaymentCallbackContainer() {
  const location = useLocation()
  const microCopy = useMicroCopy()
  const searchParams = getSearchParams(location)
  const responseFrom = getResponseFrom(searchParams)
  const success = getSuccess(searchParams)

  const successFn = useCallback(() => {
    const successParams = getSuccessParams(searchParams)
    handleHPMSuccess(successParams)
  }, [searchParams])

  const failureFn = useCallback(() => {
    const isEmpty = Object.keys(searchParams).length === 0
    const failureParams = getFailureParams(searchParams)

    isEmpty && exists(window) && (window.location.href = '/error-404')
    !isEmpty && handleHPMFailure(failureParams)
  }, [searchParams])

  useEffect(() => {
    const isResponseFromSubmitPage =
      responseFrom === 'Response_From_Submit_Page'
    /**
     * Conditionally handle the response from the Zuora payment form callback
     * If the response is not from the Submit Page, then there was an error loading the form, so handle errors
     * If the response is from the Submit Page and it is unsuccessful, handle errors
     * If the response is from the Submit Page and it is successful, POST to payment decorator and handle based on response
     */
    !isResponseFromSubmitPage && failureFn()
    isResponseFromSubmitPage && !success && failureFn()
    isResponseFromSubmitPage &&
      success &&
      handleZuoraResponse(
        formatCallbackRequest(searchParams),
        successFn,
        failureFn
      )
  }, [])

  return (
    <div
      className="text-center"
      data-component="PaymentCallbackContainer"
      data-testid="PaymentCallbackContainer"
    >
      <h2>{microCopy['loading']}</h2>
      <LoadingSpinner className="fill-primary-100" />
    </div>
  )
}
