import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { match, P } from 'ts-pattern'

import type { GroupSchema } from '../../templates/AlarmSensor/alarmSensorPageSchema'
import AdditionalAlarmSensorsDisclaimer from '../AdditionalAlarmSensorsDisclaimer'
import ProductCard from '../ProductCard'
import ProductsDisclaimer from '../ProductsDisclaimer'
import { TooltipEventSchema } from '../ProductCard/schema'
import { parseObjectKey } from '@simplisafe/ewok'
import { devThrowError } from '@ecomm/error-handling'

const SS2_DISCLAIMER_TYPE = 'SS2'
const ADDITIONAL_ALARM_SENSORS_DISCLAIMER_TYPE = 'Additional Alarm Sensors'

type Props = {
  readonly group: GroupSchema | null
  readonly tooltipEvent?: TooltipEventSchema
}

export function getDisclaimer(disclaimerType: string) {
  return match(disclaimerType)
    .with(SS2_DISCLAIMER_TYPE, () => {
      const data = useStaticQuery(graphql`
          query DisclaimerImage {
            contentfulAsset(contentful_id: { eq: "2kO7yT72zOZqWQuPeb8IVL" }) {
              title
              description
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        `)
      const image = parseObjectKey(data, 'contentfulAsset', gatsbyImageSchema)

      return <ProductsDisclaimer image={image} />
    })
    .with(ADDITIONAL_ALARM_SENSORS_DISCLAIMER_TYPE, () => (
      <AdditionalAlarmSensorsDisclaimer />
    ))
    .with(P.string, str => {
      devThrowError(Error(`unhandled disclaimer type: ${str}`))
      return null
    })
    .exhaustive()
}

function ProductCards({ group, tooltipEvent }: Props) {
  const products = group?.products || []

  return products.length > 0 ? (
    <div
      className="grid md:grid-cols-3 lg:grid-cols-4"
      data-component="ProductList"
    >
      {group?.disclaimerCardType
        ? getDisclaimer(group.disclaimerCardType)
        : null}
      {
        // We pass `disablePromo` because we do not want to show the free gift item message on the Alarm Sensors page
        products.map((item, index) => (
          <ProductCard
            key={`${index}-${item.sku}`}
            {...item}
            addToCartButton
            className="border-neutral-light-100 m-2 border-2 border-solid"
            disableMonthlyPricing
            disablePromo
            isAffirmExperience={false}
            productLink={item.slug ? `/${item.slug}` : null}
            tooltipEvent={tooltipEvent}
            type="Alarm Sensor"
          />
        ))
      }
    </div>
  ) : null
}

export default ProductCards
